<template>
  <a title="اشتراک گذاری" @click="share">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-share"
        viewBox="0 0 16 16"
    >
      <path
          fill="#444"
          fill-width="1.5"
          d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "ProductShare",
  props: {
    product: Object
  },
  methods: {
    share() {
      if (navigator.share) {
        navigator.share({
          title: this.product.title,
          text: 'این محصول رو بهت پیشنهاد میکنم',
          url: 'https://' + window.location.host + '/product/' + this.product.id,
        })
            .then(() => {})
            .catch((error) => console.error(error));
      }
    }
  }
}
</script>

<style scoped>

</style>
