<template>
  <header class="main-header">
    <!-- <TopHeader @openRespMenu="openRespMenu"></TopHeader> -->
    <Header @openRespMenu="openRespMenu"/>
    <MainSearch></MainSearch>
    <!-- resp login Modal -->
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <RespMenu ref="respMenu"></RespMenu>


  </header>
</template>

<script>
// import TopHeader from "@/parts/Front/components/TopHeader";
import MainSearch from "@/parts/Front/components/MainSearch";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import RespMenu from "@/parts/Front/components/RespMenu";
import CartLists from "@/parts/Front/components/CartLists";
import Header from "@/parts/Front/components/shared/Header.vue";

export default {
  name: "HeaderSecond",
  components:{
    // TopHeader,
    MainSearch,
    RespLoginModal,
    RespMenu,
    CartLists,
    Header
},
  methods: {
    openRespMenu() {
      this.$refs.respMenu.allCategoriesToggle()
    }
  }
}
</script>

<style scoped>

</style>
