<template>
  <section v-if="missions && missions.length">
    <div>
      <article
        v-for="(mission, index) in missions"
        :key="index"
        class="overflow-hidden border border-r-10 mb-3"
      >
        <template v-if="missionLinkResolver(mission.link_to).externalLink">
          <a
            :href="missionLinkResolver(mission.link_to).link"
            class="row text-color-grey"
          >
            <div class="col-12 col-lg-3 p-0">
              <img
                class="w-100 h-100"
                :src="`https://api.benedito.ir/${mission.image}`"
                alt=""
              />
            </div>
            <div
              class="d-flex flex-column justify-content-between py-3 col-12 col-lg-9 pr-4 pr-lg-3 position-relative"
            >
              <div
                class="w-100 mb-3"
                :class="
                  mission.extra_data && mission.extra_data.length
                    ? 'd-flex flex-column flex-lg-row align-items-start justify-content-between'
                    : ''
                "
              >
                <div>
                  <h4 class="fontsize-regular">{{ mission.cause_title }}</h4>
                  <p
                    class="m-0 pb-1 fontsize-mini"
                    v-for="(item, index) in mission.cause_description"
                    :key="index"
                  >
                    {{ `${index + 1}.  ${item}` }}
                  </p>
                </div>
              </div>
              <div
                class="w-100 pl-3 d-flex align-items-center justify-content-between"
              >
                <span class="font-w-700 fontsize-small"
                  >امتیاز: {{ mission.total_score }}</span
                >
                <span class="font-w-700 fontsize-small"
                  >بن: {{ mission.total_bon }}</span
                >
                <span class="font-w-700 fontsize-small">
                  <CheckedIcon v-if="mission.result == true" />
                  <CrossIcon v-else-if="mission.result == false" />
                  <span v-else>
                    {{ mission.result }}
                  </span>
                </span>
              </div>
              <button
                v-if="mission.extra_data && mission.extra_data.length"
                class="bg-none mission-more-btn"
                v-b-modal="`moreDetail${index}`"
              >
                بیشتر ...
              </button>
            </div>
          </a>
        </template>
        <template v-else>
          <router-link
            :to="missionLinkResolver(mission.link_to).link"
            class="row text-color-grey"
          >
            <div class="col-12 col-lg-3 p-0">
              <img
                class="w-100 h-100"
                :src="`https://api.benedito.ir/${mission.image}`"
                alt=""
              />
            </div>
            <div
              class="d-flex flex-column justify-content-between py-3 col-12 col-lg-9 pr-4 pr-lg-3 position-relative"
            >
              <div
                class="w-100 mb-3"
                :class="
                  mission.extra_data && mission.extra_data.length
                    ? 'd-flex flex-column flex-lg-row align-items-start justify-content-between'
                    : ''
                "
              >
                <div>
                  <h4 class="fontsize-regular">{{ mission.cause_title }}</h4>
                  <p
                    class="m-0 pb-1 fontsize-mini"
                    v-for="(item, index) in mission.cause_description"
                    :key="index"
                  >
                    {{ `${index + 1}.  ${item}` }}
                  </p>
                </div>
              </div>
              <div
                class="w-100 pl-3 d-flex align-items-center justify-content-between"
              >
                <span class="font-w-700 fontsize-small"
                  >امتیاز: {{ mission.total_score }}</span
                >
                <span class="font-w-700 fontsize-small"
                  >بن: {{ mission.total_bon }}</span
                >
                <span class="font-w-700 fontsize-small">
                  <CheckedIcon v-if="mission.result == true" />
                  <CrossIcon v-else-if="mission.result == false" />
                  <span v-else>
                    {{ mission.result }}
                  </span>
                </span>
              </div>
              <button
                v-if="mission.extra_data && mission.extra_data.length"
                class="bg-none mission-more-btn"
                v-b-modal="`moreDetail${index}`"
              >
                بیشتر ...
              </button>
            </div>
          </router-link>
        </template>
        <BModal hideFooter :id="`moreDetail${index}`" centered title="">
          <div v-if="mission.extra_data && mission.extra_data.length">
            <table class="table table-bordered table-sm m-0">
              <tbody>
                <tr
                  v-for="(tableRow, index) in mission.extra_data"
                  :key="index"
                >
                  <td v-for="(tableData, index) in tableRow" :key="index">
                    {{ tableData }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </BModal>
      </article>
    </div>
  </section>
</template>
<script>
import CheckedIcon from "@/parts/Front/components/Icon/Checked.vue";
import CrossIcon from "@/parts/Front/components/Icon/Cross.vue";
import { BModal, VBModal } from "bootstrap-vue";
export default {
  name: "clubMissions",
  components: {
    CheckedIcon,
    CrossIcon,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("front/getClubMissionsFromServer");
  },
  computed: {
    missions() {
      return this.$store.getters["front/getClubMissions"];
    },
  },
  methods: {
    missionLinkResolver(link) {
      switch (link) {
        case "profile":
          return { link: "/user-panel", externalLink: false };
        case "categories":
          return { link: "/all-categories", externalLink: false };
        case "comments":
          return {
            link: "/user-panel?section=product-Purchased",
            externalLink: false,
          };
        case "before-after":
          return { link: "/club-after-before", externalLink: false };
        case "instagram":
          return {
            link: "https://instagram.com/benedito.ir?igshid=OGQ5ZDc2ODk2ZA==",
            externalLink: true,
          };
        case "enamad":
          return {
            link: "https://trustseal.enamad.ir/?id=325870&Code=cWzNpT3wulmDJpmWKe96",
            externalLink: true,
          };
        default:
          return { link: "/club-missons", externalLink: false };
      }
    },
  },
};
</script>
<style scoped>
.mission-more-btn {
  position: absolute;
  top: 8%;
  left: 4%;
}
@media screen and (max-width: 540px) {
  .mission-more-btn {
    position: absolute;
    top: 13%;
    left: 6%;
  }
}
</style>
