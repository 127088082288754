<template>
  <div class="d-flex mores-btn flex-nowrap fontsize-small order-xl-1 order-2">
    <button :disabled="filter.sort == 'most_visited' || loading"
            :class="filter.sort == 'most_visited' ? 'view-order-active' : ''"
            @click="filter.sort = 'most_visited' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999 w-s-nowrap product-view-order-button">
      پربازدید ترین
    </button>
    <!-- <span class="vertical-line" /> -->
    <!-- <button :disabled="filter.sort == 'low_to_high' || loading"
            :class="filter.sort == 'low_to_high' ? 'view-order-active' : ''"
            @click="filter.sort = 'low_to_high' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      ارزان ترین
    </button> -->
    <!-- <span class="vertical-line" />
    <button :disabled="filter.sort == 'high_to_low' || loading"
            :class="filter.sort == 'high_to_low' ? 'view-order-active' : ''"
            @click="filter.sort = 'high_to_low' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      گران ترین
    </button> -->
    <!-- <span class="vertical-line" />
    <button :disabled="filter.sort == 'top_sales' || loading"
            :class="filter.sort == 'top_sales' ? 'view-order-active' : ''"
            @click="filter.sort = 'top_sales' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999  ">
      پرفروش ترین
    </button> -->
    <!-- <span class="vertical-line" /> -->
    <button :disabled="filter.sort == 'newest' || loading"
            :class="filter.sort == 'newest' ? 'view-order-active' : ''"
            @click="filter.sort = 'newest' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999 bg-color-f0 box-shaddow20 w-s-nowrap product-view-order-button ">
      جدید ترین
    </button>
    <!-- <span class="vertical-line" /> -->
    <button :disabled="filter.sort == 'most_discount' || loading"
            :class="filter.sort == 'most_discount' ? 'view-order-active' : ''"
            @click="filter.sort = 'most_discount' , productsFilter()" type="button"
            class="ml-1 py-2 px-3 text-color-999 w-s-nowrap product-view-order-button">
      ویژه
    </button>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: "ProductsSort",
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      filter: cloneDeep(this.value)
    }
  },
  watch: {
      value(newVal) {
        this.filter = cloneDeep(newVal)
      }
  },
  computed: {
    disabled() {
      return this.$store.getters['front/getProductsStatus']
    },
  },
  methods: {
    productsFilter() {
      this.filter.page = 1
      this.$emit('input', this.filter)
    }
  }
}
</script>
<style scoped>
.product-view-order-button{
  background-color: var(--color-f0);
  border-radius: 20px;
}
.product-view-order-button:hover{
  background-color: var(--color-theme) !important;
  color: var(--color-f)!important;

}
.view-order-active {
  color: var(--color-f) !important;
  background-color: var(--color-theme) !important;
}
.vertical-line {
  height: auto;
  width: 2px;
  background-image: linear-gradient(
      180deg, white, #f4a6d738, white);
}
@media screen and (max-width: 768px) {
  .mores-btn{
    width: 100% !important;
    gap: 8px;
    margin-top: 1rem;
   
  }
}
@media screen and (max-width: 468px) {
  .mores-btn{
    width: 100% !important;
    /* justify-content: space-between !important; */
    gap: 8px;
    margin-top: 1rem;
   
  }
}
</style>
