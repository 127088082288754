<template>
  <div id="specifications" class="tab-pane fade pb-3" :class="activeTab === 'specifications' ? 'in active show' :''">
    <div class="">
      <table class="table fontsize-small">
        <tbody>
        <tr v-for="specification in product.specifications" :key="specification.id">
          <th scope="row" class="text-center w-25 border-0"> {{specification.label != null ? specification.label : specification.name }}</th>
          <td class="text-right text-grey-8b pr-3 pr-sm-4 border-0"
              :class="{'multiline': specification.pivot && specification.pivot.value
                    && specification.pivot.value.length > 100}">
            <template v-if="specification.pivot && specification.pivot.specification_values != ''">
              <span v-for="(values, index) in specification.pivot.specification_values" :key="index"> {{values.value }} {{index != specification.pivot.specification_values.length - 1 ? '-' : ''}} </span>
            </template>
            <template v-else-if="specification.pivot && specification.pivot.specification_value">
              {{ specification.pivot.specification_value.value }}
            </template>
            <template v-else-if="specification.pivot && specification.pivot.value">
              {{ specification.pivot.value }}
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductSpecifications',
  props:['activeTab' , 'product'],
}
</script>
<style scoped>
#specifications table {
  border-collapse:separate;
  border-spacing:0 10px;
}
#specifications table th {
  background-color: #f3f3f3;
}
#specifications table td {
  background-color: #fafafa;
}
#specifications table tr:first-child th {
  border-top-right-radius: 10px;
}
#specifications table tr:first-child td {
  border-top-left-radius: 10px;
}
#specifications table tr:last-child th {
  border-bottom-right-radius: 10px;
}
#specifications table tr:last-child td {
  border-bottom-left-radius: 10px;
}
@media screen and (max-width: 768px) {
  table tr th:first-child {
    text-align: right!important;
  }

  .multiline {
    line-height: 2.1!important;
  }

}

table td {
  white-space: pre-wrap;
}

.multiline {
  line-height: 2.4;
}
</style>
