<template>
  <article id="timedProduct" class="border-r-35 px-4 pb-1">
    <a
      class="d-inline-block w-100 h-100"
      @click.prevent="cachProduct"
      :href="to.href"
    >
      <template v-if="product.discount_until">
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-white m-0 fontsize-heavy w-s-nowrap">بنی باکس</p>
          <CountDownTimer :deadline="product.discount_until" />
        </div>
      </template>
      <div
        v-if="product && comparable"
        @click="$store.commit('front/toggleCompare', { product, $root })"
        class="compare-btn"
        :selected="isComparing"
      >
        مقایسه
        <Tick class="mr-1" :value="isComparing" />
      </div>
      <div class="w-100 position-relative">
        <img
          loading="lazy"
          v-if="product && mainImage"
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
          class="w-100 border-r-20"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
        <template v-if="product && product.major_final_price">
          <DiscountTag
            class="product-discount-tag"
            :max_discount_variety="product.max_discount_variety"
            :finalPrice="product.major_final_price"
          />
        </template>
      </div>
      <div
        v-if="product"
        class="d-flex align-items-center justify-content-center flex-column gap-8 pt-2"
      >
        <h1
          class="w-100 text-center text-white fontsize-medium m-0 font-w-700 title-overflow"
        >
          {{ product.title }}
        </h1>
        <div class="dashed-line"></div>
        <div>
          <div
            class="d-flex align-items-center justify-content-center flex-column gap-4"
            v-if="product.status == 'available'"
          >
            <div
              class="fontsize-small text-color-themeRed text-center bg-white border-r-30 px-3 py-1"
            >
              <span>
                {{ mainPrice | price }}
              </span>
              <span>تومان</span>
            </div>
            <span v-if="discountAmount != 0">
              <span
                class="fontsize-small text-white text-center position-relative discount-price line-through"
                >{{ ((mainPrice + discountAmount) | price) + "  تومان" }}</span
              >
            </span>
          </div>
          <div v-else>
            <span
              class="text-white fontsize-small text-center"
              v-if="product.status == 'out_of_stock'"
            >
              تمام شد
            </span>
            <span
              class="text-white fontsize-small text-center"
              v-if="product.status == 'soon'"
            >
              به زودی
            </span>
          </div>
        </div>
      </div>
    </a>
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";
import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import Tick from "@/parts/Front/components/shared/Tick";
import DiscountTag from "@/parts/Front/components/product/DiscountTag.vue";
import CountDownTimer from "@/parts/Front/components/shared/CountDownTimer.vue";

export default {
  name: "Product",
  components: {
    Tick,
    DiscountTag,
    BFormRating,
    BSkeleton,
    DiscountTag,
    CountDownTimer,
  },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImage,
    cachProduct() {
      let product = this.product;
      product.isPreview = true;
      this.$store.commit("front/setProductLinkedInfo", product);
      this.$router.push(this.to.href);
    },
  },
  computed: {
    to() {
      return this.$router.resolve({
        name: "product.detail",
        params: { id: this.product.id, slug: this.product.slug },
      });
    },
    mainPrice() {
      // jor keshi front
      let sort =
        this.$route.query.sort &&
        (this.$route.query.sort == "high_to_low" ||
          this.$route.query.sort == "low_to_high");
      return sort
        ? this.product
          ? this.$route.query.sort == "low_to_high"
            ? this.product.minor_variety_price
            : this.product.major_variety_price
          : null
        : this.product
        ? this.product.price
        : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
  },
};
</script>

<style scoped>
#timedProduct {
  background-color: #ff294f;
}
/* #timedProduct .discount-price::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
  transform: rotate(-10deg);
} */
#timedProduct .dashed-line {
  border: 1px dashed #ffffffc2;
  width: 100%;
}

#timedProduct .product-discount-tag {
  transform: rotate(-90deg);
  transform-origin: bottom right;
}
#timedProduct .title-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#timedProduct .recommended-discount {
  top: -6%;
  right: 6%;
}
#timedProduct .compare-btn {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}

#timedProduct .compare-btn:not([selected]) {
  opacity: 0;
}

#timedProduct .compare-btn[selected] {
  opacity: 1 !important;
}

#timedProduct .compare-btn input {
  transform: translateY(3px);
}
#timedProduct .compare-btn input:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  #timedProduct .compare-btn:not([selected]) {
    opacity: 0.7;
  }
}

#timedProduct .compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
#timedProduct .compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
