<template>
  <a @click="goToCompare" v-if="compares.length" @mouseenter="$emit('entered')" @mouseleave="$emit('left')"
     class="compare-btn js-start-compare-btn">
    <span>مقایسه</span>
    <span class="compare-btn-count">
      <span class="js-compare-count">{{ compares.length }}</span>
      کالا</span>
    <span class="d-block d-md-none close-x">X</span>
  </a>
</template>

<script>
export default {
  name: "Compare",
  methods:{
    goToCompare(e) {
      if (e.target.classList.contains('close-x')) {
        this.$store.commit('front/resetCompare')

        return;
      }
      if (!this.compares.length) return;
      this.$router.push({
        path: '/compare', query: {
          compares: this.compares.map(i => i.id).join(',')
        }
      })
    }
  },
  computed: {
    compares() {
      return this.$store.getters['front/getCompares'];
    }
  }

}
</script>

<style scoped>
.compare-btn {
  position: fixed;
  bottom: 84px;
  left: 30px;
  background: #f8f8f8;
  border: 1px solid var(--color-theme2);
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  line-height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px 10px 10px;
  width: 145px;
  height: 40px;
  cursor: pointer;
  z-index: 3;
}


@media screen and (max-width: 768px) {
  .compare-btn {
    padding-left: 0px;
  }

  .close-x {
    width: 18px;
  }
}

.compare-btn-count {
  padding: 1px 5px;
  background-color: rgba(0, 0, 0, .15);
  border-radius: 3px;
  z-index: 1;
}
</style>
