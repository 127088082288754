<template>
  <section v-if="product != null" class="product-details">
    <div
      class="d-flex align-items-center justify-content-center flex-column gap-10"
    >
      <div
        class="d-flex align-items-center gap-5 border-r-25 bg-color-theme padding-t-2 padding-b-2 padding-r-10 padding-l-10 text-light"
        v-if="prices.discount_price"
      >
        <span>{{ prices.discount_price | price }}</span>
        <span>تخفیف</span>
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center text-center"
      >
        <template v-if="!product['title'].includes('\n')">
          <h1 class="fontsize-heavy font-w-900 text-color-444">
            {{ product.title }}
          </h1>
        </template>
        <template v-else>
          <h1 class="fontsize-heavy font-w-900 text-color-444">
            {{ product["title"].slice(0, product["title"].indexOf("\n")) }}
          </h1>
          <strong class="fontsize-small text-color-666">
            {{
              product["title"].slice(
                product["title"].indexOf("\n"),
                product["title"].length
              )
            }}
          </strong>
        </template>
      </div>
      <h6 class="fontsize-small text-color-999">
        {{ product.brand ? product.brand.name + "/" : "" }}
        <template v-if="product.categories != '' && !product.isPreview">
        </template>
        <span v-for="(cat, index) in product.categories" :key="cat.id">
          {{ cat.title }}
          {{ index != product.categories.length - 1 ? "،" : "" }}
        </span>
      </h6>
      <div class="d-flex align-items-center gap-15 mb-3">
        <ProductFavorite :productId="product ? product.id : null" />
        <ProductShare :product="product" />
        <ProductIsAmazing :product="product" />
        <ProductComparison :product="product" />
        <ProductIsExist
          :product="product"
          v-if="product && !product.isPreview"
          :selectedVariety="selectedVariety"
        />
      </div>
      <div class="mini-line"></div>
    </div>
    <Galleries
      :selectedVariety="selectedVarietyForGallery"
      :galleries="galleries"
    />
    <div class="border-top padding-t-20">
      <section class="product-details-content d-flex flex-column">
        <div
          v-if="
            product &&
            !product.isPreview &&
            selectedVariety &&
            selectedVariety.quantity == 0
          "
        >
          <span class="text-color-themeRed weight-bold fontsize-heavy"
            >تمام شد</span
          >
        </div>
        <template v-if="product && !product.isPreview">
          <div
            v-if="product.getColors().length"
            :style="isAvailable ? '' : 'opacity: 0.5'"
            class="product-details-color-choose pt-3 text-right"
          >
            <ProductColors v-model="product" />
          </div>
        </template>

        <div class="attributes-container">
          <ProductSizeChart
            v-if="
              shouldShowSizeChartModal &&
              product != null &&
              !product.isPreview &&
              product.size_charts != ''
            "
            :sizeChart="product.size_charts"
          />
          <template v-if="product && !product.isPreview">
            <ProductAttributes
              :style="isAvailable ? '' : 'opacity: 0.7'"
              v-model="product"
            />
          </template>
        </div>
        <p
          class="mt-3 text-success"
          v-if="
            product &&
            !product.isPreview &&
            selectedVariety &&
            alreadyExistedCart
          "
        >
          <svg
            class="notice-icon"
            fill="#28a745"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20px"
            height="20px"
            viewBox="0 0 122.88 122.88"
            enable-background="new 0 0 122.88 122.88"
            xml:space="preserve"
          >
            <g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61.44,0c33.926,0,61.44,27.514,61.44,61.44c0,33.926-27.514,61.439-61.44,61.439 C27.513,122.88,0,95.366,0,61.44C0,27.514,27.513,0,61.44,0L61.44,0z M52.687,90.555H69.44v14.81H52.687V90.555L52.687,90.555z M69.431,82.96H52.691c-1.665-20.343-5.159-29.414-5.159-49.729c0-7.492,6.075-13.57,13.567-13.57s13.57,6.078,13.57,13.57 C74.67,53.535,71.13,62.633,69.431,82.96L69.431,82.96z"
              />
            </g>
          </svg>
          <span
            >{{ alreadyExistedCart.quantity }} عدد از این محصول در سبد خرید شما
            وجود دارد</span
          >
        </p>
      </section>

      <div
        class="row d-flex align-items-end flex-wrap flex-lg-nowrap justify-content-between margin-t-20 gap-15"
        v-if="!product.isPreview"
      >
        <div class="row d-flex align-items-end gap-15 col-12 col-lg-7">
          <div class="col-12 col-lg-4">
            <template v-if="isAvailable">
              <span class="quantityTitle"
                >تعداد :
                <span
                  style="color: #eb0037"
                  v-if="
                    productSelectedVariety &&
                    productSelectedVariety.quantity < 20
                  "
                  >{{
                    `(موجودی انبار: ${productSelectedVariety.quantity})`
                  }}</span
                >
              </span>
              <div class="d-flex align-items-center gap-5">
                <div
                  class="border border-r-25 py-2 px-3 text-grey-8b font-w-900 fontsize-medium w-s-nowrap"
                >
                  تعداد محصول
                </div>
                <!-- <div id="qtySelect" class="border border-r-25 py-2 px-3"> -->
                <ProductSelectQuantity v-model="product"/>
                <!-- <select>
                    <option class="p-3" v-for="i in 10" :key="i">{{i}}</option>
                  </select>
                  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.89007 17.9487C6.85674 17.9162 6.71421 17.7936 6.59697 17.6794C5.85961 17.0098 4.65272 15.263 4.28433 14.3487C4.22513 14.2098 4.09984 13.8588 4.0918 13.6712C4.0918 13.4915 4.13318 13.3202 4.21708 13.1567C4.33433 12.9529 4.51881 12.7894 4.73662 12.6999C4.88777 12.6422 5.34007 12.5526 5.34812 12.5526C5.84295 12.463 6.64697 12.4138 7.53547 12.4138C8.38203 12.4138 9.15329 12.463 9.65559 12.5364C9.66364 12.5448 10.2257 12.6343 10.4182 12.7323C10.77 12.912 10.9883 13.2631 10.9883 13.6388V13.6712C10.9797 13.9159 10.7613 14.4304 10.7533 14.4304C10.3843 15.2954 9.23662 17.0019 8.47398 17.6878C8.47398 17.6878 8.278 17.8809 8.15559 17.9649C7.97973 18.0959 7.76191 18.1609 7.5441 18.1609C7.30099 18.1609 7.07456 18.0876 6.89007 17.9487Z" fill="#B9B9B9"/>
                  <path d="M6.89007 4.57999C6.85674 4.61246 6.71421 4.7351 6.59697 4.8493C5.85961 5.51889 4.65272 7.26573 4.28433 8.17999C4.22513 8.31884 4.09984 8.66989 4.0918 8.85745C4.0918 9.03717 4.13318 9.20849 4.21708 9.37198C4.33433 9.57577 4.51881 9.73925 4.73662 9.82883C4.88777 9.8865 5.34007 9.97608 5.34812 9.97608C5.84295 10.0657 6.64697 10.1149 7.53547 10.1149C8.38203 10.1149 9.15329 10.0657 9.65559 9.99231C9.66364 9.98392 10.2257 9.89434 10.4182 9.79636C10.77 9.61664 10.9883 9.2656 10.9883 8.88992V8.85745C10.9797 8.61278 10.7613 8.09826 10.7533 8.09826C10.3843 7.23326 9.23662 5.52677 8.47398 4.84091C8.47398 4.84091 8.278 4.64775 8.15559 4.56378C7.97973 4.43274 7.76191 4.3678 7.5441 4.3678C7.30099 4.3678 7.07456 4.44114 6.89007 4.57999Z" fill="#B9B9B9"/>
                  </svg> -->
                <!-- </div>  -->
              </div>
            </template>
            <template v-else>
              <!--    ناموجود      -->
            </template>
          </div>
          <div class="col-12 col-lg-7">
            <div
              class="align-items-center justify-content-end d-flex flex-row-reverse gap-10 bg-color-eai border-r-25 py-2 px-3"
              style="width: fit-content;"
            >
              <template v-if="isAvailable">
                <div
                  class="d-flex align-items-center gap-5"
                  :class="
                    prices.discount_price
                      ? 'line-through-discount-price text-muted fontsize-small'
                      : 'fontsize-heavy'
                  "
                >
                  <span>{{ prices.price | price }}</span>
                  <span>تومان</span>
                </div>
                <div
                  class="d-flex align-items-center gap-5 text-color-grey weight-bold fontsize-heavy"
                  v-if="prices.discount_price"
                >
                  <span>{{ prices.final_price | price }}</span>
                  <span>تومان</span>
                </div>
                <h6 class="font-w-900 text-color-grey m-0 fontsize-heavy">
                  قیمت:
                </h6>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.4915 17.2203C22.1576 19.9115 19.952 22.1172 17.2607 22.4511C15.6318 22.6534 14.1141 22.2082 12.9304 21.3381C12.2525 20.8424 12.4144 19.7901 13.2238 19.5473C16.2692 18.6266 18.667 16.2186 19.5979 13.1732C19.8407 12.3739 20.8929 12.212 21.3887 12.8798C22.2487 14.0737 22.6939 15.5913 22.4915 17.2203Z"
                    fill="#5C5C5C"
                  />
                  <path
                    d="M10.4209 2.29675C5.95902 2.29675 2.33691 5.91886 2.33691 10.3807C2.33691 14.8426 5.95902 18.4647 10.4209 18.4647C14.8828 18.4647 18.5049 14.8426 18.5049 10.3807C18.4947 5.91886 14.8828 2.29675 10.4209 2.29675ZM9.46983 9.24756L11.9082 10.0974C12.7884 10.4111 13.2134 11.0283 13.2134 11.9793C13.2134 13.072 12.3432 13.9725 11.2809 13.9725H11.1898V14.0231C11.1898 14.4379 10.8458 14.7819 10.431 14.7819C10.0162 14.7819 9.67219 14.4379 9.67219 14.0231V13.9624C8.54913 13.9118 7.64866 12.9708 7.64866 11.7972C7.64866 11.3824 7.99266 11.0384 8.40749 11.0384C8.82231 11.0384 9.16631 11.3824 9.16631 11.7972C9.16631 12.1614 9.42936 12.4548 9.75313 12.4548H11.2708C11.5035 12.4548 11.6856 12.2424 11.6856 11.9793C11.6856 11.6252 11.6249 11.605 11.3922 11.524L8.95384 10.6741C8.08372 10.3706 7.64866 9.75344 7.64866 8.79226C7.64866 7.69956 8.51878 6.79909 9.58113 6.79909H9.67219V6.75862C9.67219 6.3438 10.0162 5.9998 10.431 5.9998C10.8458 5.9998 11.1898 6.3438 11.1898 6.75862V6.81933C12.3129 6.86992 13.2134 7.81085 13.2134 8.9845C13.2134 9.39932 12.8694 9.74332 12.4545 9.74332C12.0397 9.74332 11.6957 9.39932 11.6957 8.9845C11.6957 8.62026 11.4327 8.32685 11.1089 8.32685H9.59125C9.35854 8.32685 9.17642 8.53932 9.17642 8.80238C9.16631 9.14638 9.22701 9.16662 9.46983 9.24756Z"
                    fill="#5C5C5C"
                  />
                </svg>
              </template>
              <template v-else-if="product.status === 'soon'">
                <div class="font-weight-bold text-info">بزودی</div>
              </template>
              <template v-else>
                <div class="font-weight-bold text-danger">تمام شد</div>
              </template>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start justify-content-lg-end col-12 col-lg-3">
          <template v-if="product && product.isPreview">
            <button
              class="product-details-cart-btn d-flex align-items-center gap-10 border-r-25 padding-r-25 padding-l-25 padding-t-10 padding-b-10 bg-color-theme text-white"
            >
              <span class="fontsize15">{{ "افزودن به سبد خرید" }}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99984 12.3333C6.43984 12.3333 5.1665 11.06 5.1665 9.5C5.1665 9.22667 5.39317 9 5.6665 9C5.93984 9 6.1665 9.22667 6.1665 9.5C6.1665 10.5133 6.9865 11.3333 7.99984 11.3333C9.01317 11.3333 9.83317 10.5133 9.83317 9.5C9.83317 9.22667 10.0598 9 10.3332 9C10.6065 9 10.8332 9.22667 10.8332 9.5C10.8332 11.06 9.55984 12.3333 7.99984 12.3333Z"
                  fill="white"
                />
                <path
                  d="M3.45976 4.25336C3.33309 4.25336 3.19976 4.20002 3.10643 4.10669C2.91309 3.91336 2.91309 3.59336 3.10643 3.40002L5.52643 0.980022C5.71976 0.786689 6.03976 0.786689 6.23309 0.980022C6.42643 1.17336 6.42643 1.49336 6.23309 1.68669L3.81309 4.10669C3.71309 4.20002 3.58643 4.25336 3.45976 4.25336Z"
                  fill="white"
                />
                <path
                  d="M12.5399 4.25336C12.4132 4.25336 12.2866 4.20669 12.1866 4.10669L9.76658 1.68669C9.57325 1.49336 9.57325 1.17336 9.76658 0.980022C9.95992 0.786689 10.2799 0.786689 10.4732 0.980022L12.8932 3.40002C13.0866 3.59336 13.0866 3.91336 12.8932 4.10669C12.7999 4.20002 12.6666 4.25336 12.5399 4.25336Z"
                  fill="white"
                />
                <path
                  d="M13.4735 7.06669C13.4268 7.06669 13.3802 7.06669 13.3335 7.06669H13.1802H2.66683C2.20016 7.07336 1.66683 7.07336 1.28016 6.68669C0.973496 6.38669 0.833496 5.92002 0.833496 5.23336C0.833496 3.40002 2.1735 3.40002 2.8135 3.40002H13.1868C13.8268 3.40002 15.1668 3.40002 15.1668 5.23336C15.1668 5.92669 15.0268 6.38669 14.7202 6.68669C14.3735 7.03336 13.9068 7.06669 13.4735 7.06669ZM2.8135 6.06669H13.3402C13.6402 6.07336 13.9202 6.07336 14.0135 5.98002C14.0602 5.93336 14.1602 5.77336 14.1602 5.23336C14.1602 4.48002 13.9735 4.40002 13.1802 4.40002H2.8135C2.02016 4.40002 1.8335 4.48002 1.8335 5.23336C1.8335 5.77336 1.94016 5.93336 1.98016 5.98002C2.0735 6.06669 2.36016 6.06669 2.6535 6.06669H2.8135Z"
                  fill="white"
                />
                <path
                  d="M9.92683 15.1667H5.90683C3.52016 15.1667 2.98683 13.7467 2.78016 12.5134L1.84016 6.74669C1.7935 6.47335 1.98016 6.22002 2.2535 6.17335C2.52016 6.12669 2.78016 6.31335 2.82683 6.58669L3.76683 12.3467C3.96016 13.5267 4.36016 14.1667 5.90683 14.1667H9.92683C11.6402 14.1667 11.8335 13.5667 12.0535 12.4067L13.1735 6.57335C13.2268 6.30002 13.4868 6.12002 13.7602 6.18002C14.0335 6.23335 14.2068 6.49335 14.1535 6.76669L13.0335 12.6C12.7735 13.9534 12.3402 15.1667 9.92683 15.1667Z"
                  fill="white"
                />
              </svg>
            </button>
          </template>
          <template v-else>
            <button
              @click="addToCart()"
              :disabled="disabled || !isAvailable"
              id="product-details-cart-btns"
              class="product-details-cart-btn d-flex align-items-center gap-10 border-r-25 padding-r-25 padding-l-25 padding-t-10 padding-b-10 bg-color-theme text-white"
            >
              <span class="fontsize15">{{
                !this.product || isAvailable ? "افزودن به سبد خرید" : "تمام شد"
              }}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99984 12.3333C6.43984 12.3333 5.1665 11.06 5.1665 9.5C5.1665 9.22667 5.39317 9 5.6665 9C5.93984 9 6.1665 9.22667 6.1665 9.5C6.1665 10.5133 6.9865 11.3333 7.99984 11.3333C9.01317 11.3333 9.83317 10.5133 9.83317 9.5C9.83317 9.22667 10.0598 9 10.3332 9C10.6065 9 10.8332 9.22667 10.8332 9.5C10.8332 11.06 9.55984 12.3333 7.99984 12.3333Z"
                  fill="white"
                />
                <path
                  d="M3.45976 4.25336C3.33309 4.25336 3.19976 4.20002 3.10643 4.10669C2.91309 3.91336 2.91309 3.59336 3.10643 3.40002L5.52643 0.980022C5.71976 0.786689 6.03976 0.786689 6.23309 0.980022C6.42643 1.17336 6.42643 1.49336 6.23309 1.68669L3.81309 4.10669C3.71309 4.20002 3.58643 4.25336 3.45976 4.25336Z"
                  fill="white"
                />
                <path
                  d="M12.5399 4.25336C12.4132 4.25336 12.2866 4.20669 12.1866 4.10669L9.76658 1.68669C9.57325 1.49336 9.57325 1.17336 9.76658 0.980022C9.95992 0.786689 10.2799 0.786689 10.4732 0.980022L12.8932 3.40002C13.0866 3.59336 13.0866 3.91336 12.8932 4.10669C12.7999 4.20002 12.6666 4.25336 12.5399 4.25336Z"
                  fill="white"
                />
                <path
                  d="M13.4735 7.06669C13.4268 7.06669 13.3802 7.06669 13.3335 7.06669H13.1802H2.66683C2.20016 7.07336 1.66683 7.07336 1.28016 6.68669C0.973496 6.38669 0.833496 5.92002 0.833496 5.23336C0.833496 3.40002 2.1735 3.40002 2.8135 3.40002H13.1868C13.8268 3.40002 15.1668 3.40002 15.1668 5.23336C15.1668 5.92669 15.0268 6.38669 14.7202 6.68669C14.3735 7.03336 13.9068 7.06669 13.4735 7.06669ZM2.8135 6.06669H13.3402C13.6402 6.07336 13.9202 6.07336 14.0135 5.98002C14.0602 5.93336 14.1602 5.77336 14.1602 5.23336C14.1602 4.48002 13.9735 4.40002 13.1802 4.40002H2.8135C2.02016 4.40002 1.8335 4.48002 1.8335 5.23336C1.8335 5.77336 1.94016 5.93336 1.98016 5.98002C2.0735 6.06669 2.36016 6.06669 2.6535 6.06669H2.8135Z"
                  fill="white"
                />
                <path
                  d="M9.92683 15.1667H5.90683C3.52016 15.1667 2.98683 13.7467 2.78016 12.5134L1.84016 6.74669C1.7935 6.47335 1.98016 6.22002 2.2535 6.17335C2.52016 6.12669 2.78016 6.31335 2.82683 6.58669L3.76683 12.3467C3.96016 13.5267 4.36016 14.1667 5.90683 14.1667H9.92683C11.6402 14.1667 11.8335 13.5667 12.0535 12.4067L13.1735 6.57335C13.2268 6.30002 13.4868 6.12002 13.7602 6.18002C14.0335 6.23335 14.2068 6.49335 14.1535 6.76669L13.0335 12.6C12.7735 13.9534 12.3402 15.1667 9.92683 15.1667Z"
                  fill="white"
                />
              </svg>
            </button>
          </template>
        </div>
      </div>
    </div>
    <ProductCongratulations
      v-if="showCongratulations"
      v-model="showCongratulations"
    />
  </section>
</template>
<script>
import { cartListsOpen } from "@/libraries/functions";
import Galleries from "@/parts/Front/components/productDetail/ProductGalleries";
import ProductAttributes from "@/parts/Front/components/productDetail/ProductAttributes";
import ProductColors from "@/parts/Front/components/productDetail/ProductColors";
import ProductSelectQuantity from "@/parts/Front/components/productDetail/ProductSelectQuantity";
import ProductFavorite from "@/parts/Front/components/productDetail/ProductFavorite";
import ProductCongratulations from "@/parts/Front/components/productDetail/ProductCongratulations";
import ProductShare from "@/parts/Front/components/productDetail/ProductShare";
import ProductSizeChart from "@/parts/Front/components/productDetail/ProductSizeChart";
import ProductComparison from "@/parts/Front/components/productDetail/ProductComparison.vue";
import ProductIsExist from "@/parts/Front/components/productDetail/ProductIsExist.vue";
import ProductIsAmazing from "@/parts/Front/components/productDetail/ProductIsAmazing.vue";
import BagSvg from "@/parts/Front/components/Icon/BagSvg.vue";
import { BSkeleton } from "bootstrap-vue";
import Tick from "@/parts/Front/components/shared/Tick";

export default {
  props: {
    comparable: {
      default: false,
      type: Boolean,
    },
    value: Object || null,
  },
  components: {
    ProductShare,
    Tick,
    ProductCongratulations,
    ProductSelectQuantity,
    ProductColors,
    ProductAttributes,
    Galleries,
    ProductFavorite,
    ProductSizeChart,
    BSkeleton,
    ProductComparison,
    ProductIsExist,
    ProductIsAmazing,
    BagSvg,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      product: this.value,
      disabled: false,
      listenDisabled: false,
      showCongratulations: false,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      this.checkIosConfig();
      if (!this.product.isPreview) this.selectFirstIfNotSelected();
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    productSelectedVariety() {
      return this.$store.getters["front/getSelectedVaritey"];
    },

    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
    shouldShowSizeChartModal() {
      return this.product && this.product.size_charts != "";
    },
    isMobile() {
      return this.mediaQueries.mobileSize;
    },
    prices() {
      return this.product.getMainPrices();
    },
    selectedVariety() {
      if (!this.product) {
        return null;
      }
      return this.product.getSelectedVariety();
    },
    selectedVarietyForGallery() {
      if (!this.product) {
        return null;
      } else if (this.product && !this.product.isPreview) {
        return this.product.getSelectedVariety(["size"]);
      }
    },
    isAvailable() {
      // آیا قابل خرید هست اصلا
      // حتی اگر یک تنوعش قابل خرید باشه هست
      return (
        this.product && this.product.status === "available" && this.hasQuantity
      );
    },
    hasQuantity() {
      if (this.product && !this.product.isPreview)
        return this.product && this.product.getMaxQuantity() > 0;
      return 0;
    },
    alreadyExistedCart() {
      // اگر تنوع انتخاب شده توی سبد وجود داشت بهمون میده
      if (!this.selectedVariety || !this.$store.getters["front/getHomeData"]) {
        return null;
      }
      return this.$store.getters["front/getHomeData"].user.cart.hasVariety(
        this.selectedVariety
      );
    },
    // عکس های محصول و تنوع رو هم
    galleries() {
      if (!this.value) {
        return null;
      }
      let images = [];
      if (
        this.value.varieties &&
        this.value.varieties[0] &&
        this.value.varieties[0].images &&
        this.value.varieties[0].images.length
      ) {
        console.log(2);
        this.value.varieties.forEach((variety) => {
          images.push({
            ...variety.images[0],
            variety_id: variety.id,
          });
        });
        return images;
      } else {
        console.log(1);
        if (this.value.isPreview) {
          this.value.images.forEach((img) => {
            images.push({
              ...img,
              variety_id: null,
            });
          });
          return images;
        }
        if (this.selectedVarietyForGallery) {
          this.product.getSelectedVariety("size", true).forEach((variety) => {
            variety.images.forEach((img) => {
              images.push({
                ...img,
                variety_id: variety.id,
              });
            });
          });
          if (!images.length) {
            return this.product.images;
          }

          return images;
        }
        let firstVariety = this.product.varieties[0];
        let selectedAttributesId = firstVariety.attributes.map((attr) => {
          return {
            id: attr.id,
            value: attr.pivot.value,
            valueId: attr.pivot.attribute_value_id,
          };
        });
        this.product
          .getSelectedVariety(
            "size",
            true,
            firstVariety.color_id,
            selectedAttributesId
          )
          .forEach((variety) => {
            variety.images.forEach((img) => {
              images.push({
                ...img,
                variety_id: variety.id,
              });
            });
          });
        this.value.images.forEach((img) => {
          images.push({
            ...img,
            variety_id: null,
          });
        });
        this.value.varieties.forEach((variety) => {
          variety.images.forEach((img) => {
            images.push({
              ...img,
              variety_id: variety.id,
            });
          });
        });
        return images;
      }
    },
    hasInListens() {
      let homeData = this.$store.getters["front/getHomeData"];
      if (!homeData) {
        return false;
      }
      let user = homeData.user.user;
      if (!user) {
        return false;
      }

      return user.listen_charges.find((listenCharge) => {
        return listenCharge.product_id == this.product.id;
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkIosConfig();
    });
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "other";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Ios";
      }

      return "other";
    },
    checkIosConfig() {
      if (this.getMobileOperatingSystem() == "Ios") {
        document.getElementById(
          "product-details-cart-btns"
        ).style.paddingBottom = "32px";
      }
    },
    selectFirstIfNotSelected() {
      this.product.selectFirstIfNotSelected();
    },
    async addToCart() {
      this.disabled = true;
      try {
        const response = await this.product.addToCart();
        if (this.isMobile) {
          this.showCongratulations = true;
        } else {
          this.$root.notify(response.data.message, "success");
          cartListsOpen();
          this.$router.push(this.$route.fullPath + "#cart-modal");
        }
      } catch (error) {
        if (error.message === "SELECT-VARIETY") {
          this.$root.debouncedNotify(
            "لطفا گزینه های محصول را انتخاب کنید",
            "warning"
          );
          let scrollDiv = document.querySelector(
            ".attributes-container"
          ).offsetTop;
          window.scrollTo({ top: scrollDiv - 100, behavior: "smooth" });
        } else if (error.message === "SELECT-QUANTITY") {
          this.$root.debouncedNotify("لطفا تعداد را انتخاب کنید", "warning");
        } else if (error.message === "NO_STOCK") {
          // This error may happen if cart is in "cookie" mode
          this.$root.errorNotify("موجودی محصول کافی نیست");
        } else {
          this.$root.notify(error);
        }
      } finally {
        this.disabled = false;
      }
    },
    // آیا این کاربر قبلا برای مطلع شدن از موجود شدن این محصول اقدام کرده
    async listenRequest() {
      let homeData = this.$store.getters["front/getHomeData"];
      if (!homeData.user.user) {
        window.$("#loginModal").modal("show");
        return;
      }
      try {
        this.listenDisabled = true;
        const response = await this.$axios.post(
          `customer/products/${this.product.id}/listen`
        );
        homeData.user.user.listen_charges.push(response.data.data.listenCharge);
        this.$root.notify(response, "success");
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
    async unlistenRequet() {
      let homeData = this.$store.getters["front/getHomeData"];
      try {
        this.listenDisabled = true;
        const response = await this.$axios.delete(
          `customer/products/${this.product.id}/unlisten`
        );
        homeData.user.user.listen_charges =
          homeData.user.user.listen_charges.filter((listenCharge) => {
            return listenCharge.product_id != this.product.id;
          });
        this.$root.notify(response, "success");
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
  },
  beforeCreate() {
    this.$store.commit("front/setSelectedVariety", null);
  },
};
</script>
<style scoped>
.notice-icon {
  margin-left: 4px;
  transform: translateY(-3px);
  opacity: 0.7;
}
.cancel-listen-btn {
  padding: 2px 11px;
  font-size: 14px;
}

.text-red {
  color: var(--color-themeRed);
}

.price {
  display: flex;
  gap: 15px;
}

.product-details-color-choose {
  overflow-x: auto;
}
.mini-line {
  width: 5%;
  height: 5px;
  background-color: var(--color-brown-theme);
  border-radius: 20px;
  margin-inline: auto;
}
.line-through-discount-price {
  position: relative;
}
.line-through-discount-price::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--text-color-grey);
  transform: rotate(-7deg);
}
#qtySelect select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .product-details-color-choose {
    margin-right: -14px;
  }
}
.product-details-color-choose::-webkit-scrollbar {
  height: 7px;
  background-color: var(--color-theme2);
}
.product-details-color-choose::-webkit-scrollbar-thumb {
  height: 7px;
  background-color: var(--color-theme);
}
.product-details-color-choose::-webkit-scrollbar-track {
  background-color: var(--color-theme2);
}
.quantityTitle {
  display: block;
  width: max-content;
}
</style>
