<template>
  <div
    id="comments"
    class="tab-pane fade pb-4 in-comments"
    :class="[activeTab === 'comments' ? 'in active show' : '']"
  >
    <div class="d-sm-flex justify-content-start pt-3 px-3 pb-4">
      <div class="d-flex align-items-start flex-column gap-8">
        <h6
          v-if="comments != null && comments.length != 0"
          class="text-color-444 fontsize-medium"
        >
          دیدگاه خود را درباره این کالا بیان کنید.
        </h6>
        <template v-if="comments != null && comments.length == 0">
          <h5
            class="fontsize-medium text-color-444 line-height2 font-weight-bold m-0"
          >
            چطور میتوانم در مورد این کالا نظر بدم...؟
          </h5>
          <h6 class="fontsize-small text-grey-8b line-height2 m-0">
            پس از تایید شدن دیدگاه، با رفتن به صفحه ماموریت‌های بندیتو امتیازتان
            را ، پس از تایید شدن <br />
            دیدگاه، با رفتن به صفحه ماموریت‌های بندیتو امتیازتان را دریافت کنید.
          </h6>
        </template>
        <button
          type="button"
          data-toggle="collapse"
          data-target="#collapseComment"
          aria-expanded="false"
          aria-controls="collapseComment"
          class="send-comment-btn fontsize-small text-light py-2 px-5 bg-color-theme d-block border-r-20"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 16.74C5.79 16.74 5.57249 16.6876 5.37749 16.5826C4.94999 16.3576 4.6875 15.9075 4.6875 15.4275V14.3626C2.4225 14.1301 0.9375 12.465 0.9375 10.08V5.58008C0.9375 3.00008 2.67 1.26758 5.25 1.26758H12.75C15.33 1.26758 17.0625 3.00008 17.0625 5.58008V10.08C17.0625 12.66 15.33 14.3925 12.75 14.3925H9.92249L6.72748 16.5226C6.50998 16.6651 6.255 16.74 6 16.74ZM5.25 2.38507C3.315 2.38507 2.0625 3.63757 2.0625 5.57257V10.0726C2.0625 12.0076 3.315 13.2601 5.25 13.2601C5.5575 13.2601 5.8125 13.5151 5.8125 13.8226V15.4201C5.8125 15.5176 5.8725 15.5626 5.91 15.5851C5.9475 15.6076 6.02251 15.6301 6.10501 15.5776L9.44252 13.3576C9.53252 13.2976 9.64501 13.2601 9.75751 13.2601H12.7575C14.6925 13.2601 15.945 12.0076 15.945 10.0726V5.57257C15.945 3.63757 14.6925 2.38507 12.7575 2.38507H5.25V2.38507Z"
              fill="white"
            />
            <path
              d="M8.99986 9.08252C8.69236 9.08252 8.43736 8.82752 8.43736 8.52002V8.36255C8.43736 7.49255 9.07485 7.06504 9.31485 6.90004C9.59235 6.71254 9.68234 6.58505 9.68234 6.39005C9.68234 6.01505 9.37486 5.70752 8.99986 5.70752C8.62486 5.70752 8.31738 6.01505 8.31738 6.39005C8.31738 6.69755 8.06238 6.95255 7.75488 6.95255C7.44738 6.95255 7.19238 6.69755 7.19238 6.39005C7.19238 5.39255 8.00236 4.58252 8.99986 4.58252C9.99736 4.58252 10.8073 5.39255 10.8073 6.39005C10.8073 7.24505 10.1774 7.67253 9.94487 7.83003C9.65237 8.02503 9.56236 8.15255 9.56236 8.36255V8.52002C9.56236 8.83502 9.30736 9.08252 8.99986 9.08252Z"
              fill="white"
            />
            <path
              d="M9 10.95C8.685 10.95 8.4375 10.695 8.4375 10.3875C8.4375 10.08 8.6925 9.82495 9 9.82495C9.3075 9.82495 9.5625 10.08 9.5625 10.3875C9.5625 10.695 9.315 10.95 9 10.95Z"
              fill="white"
            />
          </svg>
          برای ارسال نظر کلیک کنید
        </button>
      </div>
    </div>
    <section
      class="response-register collapse box-shaddow20 mb-3"
      id="collapseComment"
    >
      <!-- <h6 class="fontsize-small">پاسخی بگذارید</h6> -->
      <!-- <p class="fontsize-mini text-color-666">
        نشانی ایمیل شما منتشر نخواهد شد و بخش های موردنیاز علامت گذاری شده اند.
      </p> -->
      <form class="fontsize13 p-4" @submit.prevent="commentSubmit()">
        <div class="response-register-grid">
          <div class="d-flex flex-column fontsize-mini">
            <input
              class="border box-shaddow20 rounded p-2 mb-2 w-100"
              type="text"
              v-model="comment.title"
              placeholder="عنوان"
            />
            <!-- <input class="bg-color-eai rounded p-2  mb-2" type="text" v-model="comment.name"
                placeholder="نام کامل ..."> -->
            <!-- <input class="bg-color-eai rounded p-2 mb-2" type="email" v-model="comment.email"
                placeholder=" آدرس ایمیل ..."> -->
            <!-- <input class="bg-color-eai rounded p-2 mb-2" type="text" placeholder=" آدرس وب سایت..."> -->
            <b-form-rating
              v-model="comment.rate"
              variant="warning"
              class="border-0 pb-0 pt-3 py-sm-2 pr-sm-3 pr-0"
              size="lg"
            >
            </b-form-rating>
            <b-form-checkbox
              class="pt-3 pr-sm-4 pb-sm-0 pb-3"
              :disabled="disabled"
              v-model="comment.show_customer_name"
              value="1"
              unchecked-value="0"
            >
              <span class="pr-4">نمایش نام شما</span>
            </b-form-checkbox>
          </div>
          <div>
            <textarea
              class="border box-shaddow20 rounded p-2 mb-2 w-100 fontsize-mini"
              v-model="comment.body"
              name=""
              cols="5"
              rows="5"
              placeholder="دیدگاه شما *"
            ></textarea>
          </div>
        </div>

        <div class="text-left">
          <button
            type="submit"
            class="py-2 px-md-5 px-3 bg-color-theme text-light fontsize-small border-r-20"
          >
            ارسال پیام
          </button>
        </div>
      </form>
    </section>
    <Loading v-if="comments == null" />
    <div v-if="comments != ''" class="user-comments px-sm-3 px-2 py-sm-2 pt-4">
      <div
        v-for="(comment, index) in comments"
        :key="comment.id"
        :class="index == 0 ? '' : ''"
        class="user-comments-item pb-3 mb-3"
      >
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mb-2 border-bottom pb-3"
        >
          <div class="d-flex align-items-end gap-7">
            <span>
              <img
                class="rounded-circle"
                width="40"
                height="40"
                src="../../../../assets/images/userDefault.jpg"
                alt=""
              />
            </span>
            <span
              v-if="
                comment.creator &&
                (comment.creator.first_name || comment.creator.last_name)
              "
              class="text-color-grey font-w-900 fontsize-medium"
            >
              {{
                (comment.creator.first_name || "") +
                " " +
                (comment.creator.last_name || "")
              }}
            </span>
            <span class="text-color-grey font-w-900 fontsize-medium" v-else
              >ناشناس</span
            >
            <span class="text-color-999 fontsize-mini">
              {{ moment(comment.created_at).format("dddd DD MMMM YYYY") }}
            </span>
          </div>
          <span>
            <b-form-rating
              v-model="comment.rate"
              variant="warning"
              :readonly="true"
              class="border-0 pb-0 pt-3"
              size="lg"
            >
            </b-form-rating>
          </span>
        </div>
        <h6 class="fontsize-medium text-grey-8b text-justify line-height2">
          {{ comment.title }}
        </h6>
        <p class="text-grey-8b fontsize-small text-justify line-height2">
          {{ comment.body }}
        </p>
      </div>
    </div>

    <!-- <div class="text-center d-sm-none">
      <button type="button" class="fontsize13 text-color-999 bg-none">
        اطلاعات بیشتر ...
      </button>
    </div> -->
  </div>
</template>
<script>
import { BFormRating, BFormCheckbox } from "bootstrap-vue";

import Loading from "@/parts/Front/components/shared/Loading";
export default {
  props: ["activeTab", "product"],
  components: {
    Loading,
    BFormRating,
    BFormCheckbox,
  },
  data() {
    return {
      comment: {
        rate: 4,
        title: "",
        body: "",
        show_customer_name: 1,
      },
      disabled: false,
      comments: null,
      getCommentsStatus: false,
    };
  },
  watch: {
    activeTab(newActiveTab) {
      if (newActiveTab === "comments") {
        this.getComments();
      }
    },
  },
  mounted() {
    if (this.activeTab === "comments") {
      this.getComments();
    }
  },
  methods: {
    getComments() {
      if (this.comments == null && !this.getCommentsStatus) {
        this.getCommentsStatus = true;
        this.$axios
          .get(`front/product-comment/${this.$route.params.id}`)
          .then((response) => {
            this.comments = response.data.data.comment;
          });
      }
    },
    async commentSubmit() {
      if (this.$store.getters["front/getLoginStatus"]) {
        this.disabled = true;
        try {
          const response = await this.$axios.post("customer/product-comment", {
            rate: this.comment.rate,
            title: this.comment.title,
            body: this.comment.body,
            product_id: this.$route.params.id,
            show_customer_name: this.comment.show_customer_name,
          });

          this.$root.notify(response.data.message, "success");
          this.comment.body = "";
          this.comment.title = "";
        } catch (error) {
          this.$root.notify(error);
        } finally {
          this.disabled = false;
        }
      } else {
        this.$root.notify("برای ثبت نظر ابتدا وارد سایت شوید.", "warning");
      }
    },
  },
};
</script>
<style scoped>
.response-register form textarea {
  height: unset !important;
}
</style>
<style>
#comments .form-control:focus {
  box-shadow: unset !important;
}

#comments .form-control-lg {
  height: unset !important;
}

#comments .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--color-theme);
  background-color: var(--color-theme);
}

#comments
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--color-theme) !important;
}

#comments .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(183, 149, 111, 0.5);
}

#comments .custom-control-label::before {
  top: 0;
}

#comments .custom-control-label::after {
  top: 0;
}

#comments .flex-grow-1 {
  flex-grow: unset !important;
}

@media screen and (max-width: 768px) {
  .send-comment-btn {
    display: block;
    margin: auto;
  }
}
</style>
