<template>
  <div id="clubAfterBefore">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        تصویر قبل / بعد باشگاه
      </h3>
    </div>
    <div class="d-flex flex-column align-items-start gap-10 mt-4">
      <div class="col-12 col-md-6">
        <p class="m-0 w-s-nowrap col-5">انتخاب محصول:</p>
        <multiselect
          v-model="selectedProduct"
          placeholder="محصول"
          open-direction="bottom"
          label="title"
          track-by="id"
          :options="products"
          :allow-empty="false"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
      </div>
      <b-form-group
        label="تصویر قبل از استفاده از محصول"
        class="col-12 col-md-6"
      >
        <b-form-file v-model="beforeImage"></b-form-file>
      </b-form-group>
      <b-form-group
        label="تصویر بعد از استفاده از محصول"
        class="col-12 col-md-6"
      >
        <b-form-file v-model="afterImage"></b-form-file>
      </b-form-group>
      <div class="col-12 col-md-6">
        <button class="btn btn-after-before-color" type="button" @click="send">
          ثبت تصاویر
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/parts/Front/components/Icon/ChevronLeft.vue";
import { BFormGroup, BFormFile } from "bootstrap-vue";
export default {
  name: "ClubAfterBefore",
  props: ["clubData", "clubColor"],
  components: {
    ChevronLeft,
    BFormGroup,
    BFormFile,
  },
  data() {
    return {
      url: "customer/customers_club/get_bought_products",
      products: [],
      selectedProduct: "",
      disabled: false,
      afterImage: "",
      beforeImage: "",
    };
  },
  mounted() {
    this.getBoughtProducts();
  },
  methods: {
    async getBoughtProducts() {
      await this.$axios
        .post(this.url)
        .then((res) => {
          this.products = res.data.data.products;
          this.$root.notify(res.data.message, "success");
        })
        .catch((e) => {
          this.$root.notify(e);
        });
    },
    send() {
      this.disabled = true;
      let formData = new FormData();
      formData.append("product_id", this.selectedProduct?.id);
      formData.append("after_image", this.afterImage);
      formData.append("before_image", this.beforeImage);
      this.$axios
        .post("customer/customers_club/set_before_after_images", formData)
        .then((res) => {
          this.$root.notify(res, "success");
          this.selectedProduct = "";
          this.beforeImage = "";
          this.afterImage = "";
        })
        .catch((err) => {
          this.$root.notify(err);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
<style scoped>
.club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
#clubAfterBefore .inner-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
#clubAfterBefore .inner-container label {
  background: v-bind(clubColor);
  color: #ffffff;
}
#clubAfterBefore .btn-after-before-color {
  background: v-bind(clubColor);
}
</style>
