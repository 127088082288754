<template>
  <div class="color-choose-img" :class="{'product-not-loaded': !product}">
    <div v-if="product" class="d-flex">
      <button :disabled="!product.isColorAvailable(color)" :selected="product.isColorSelected(color)"
              v-for="color in product.getColors()" :key="color.id"
              @click="selectColor(color)" class="color-btn">
        <canvas v-show="!product.isColorAvailable(color)" class="canvas"></canvas>
        <div class="product-color-choose-img" :style="'background: ' + color.code"></div>
        <div
            class="product-color-choose-bottom py-1 bg-color-theme text-color-666 weight-bold">
          <div class="color-text">
            <span v-if="product.isColorSelected(color)" class="fa fa-check icon fontsize11"></span>{{ color.name }}
          </div>
        </div>
      </button>
    </div>
    <div v-else>
      <button class="  overflow-hidden mb-2 color-btn"></button>
      <button class="  overflow-hidden mb-2 color-btn"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductColors",
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value
    }
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      if (this.product) {
        this.selectDefaultColor()
      }
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    if (this.product) {
      this.selectDefaultColor()
    }

    document.getElementsByClassName("canvas").forEach(c => {
      let bounding = c.getBoundingClientRect();
      let width = bounding.width;
      let height = bounding.height
      c.height = height;
      c.width = width
      let ctx = c.getContext("2d");
      ctx.strokeStyle="red";
      ctx.moveTo(0,height);
      ctx.lineTo(width,0);
      ctx.stroke();
      ctx.moveTo(0,0);
      ctx.lineTo(width,height);
      ctx.stroke();
    })
  },
  methods: {
    selectColor(color) {
      this.product.selectColor(color);
      this.$store.commit("front/setSelectedVariety",this.product.getSelectedVariety())
    },
    selectDefaultColor() {
      // انتخاب اولین رنگ
      let colors = this.product.getColors();
      for (let [index, color] of Object.entries(colors)) {
        if (this.product.isColorAvailable(color)) {
          this.selectColor(color)
          break;
        }
      }
    }
  }
}
</script>

<style scoped>
.color-btn {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 7px -2px var(--color-theme3);
}

.product-color-choose-img {
  height: 100px;
  width: 100%;
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
}

.product-color-choose-img img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.product-color-choose-bottom {
  background-color: #dedede;
  text-align: center;
  font-size: 14px;
  height: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.2s all;
}

.color-btn:not(selected=selected):hover .product-color-choose-bottom {
  background-color: var(--color-theme);
  color: white !important;
}

/* وقتی انتخاب شد چه شکلی بشه */
.color-btn[selected=selected] .product-color-choose-bottom {
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
  /*color: white!important;*/
  height: 29px;

}

.color-btn[selected=selected] .product-color-choose-img {
  height: 100px;
}

.color-btn[selected=selected] {
  /*box-shadow: 0 0 5px 1px #923131;*/
}

.color-btn:hover {
  box-shadow: 0 0 7px -1px var(--color-theme3);
}

.color-btn {
  width: 80px;
  height: 58px;
  text-align: center;
  margin-left: 10px;
  /*box-sizing: border-box !important;*/
  transition: .3s;
}

.icon {
  margin-left: 4px;
  transform: translateY(2px);
}

.color-btn:disabled {
  opacity: 0.6;
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.color-choose-img>div {
  min-width: 300px;
}

@media (max-width:768px) {
  .color-choose-img > div {
    min-width: unset;
  }

  .color-choose-img {
    max-width: 60vw
  }
}


@media (max-width:500px) {
  .color-choose-img {
    max-width: 88vw
  }
}

.product-not-loaded.color-choose-img {
  max-width: 450px;
}

.color-choose-img {
  scrollbar-color: var(--color-theme) var(--color-theme2);
  scrollbsr-width: thin;
}

.color-choose-img button {
  flex-shrink: 0;
}
</style>
