<template>
  <div>
    <main class="main product-details-main">
      <PagesPath path-title1="لیست محصولات" :pathTo1="$route.path"/>
      <!-- <div class="bg-white main-product-list"> -->
      <div class="product-list-main-grid container mt-4">
        <div class="welog-list-side d-lg-block d-none">
          <section class="products-list-filter  pt-3 pb-3 pr-0 pl-0  mb-3 bg-color-theme">
            <!-- <div class="d-flex justify-content-center border-bottom pb-2">
              <ZFilterSvg />
              <h6 class="mr-2 fontsize-medium weight-bold text-color-444">فیلتر جستجو</h6>
            </div> -->
           <div class="d-flex justify-content-between align-items-center pr-2 pl-2">
            <h6 class="mr-2 fontsize-medium weight-bold text-color-444 mt-3 text-white">دسته بندی ها</h6>
            <span>
              <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 4.52084H1.75C1.51083 4.52084 1.3125 4.3225 1.3125 4.08334C1.3125 3.84417 1.51083 3.64584 1.75 3.64584H12.25C12.4892 3.64584 12.6875 3.84417 12.6875 4.08334C12.6875 4.3225 12.4892 4.52084 12.25 4.52084Z" fill="white"/>
                <path d="M12.25 7.4375H1.75C1.51083 7.4375 1.3125 7.23917 1.3125 7C1.3125 6.76083 1.51083 6.5625 1.75 6.5625H12.25C12.4892 6.5625 12.6875 6.76083 12.6875 7C12.6875 7.23917 12.4892 7.4375 12.25 7.4375Z" fill="white"/>
                <path d="M12.25 10.3542H1.75C1.51083 10.3542 1.3125 10.1558 1.3125 9.91666C1.3125 9.6775 1.51083 9.47916 1.75 9.47916H12.25C12.4892 9.47916 12.6875 9.6775 12.6875 9.91666C12.6875 10.1558 12.4892 10.3542 12.25 10.3542Z" fill="white"/>
                </svg>
                
            </span>
           </div>
            <Categories :categories="categories" :categoryId="categoryId" :query="niceQuery"/>
          </section>

          <ProductsFilter :loading="loading" v-model="query"/>
        </div>
        <div class="weblog-list-main">
          <section class="filter-buttons d-lg-none">
            <button class="d-flex justify-content-center border-r-15 bg-color-theme w-100 text-color-white py-3 mb-2"
                    data-toggle="modal" data-target="#productFilterModal">
              <svg class="" width="16" height="16" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                      fill="#fff"/>
              </svg>
              <span class="mr-2 weight-bold fontsize15 text-color-white">فیلتر جستجو</span>
            </button>
            <!-- <button class="d-flex justify-content-center bg-color-ea py-3 px-sm-3 px-2   mb-2 w-49"
                    data-toggle="modal" data-target="#productOrderModal">
              <svg class="" width="16" height="16" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1172 17.9866H2.88306" stroke="#444" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.1179 17.9866C21.1179 19.5772 19.8285 20.8666 18.2379 20.8666C16.6473 20.8666 15.3579 19.5772 15.3579 17.9866C15.3579 16.3948 16.6473 15.1066 18.2379 15.1066C19.8285 15.1066 21.1179 16.3948 21.1179 17.9866Z"
                      stroke="#444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.8827 6.26212H21.118" stroke="#444" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.8826 6.26211C2.8826 7.85388 4.17201 9.14211 5.7626 9.14211C7.35319 9.14211 8.6426 7.85388 8.6426 6.26211C8.6426 4.67152 7.35319 3.38211 5.7626 3.38211C4.17201 3.38211 2.8826 4.67152 2.8826 6.26211Z"
                      stroke="#444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="mr-2 fontsize15 weight-bold text-color-444">ترتیب نمایش</span>
            </button> -->
          </section>
          <!-- <section @click="openAllCategory" v-if="mediaQueries.mobileSize && $route.params.slug" class="mobile-category">
            <h2>{{ $route.params.slug.replaceAll('-', ' ') }}</h2>
          </section> -->
          <ProductsFilterMobile :loading="loading" :categoriesMobile="categories" :categoryId="categoryId" :query="niceQuery" v-model="query"/>
          <ProductsSortMobile v-model="query"/>
          <section
              class="container product-view-order d-flex gap-15 justify-content-between align-items-baseline flex-wrap flex-md-nowrap py-3 mb-3">
            <div class="order-0">
              <template  v-if="!$route.params.slug">
                <div class="r d-flex align-items-center flex-nowrap">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter"
                       viewBox="0 0 16 16">
                    <path
                        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                        fill="#999"/>
                  </svg>
                  <span class="text-color-666 fontsize-medium weight-bold w-s-nowrap" v-if="!$route.params.slug">ترتیب نمایش:</span>
              </div> 
             </template>
              <h2 v-else class="weight-bold font-s-1-5 w-s-nowrap mr-2 mb-0">{{ $route.params.slug.replaceAll('-', ' ') }}</h2>
            </div>
            <div class="line-product-list d-none d-lg-block"></div>
            <ProductsSort v-model="query" :loading="loading" :filter="query"/>
          </section>
          <section v-if="products.data != ''" class="w-100">
            <div class="row justify-content-between w-100 mx-auto">
              <transition name="fade">
                <div v-if="loading" :style="'top: ' + loadingPosition" class="loading-container">
                  <Loading/>
                </div>
              </transition>
              <Product class="width-49 width-sm-49 width-md-32 mb-4" v-for="(product, index) in products.data" :low-opacity="loading" :key="index"
                       :product="product" :comparable="true" />
            </div>
          </section>
          <section v-else
                   class="products-list-grid recommended-content-item product-empty bg-white box-shaddow20 py-4 px-5 mt-sm-0 mt-3 ">
            <div class="position-relative w-100">
              <div v-if="loading" :style="'top: ' + loadingPosition" class="loading-container">
                <Loading/>
              </div>
              <div class="mr-2 fontsize14 weight-bold text-color-444 py-2">محصولی یافت نشد. </div>
              <router-link class="text-color-themeRed text-center d-block" to="/products">مشاهده همه</router-link>
            </div>
          </section>
          <section v-if="products.data != ''" class="list-pagination weblog-list-pagination text-center my-4 py-2 fontsize-medium">
            <pagination :router="true" :limit="mediaQueries.mobileSize ? 1 : 3" :data="products" @pagination-change-page="changePage" :disabled="disabled"
                        class="justify-content-center border-radius15">
              <span class="prev-nav" slot="prev-nav">
                <svg class="page-arrow mb-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                     viewBox="0 0 226 226" style=" fill:var(--color-f);">
                  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                     stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                     font-family="none" font-weight="none" font-size="none" text-anchor="none"
                     style="mix-blend-mode: normal">
                    <path d="M0,226v-226h226v226z" fill="none"></path>
                    <g fill="var(--color-f)">
                      <path
                          d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z">
                      </path>
                    </g>
                  </g>
                </svg>
                <span class="prev-nav-mb">
                  صفحه قبلی
                </span>
              </span>
              <span class="next-nav" slot="next-nav">
                <svg class="page-arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                     viewBox="0 0 226 226" style=" fill:var(--color-f);">
                  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                     stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                     font-family="none" font-weight="none" font-size="none" text-anchor="none"
                     style="mix-blend-mode: normal">
                    <path d="M0,226v-226h226v226z" fill="none"></path>
                    <g fill="var(--color-f)">
                      <path
                          d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z">
                      </path>
                    </g>
                  </g>
                </svg>
                <span class="next-nav-mb">
                  صفحه بعدی
                </span>
              </span>
            </pagination>
          </section>
        </div>
      </div>
    <!-- </div> -->
      <ScrollToTop></ScrollToTop>
      <!-- <FooterButtons></FooterButtons> -->
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
// import FooterButtons from "@/parts/Front/components/FooterButtons";
import Product from "@/parts/Front/components/product/Product";
import Categories from "@/parts/Front/components/categories/Categories";
import ProductsFilter from "@/parts/Front/components/filter/ProductsFilter";
import ProductsSort from "@/parts/Front/components/filter/ProductsSort";
import ProductsSortMobile from "@/parts/Front/components/filter/ProductsSortMobile";
import ProductsFilterMobile from "@/parts/Front/components/filter/ProductsFilterMobile";
import PagesPath from "@/parts/Front/components/PagesPath";
import Loading from '@/parts/Front/components/shared/Loading'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import ZFilterSvg from "@/parts/Front/components/shared/ZFilterSvg";

export default {
  name: "ProductList",
  components: {
    Loading,
    PagesPath,
    ScrollToTop,
    // FooterButtons,
    Product,
    Categories,
    ProductsFilter,
    ProductsSort,
    ProductsSortMobile,
    ProductsFilterMobile,
    ZFilterSvg
  },
  inject: ['mediaQueries'],
  data() {
    return {
      categoryId: '',
      query: {
        sort: '',
        title: '',
        flash_id: '',
        max_price: 0,
        min_price: 0,
        available: 0,
        category_id: '',
        selected_sizes: Array(20).fill(false),
        color_ids: [],
      },
      loading: false,
      // چقدر از بالا به پایین اسکرول شده
      offsetFromTop: 0,
      // آخرین کویری درخواستی رو نگه میداریم که اگر فرق نکرد درخواست نزنیم
      lastQueryRequested: {}
    }
  },
  watch: {
    '$route.query.title'() {
      this.loadFiltersFromQuery();
    },
    query: {
      handler: function (newVal) {
        this.loadItems()
      },
      deep: true
    }
  },
  created() {
    this.loadFiltersFromQuery();

    this.loadItems()
  },
  computed: {
    disabled() {
      return this.$store.getters['front/getProductsStatus']
    },
    categories() {
      if(this.$store.getters['front/getHomeData'] != null ){
         return this.$store.getters['front/getHomeData'].categories
      } else {
        return null
      }
    },
    products() {
      if (this.$store.getters['front/getProductsAll'] == null) {
        return {
          data: Array(6).fill(null)
        }
      } else {
        return this.$store.getters['front/getProductsAll']
      }
    },
    loadingPosition() {
      return 160 + Math.min(1000, this.offsetFromTop) + 'px'
    },
    niceQuery() {
      let selectedAttributeValueIds = this.getSelectedAttributeValueIds();
      let copyQuery = cloneDeep(this.query);
      copyQuery.sizes = selectedAttributeValueIds.join(',')
      delete copyQuery.selected_sizes
      if (copyQuery.color_ids == '') {
        delete copyQuery.color_ids
      }
      return copyQuery
    }
  },
  mounted() {
    this.offsetFromTop = document.scrollingElement.scrollTop
    document.removeEventListener('scroll', this.recalculateOffsetFromTop)
    document.addEventListener('scroll', this.recalculateOffsetFromTop)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.recalculateOffsetFromTop)
  },
  methods: {
    openAllCategory() {
      document.querySelector('.show-category-button').click()
    },
    loadFiltersFromQuery() {
      this.query.page = this.$route.query.page || 1
      this.categoryId = this.$route.params.category
      this.query.color_ids = this.$route.query.color_ids ? this.$route.query.color_ids.map(id => Number.parseInt(id))
          : this.query.color_ids
      this.query.sort = this.$route.query.sort ? this.$route.query.sort : this.query.sort
      this.query.title = this.$route.query.title ? this.$route.query.title : this.query.title
      this.query.flash_id = this.$route.query.flash_id ? +this.$route.query.flash_id : this.query.flash_id
      this.query.max_price = this.$route.query.max_price ? +this.$route.query.max_price : this.query.max_price
      this.query.min_price = this.$route.query.min_price ? +this.$route.query.min_price : this.query.min_price
      this.query.available = this.$route.query.available ? +this.$route.query.available : this.query.available
      this.query.category_id = this.$route.params.category ? +this.$route.params.category : this.query.category_id

      this.$store.commit('front/addHomeRetrievedEvent', (homeData) => {
        if (!this.$route.query.sizes) {
          return
        }
        let sizesFromQuery = this.$route.query.sizes.split(',')
        let sizeValues = this.$store.getters['front/getSizeValues'].values
        let newSelectedSizes = [...this.query.selected_sizes]
        sizeValues.forEach((sizeValue,index) => {
          if (sizesFromQuery.includes(sizeValue.id + "")) {
            newSelectedSizes[index] = true
          }
        })
        this.query.selected_sizes = newSelectedSizes
      })
    },
    recalculateOffsetFromTop() {
      this.offsetFromTop = document.scrollingElement.scrollTop
    },
    changePage() {
      this.loadItems()
    },
    async loadItems() {
      // جلوگیری از 2 بار درخواست زدن
      if (this.loading) {
        return
      }
      this.query.page = this.$route.query.page
      // اگر فرقی تو کویری نیست درخواست نزن
      if (isEqual(this.lastQueryRequested, this.query)) {
        return
      }

      this.loading = true;
      // برای فیلتر سایز اگر انتخاب شده بود
      let copyQuery = this.niceQuery;
      let selectedAttributeValueIds = this.getSelectedAttributeValueIds()
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.removeEmptyStrings(copyQuery),page: this.$route.query.page
        }
      })

      this.lastQueryRequested = cloneDeep(this.query)
      await this.$store.dispatch('front/getProductsDataFromServer', {
        query: this.query,
        selectedAttributeValueIds
      })
      this.loading = false
    },
    getSelectedAttributeValueIds() {
      if (!this.$store.getters['front/getSizeValues']) {
        if (!this.$route.query.sizes) {
          return [];
        }
        return this.$route.query.sizes.split(',')
      }
      let sizeValues = this.$store.getters['front/getSizeValues'].values

      let selectedSizeValues = []
      this.query.selected_sizes.forEach((SelectedSize, index) => {
        if (SelectedSize) {
          selectedSizeValues.push(sizeValues[index])
        }
      })
      let attribute_value_ids = [];
      selectedSizeValues.forEach(selectedSizeValue => {
        attribute_value_ids.push(selectedSizeValue.id)
      })
      return attribute_value_ids
    },
    removeEmptyStrings(query) {
      let newQuery = {};
      for (let [index, value] of Object.entries(query)) {
        if (value != '') {
          newQuery[index] = value
        }
      }

      return newQuery
    },
  }
}
</script>

<style scoped>
.next-nav-mb , .prev-nav-mb{
  display: none;
}
.main-product-list{
  /* margin-top: 200px; */
}
.product-list{
  /* position: relative; */
  /* top: -170px; */
}
.filter-btn-search {
  border-radius: 15px;
}
.line-product-list{
  width: 100%;
  height: 1px;
  background-color:#EAEAEA ;
}
.font-s-1-5{
  font-size: 1.5rem !important;
}
@media screen and (max-width: 1700px) {
  /* .line-product-list{
    width: 35%;
   
  } */
}
@media screen and (max-width: 1350px) {
  /* .line-product-list{
    width: 25%;
   
  } */
}
@media screen and (max-width: 992px){
.product-list{
  /* position:unset !important; */
}
.main-product-list{
  /* margin-top: 45px; */
}
/* .filter-buttons{
  position: relative;
  top: -25px;
} */
}
@media screen and (max-width: 768px) {
  /* .line-product-list{
    width: 45%;
   
  } */
  .title-top-products-list{
    justify-content: flex-start !important;
    gap: 16px;
  }
}
@media screen and (max-width: 500px) {
  /* .line-product-list{
    width: 30%;
   
  } */
}
@media screen and (max-width: 420px) {
  /* .line-product-list{
    width: 25%;
   
  } */
}
@media screen and (max-width: 370px) {
  /* .line-product-list{
    width: 25%;
   
  } */
}
.products-list-filter{
  background-color: var(--color-theme);
  border-radius: 15px;

}
.product-empty {
  grid-template-columns: 2fr !important
}

.loading-container {
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  background: var(--color-theme2);
  border-radius: 10px;
  padding: 10px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: 200ms all;
}

.main >>> .vue-treeselect__multi-value-item {
  color: white;
  background: var(--color-theme);
}
.main >>>  .vue-treeselect__value-remove {
  color: white;
}

h2 {
  font-size: 1.75rem;
}

.mobile-category {
  color:var(--color-444);
  background-color:var(--color-ea);
  font-size:15px;
  transition:.3s;
  display:flex!important;
  justify-content:center!important;
  margin-bottom:.5rem!important;
  padding: 0.7rem !important;
}
.mobile-category h2 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.3rem;
}
</style>
<style>
.page-item a{
  border-radius: 50% !important;
}
.pagination-next-nav a ,.pagination-prev-nav a {
background-color: var(--color-brown) !important;
display: grid !important;
place-items: center;
}
.pagination-prev-nav a svg{
transform: translateX(2px);
}
@media screen and (max-width:468px) {
  .pagination-page-nav{
    display: none;
  }
  .next-nav-mb , .prev-nav-mb{
    display: block !important;
  }
  .pagination-prev-nav a ,.pagination-next-nav a{
    width: fit-content !important;
    border-radius: 20px !important;
   width: 151px !important;
   height: 40px !important;
   

  }
  .pagination-prev-nav a{
  
   background-color: #484848 !important;

  }
  .pagination-next-nav a{
    
   background-color: var(--color-brown) !important;

  }
  .pagination-prev-nav a svg ,.pagination-next-nav a svg{
    margin-bottom: 0 !important;
    background-color: #fff;
    border-radius: 50%;
    fill: #000 !important;
    width: 15px !important;
    height: 15px !important;
  }
  .pagination-prev-nav a svg g ,.pagination-next-nav a svg g{
   
    fill: #000 !important;
  }
  .next-nav , .prev-nav{
    display: flex !important;
    width: fit-content !important;
    color: #fff !important;
    align-items: center;
    font-size: 13px !important;
    line-height: 2.5;
    justify-content: flex-start !important;
    gap: 22px;
    width: 100% !important;
  }
  .next-nav {
    
    padding-left: 10px;
    flex-direction: row-reverse;
    
  }
  .prev-nav{
  
    padding-right: 10px;
   
  }
}
</style>
