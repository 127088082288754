<template>
  <div id="clubDashboard">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        داشبورد باشگاه
      </h3>
    </div>
    <div v-if="clubData" class="h-100 w-100 mt-4">
      <div class="club-data-container">
        <div class="club-data-card club-data-card-hover">
          <h5 class="text-color-grey fontsize-heavy">سطح</h5>
          <div class="d-flex align-items-center flex-column gap-3">
            <div class="image-holder">
              <img
                class="w-100 h-100"
                v-if="clubData.customers_club_level.image"
                :src="`https://api.benedito.ir/${clubData.customers_club_level.image}`"
                :alt="clubData.customers_club_level.level"
              />
            </div>
            <p class="text-color-grey fontsize-medium m-0">
              {{ clubData.customers_club_level.level }}
            </p>
          </div>
        </div>
        <div class="club-data-card">
          <h5 class="text-color-grey fontsize-heavy">تعداد بن</h5>
          <h4 class="text-color-grey fontsize-large">
            {{ clubData.customers_club_bon }}
          </h4>
        </div>
        <div class="club-data-card">
          <h5 class="text-color-grey fontsize-heavy">امتیاز کل</h5>
          <h4 class="text-color-grey fontsize-large">
            {{ clubData.customers_club_score | price }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/parts/Front/components/Icon/ChevronLeft.vue";
export default {
  name: "ClubDashboard",
  props: ["clubData", "clubColor"],
  components: {
    ChevronLeft,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
#clubDashboard .club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
#clubDashboard .club-data-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
#clubDashboard .club-data-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 30px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.07);
}

#clubDashboard .club-data-card-hover:hover .club-level-badge {
  transition: all 2s linear;
  transform: rotateY(360deg);
}

#clubDashboard .club-data-card .image-holder {
  width: 70px;
}
@media screen and (max-width: 992px) {
  #clubDashboard .club-data-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
</style>
