<template>
  <span
    v-if="
      product &&
        selectedVariety &&
        selectedVariety.quantity == 0 &&
        product.chargeable
    "
  >
    <a title="در صورت موجود شدن به من اطلاع بده">
      <svg
        v-if="isListen"
        @click="unlistenRequet()"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 512.000000 512.000000"
        class=""
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#FF0000"
          stroke="none"
        >
          <path
            d="M2235 5106 c-147 -37 -270 -153 -312 -295 -8 -30 -18 -97 -22 -148
l-6 -93 -110 -38 c-672 -233 -1153 -842 -1225 -1551 -6 -62 -10 -408 -10 -866
l0 -762 -78 -5 c-267 -15 -458 -283 -387 -543 43 -161 177 -283 345 -315 32
-6 315 -10 724 -10 l671 0 19 -61 c25 -80 99 -191 170 -256 70 -64 196 -128
291 -148 305 -64 617 109 717 398 l23 67 621 0 c406 0 641 4 680 11 137 25
249 110 311 237 34 68 38 85 41 168 5 114 -12 178 -70 264 -77 115 -214 190
-349 190 l-59 0 0 399 0 399 68 12 c360 62 655 334 754 695 18 67 22 106 22
240 -1 145 -3 168 -28 250 -120 395 -438 654 -847 690 -88 8 -235 -8 -326 -36
l-62 -20 -108 109 c-170 170 -309 268 -528 372 -118 56 -225 96 -335 123 l-55
13 -5 100 c-5 84 -11 110 -38 167 -89 192 -296 293 -497 243z m169 -190 c102
-31 165 -111 174 -223 l5 -66 -109 7 c-98 6 -310 -6 -356 -20 -16 -5 -18 2
-18 61 0 77 18 127 63 173 69 72 151 95 241 68z m308 -505 c270 -59 508 -172
713 -341 99 -82 191 -178 180 -188 -6 -5 -40 -32 -76 -61 -150 -118 -268 -301
-321 -496 -31 -115 -31 -355 0 -469 26 -93 95 -241 146 -311 139 -193 360
-335 589 -380 l87 -18 0 -464 0 -465 29 -29 c29 -28 32 -29 131 -29 134 0 179
-12 239 -66 127 -115 99 -319 -53 -390 l-51 -24 -1940 0 c-1857 0 -1942 1
-1980 19 -89 40 -139 121 -139 221 0 73 22 126 71 171 56 52 89 63 226 68 126
6 129 7 153 34 l24 28 0 777 c1 819 6 977 39 1135 103 492 429 915 876 1136
170 84 334 134 540 165 17 2 118 3 225 1 151 -3 217 -8 292 -24z m1594 -591
c216 -55 392 -196 490 -395 61 -125 78 -196 78 -335 0 -213 -66 -372 -219
-525 -99 -100 -188 -154 -325 -197 -108 -33 -302 -33 -410 0 -224 71 -389 214
-481 418 -45 99 -62 185 -62 309 1 215 67 369 223 525 77 78 111 104 180 138
47 23 119 51 160 62 104 26 261 27 366 0z m-1466 -3347 c0 -5 -14 -32 -31 -62
-87 -152 -269 -240 -438 -211 -132 22 -246 100 -310 211 -17 30 -31 57 -31 62
0 4 182 7 405 7 223 0 405 -3 405 -7z"
          />
          <path
            d="M1073 1332 c-28 -22 -45 -66 -37 -98 3 -13 20 -35 36 -49 l30 -25
529 0 529 0 29 28 c44 40 46 99 5 137 l-27 25 -536 0 c-492 0 -538 -2 -558
-18z"
          />
          <path
            d="M2419 1321 c-21 -22 -29 -39 -29 -66 0 -27 8 -44 29 -66 l29 -29 132
0 132 0 29 29 c21 22 29 39 29 66 0 27 -8 44 -29 66 l-29 29 -132 0 -132 0
-29 -29z"
          />
          <path
            d="M4085 3546 c-49 -21 -55 -49 -55 -239 l0 -172 -116 -115 c-156 -155
-173 -192 -115 -251 23 -23 38 -29 71 -29 41 0 47 5 182 138 77 75 146 149
154 164 17 33 20 437 4 469 -11 20 -60 49 -82 49 -7 -1 -27 -7 -43 -14z"
          />
        </g>
      </svg>

      <svg
        v-else
        @click="listenRequest()"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 512.000000 512.000000"
        class=""
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#444"
          stroke="none"
        >
          <path
            d="M2235 5106 c-147 -37 -270 -153 -312 -295 -8 -30 -18 -97 -22 -148
l-6 -93 -110 -38 c-672 -233 -1153 -842 -1225 -1551 -6 -62 -10 -408 -10 -866
l0 -762 -78 -5 c-267 -15 -458 -283 -387 -543 43 -161 177 -283 345 -315 32
-6 315 -10 724 -10 l671 0 19 -61 c25 -80 99 -191 170 -256 70 -64 196 -128
291 -148 305 -64 617 109 717 398 l23 67 621 0 c406 0 641 4 680 11 137 25
249 110 311 237 34 68 38 85 41 168 5 114 -12 178 -70 264 -77 115 -214 190
-349 190 l-59 0 0 399 0 399 68 12 c360 62 655 334 754 695 18 67 22 106 22
240 -1 145 -3 168 -28 250 -120 395 -438 654 -847 690 -88 8 -235 -8 -326 -36
l-62 -20 -108 109 c-170 170 -309 268 -528 372 -118 56 -225 96 -335 123 l-55
13 -5 100 c-5 84 -11 110 -38 167 -89 192 -296 293 -497 243z m169 -190 c102
-31 165 -111 174 -223 l5 -66 -109 7 c-98 6 -310 -6 -356 -20 -16 -5 -18 2
-18 61 0 77 18 127 63 173 69 72 151 95 241 68z m308 -505 c270 -59 508 -172
713 -341 99 -82 191 -178 180 -188 -6 -5 -40 -32 -76 -61 -150 -118 -268 -301
-321 -496 -31 -115 -31 -355 0 -469 26 -93 95 -241 146 -311 139 -193 360
-335 589 -380 l87 -18 0 -464 0 -465 29 -29 c29 -28 32 -29 131 -29 134 0 179
-12 239 -66 127 -115 99 -319 -53 -390 l-51 -24 -1940 0 c-1857 0 -1942 1
-1980 19 -89 40 -139 121 -139 221 0 73 22 126 71 171 56 52 89 63 226 68 126
6 129 7 153 34 l24 28 0 777 c1 819 6 977 39 1135 103 492 429 915 876 1136
170 84 334 134 540 165 17 2 118 3 225 1 151 -3 217 -8 292 -24z m1594 -591
c216 -55 392 -196 490 -395 61 -125 78 -196 78 -335 0 -213 -66 -372 -219
-525 -99 -100 -188 -154 -325 -197 -108 -33 -302 -33 -410 0 -224 71 -389 214
-481 418 -45 99 -62 185 -62 309 1 215 67 369 223 525 77 78 111 104 180 138
47 23 119 51 160 62 104 26 261 27 366 0z m-1466 -3347 c0 -5 -14 -32 -31 -62
-87 -152 -269 -240 -438 -211 -132 22 -246 100 -310 211 -17 30 -31 57 -31 62
0 4 182 7 405 7 223 0 405 -3 405 -7z"
          />
          <path
            d="M1073 1332 c-28 -22 -45 -66 -37 -98 3 -13 20 -35 36 -49 l30 -25
529 0 529 0 29 28 c44 40 46 99 5 137 l-27 25 -536 0 c-492 0 -538 -2 -558
-18z"
          />
          <path
            d="M2419 1321 c-21 -22 -29 -39 -29 -66 0 -27 8 -44 29 -66 l29 -29 132
0 132 0 29 29 c21 22 29 39 29 66 0 27 -8 44 -29 66 l-29 29 -132 0 -132 0
-29 -29z"
          />
          <path
            d="M4085 3546 c-49 -21 -55 -49 -55 -239 l0 -172 -116 -115 c-156 -155
-173 -192 -115 -251 23 -23 38 -29 71 -29 41 0 47 5 182 138 77 75 146 149
154 164 17 33 20 437 4 469 -11 20 -60 49 -82 49 -7 -1 -27 -7 -43 -14z"
          />
        </g>
      </svg>
    </a>
  </span>
</template>

<script>
export default {
  inject: ["mediaQueries"],
  props: {
    product: Object,
    selectedVariety: Object,
    value: Object || null,
  },
  data() {
    return {
      listenDisabled: false,
      disabled: false,
      product: this.value,
      isListen: false,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      this.selectFirstIfNotSelected();
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    this.$store.commit("front/addHomeRetrievedEvent", () => {
      if (this.listenChargeList && this.listenChargeList != "") {
        this.isListen = !!this.listenChargeList.filter(
          (item) => item.product_id == this.product.id
        ).length;
      }
    });
  },
  computed: {
    // hasInListens() {
    //   let homeData = this.$store.getters["front/getHomeData"];
    //   if (!homeData) {
    //     return false;
    //   }
    //   let user = homeData.user.user;
    //   if (!user) {
    //     return false;
    //   }
    //   return user.listen_charges.find((listenCharge) => {
    //     return listenCharge.product_id == this.product.id;
    //   });
    // },
    listenChargeList() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user != null
      ) {
        return this.$store.getters["front/getHomeData"].user.user
          .listen_charges;
      } else {
        return null;
      }
    },
  },
  methods: {
    selectFirstIfNotSelected() {
      this.product.selectFirstIfNotSelected();
    },
    // آیا این کاربر قبلا برای مطلع شدن از موجود شدن این محصول اقدام کرده
    async listenRequest() {
      let homeData = this.$store.getters["front/getHomeData"];
      if (!homeData.user.user) {
        window.$("#loginModal").modal("show");
        return;
      }
      try {
        this.listenDisabled = true;
        const response = await this.$axios.post(
          `customer/products/${this.product.id}/listen`
        );
        homeData.user.user.listen_charges.push(response.data.data.listenCharge);
        this.$root.notify(response, "success");
        this.isListen = true;
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
    async unlistenRequet() {
      let homeData = this.$store.getters["front/getHomeData"];
      try {
        this.listenDisabled = true;
        const response = await this.$axios.delete(
          `customer/products/${this.product.id}/unlisten`
        );

        homeData.user.user.listen_charges = homeData.user.user.listen_charges.filter(
          (listenCharge) => {
            return listenCharge.product_id != this.product.id;
          }
        );
        this.$root.notify(response, "success");
        this.isListen = false;
      } catch (error) {
        this.$root.notify(error);
      }
      this.listenDisabled = false;
    },
  },
};
</script>

<style></style>
