<template>
  <div id="clubExchange">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        گزارش درخواست تبدیل بن
      </h3>
    </div>
    <div v-if="clubData && bonRequests" class="h-100 w-100 mt-4">
      <b-table
        :style="{ opacity: disabled ? '0.6' : 1 }"
        bordered
        responsive
        striped
        small
        v-if="bonRequests && bonRequests.length"
        :fields="fieldsBonRequests"
        :items="bonRequests"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(requested_bon)="data">
          {{ data.item.requested_bon }}
        </template>
        <template v-slot:cell(converted_gift_value)="data">
          {{ data.item.converted_gift_value | price }}
        </template>
        <template v-slot:cell(status)="data">
          <button
            v-if="data.item.status == 'approved'"
            class="btn btn-success btn-sm"
          >
            تایید شده
          </button>
          <button
            v-if="data.item.status == 'new'"
            class="btn btn-warning btn-sm"
          >
            جدید
          </button>
          <button
            v-if="data.item.status == 'rejected'"
            class="btn btn-danger btn-sm"
          >
            رد شده
          </button>
        </template>
        <template v-slot:cell(request_date)="data">
          {{ data.item.request_date | persianDate }}
        </template>
        <template v-slot:cell(action_date)="data">
          {{ data.item.action_date | persianDate }}
        </template>
        <template v-slot:cell(description)="data">
          {{ data.item.description }}
        </template>
      </b-table>
      <div v-else class="alert alert-info" role="alert">
        موردی یافت نشد!
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/parts/Front/components/Icon/ChevronLeft.vue";
import { BTable } from "bootstrap-vue";

export default {
  name: "BonRequest",
  props: ["clubData", "clubColor"],
  components: {
    ChevronLeft,
    BTable,
  },
  watch: {
    clubData(newValue) {
      this.value = newValue;
    },
  },
  data() {
    return {
      disabled: false,
      value: this.clubData,
      fieldsBonRequests: [
        {
          label: "ردیف",
          key: "index",
        },
        {
          label: "تعداد بن",
          key: "requested_bon",
        },
        {
          label: "ارزش تبدیل شده",
          key: "converted_gift_value",
        },
        {
          label: "وضعیت",
          key: "status",
        },
        {
          label: "تاریخ ثبت",
          key: "request_date",
        },
        {
          label: "تاریخ تایید",
          key: "action_date",
        },
        {
          label: "توضیحات",
          key: "description",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("front/getClubBonRequestsFromServer");
  },
  computed: {
    bonRequests() {
      return this.$store.getters["front/getClubBonRequests"];
    },
  },
};
</script>
<style scoped>
#clubExchange .club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
</style>
<style>
#clubExchange table tbody tr td,
#clubExchange table thead tr th {
  white-space: nowrap !important;
}
</style>
