<template>
  <section
    id="categorySection"
    v-if="categories && categories.length"
    class="products-categories my-5"
  >
    <div class="swiper-container">
      <div class="swiper-wrapper justify-content-between">
        <article
          v-for="(category, index) in categories"
          :key="category.id"
          class="swiper-slide"
        >
          <router-link
            :to="{
              name: 'product.category',
              params: { category: category.id, slug: category.slug },
            }"
            class="d-flex align-items-center justify-content-center flex-column w-100 h-100"
          >
            <img
              v-if="category.icon"
              :src="category.icon.url"
              :alt="category.title"
              width="90"
              height="90"
            />
            <h6 class="text-color-444 mb-0 mt-4 fontsize14 text-center">
              {{ category.title }}
            </h6>
          </router-link>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { BSkeletonImg } from "bootstrap-vue";
import Swiper from "swiper/swiper-bundle";
export default {
  name: "ProductCategories",
  components: {
    BSkeletonImg,
  },
  mounted() {
    this.initSpecialCategories();
  },
  watch: {
    categories(newValue) {
      if (newValue) {
        this.initSpecialCategories(true);
      }
    },
  },
  computed: {
    // دریافت دسته بندی ها از پاسخ دریافت شده در صفحه اصلی
    categories() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"].special_categories;
      }
    },
  },
  methods: {
    initSpecialCategories(kill = false) {
      this.initSwiper("mySpecialCategories", kill, () => {
        new Swiper("#categorySection .swiper-container", {
          slidesPerView: "auto",
          spaceBetween: 10,
          loop: true,
          centeredSlides: false,
          centeredSlidesBounds: false,
          speed: 1000,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
        });
      });
    },
  },
};
</script>

<style scoped>
#categorySection article {
  width: 120px;
}
/********* products-categories ********/
/* .cat-item {
  display: flex;
  flex-direction: column;
}
.cat-item h6 {
  font-size: small;
}
.products-categories article > a:hover svg > path {
  fill: white !important;
  stroke: white !important;
}

.products-categories .products-categories-svg {
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  transition: 0.3s;
}
@media screen and (max-width: 769px) {
}
.products-categories-svg img {
  width: 100%;
  height: 100%;
}

.products-categories a {
  transition: 0.3s;
  display: flex;
  justify-content: center;
} 
 .products-categories a {
  --transition-duration: 500ms;
  --transition-easing: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--foreground-color);
  font-size: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent !important;

  box-shadow: 0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-duration) var(--transition-easing);
  overflow: hidden;
  width: 100%;
} 
 .products-categories a:before {
  content: "";
  left: -125%;
}
.products-categories article:not(:last-child) {
  border-left: unset !important;
}
.products-categories a:hover::before {
  background: rgba(255, 255, 255, 0.4);
  width: 60%;
  height: 100%;
  top: 0%;
  transform: skew(45deg);
  position: absolute;
  transition: left var(--transition-duration) var(--transition-easing);
}

.products-categories a:hover {
  backdrop-filter: blur(2px);
}
.products-categories a:hover::before {
  left: 150%;
}

.products-categories a svg > path,
.products-categories a h6 {
  transition: 0.3s;
}

.products-categories article {
  position: relative;
  overflow: hidden;
}
.products-categories article:not(:last-child) {
  border-left: 1px solid var(--color-eai);
}

.products-categories article > a:hover {
  background-color: var(--color-theme);
}

.products-categories article > a:hover h6 {
  color: white !important;
}

.products-categories article > a:hover svg > path {
  stroke: white;
} 

.hiddenItem {
   عدم نمایش دسته بندی ها اگر  بیشتر از هفتا بودند 
   display: none; 
}
 درست کردن قالب در سایز401  
 @media (max-width: 500px) {
  .mobileSizeCategory {
    display: none;
  }
} 
 درست کردن قالب در سایز 766 
 @media (min-width: 1199px) {
  #categorySection {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
  }
}
@media (max-width: 767px) {
  #categorySection {
    display: flex;
    overflow: scroll !important;
    max-width: 87%;
    flex-wrap: nowrap !important;
  }
  .products-categories > article {
    min-width: 115px !important;
  }

  .products-categories-svg > svg {
    width: 17px !important;
    height: 17px !important;
  } 
 .products-categories h6:first-child {
      font-size: 11px !important;
  } 
 .products-categories a {
    height: 80px;
    width: 100%;
  }
  .products-categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    bottom: -40px;
  }
  .products-categories h6:nth-child(2) {
    font-size: 9px;
  }
} 

 @media (max-width: 575px) {
  .products-categories article div > h6:nth-child(2) {
    display: none;
  }
  .products-categories {
    bottom: -30px;
  }
  .products-categories a {
    height: 60px;
  }
} 

 @media screen and (max-width: 360px) {
  #categorySection {
    max-width: 83%;
  }
} 

 #categorySection > article {
  flex: 1 1;
} 

 .products-categories > article {
  min-width: 133px;
} */
</style>
