<template>
  <button
    type="button"
    class="cart-btn bg-none d-flex flex-column align-items-center"
    style="gap: 8px"
    @click="cartListsOpen()"
  >
    <!-- <svg
      width="20"
      height="20"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3062 14.2188H4.68746C3.61246 14.2188 2.80621 13.9313 2.30621 13.3625C1.80621 12.7938 1.61246 11.9688 1.74371 10.9L2.30621 6.2125C2.46871 4.83125 2.81871 3.59375 5.25621 3.59375H9.75621C12.1875 3.59375 12.5375 4.83125 12.7062 6.2125L13.2687 10.9C13.3937 11.9688 13.2062 12.8 12.7062 13.3625C12.1875 13.9313 11.3875 14.2188 10.3062 14.2188ZM5.24996 4.53125C3.44996 4.53125 3.36246 5.24374 3.23121 6.31874L2.66871 11.0062C2.57496 11.8 2.68746 12.3813 3.00621 12.7375C3.32496 13.0938 3.88746 13.275 4.68746 13.275H10.3062C11.1062 13.275 11.6687 13.0938 11.9875 12.7375C12.3062 12.3813 12.4187 11.8 12.325 11.0062L11.7625 6.31874C11.6312 5.23749 11.55 4.53125 9.74371 4.53125H5.24996Z"
        fill="#F83D84"
      />
      <path
        d="M10 5.46875C9.74375 5.46875 9.53125 5.25625 9.53125 5V2.8125C9.53125 2.1375 9.1125 1.71875 8.4375 1.71875H6.5625C5.8875 1.71875 5.46875 2.1375 5.46875 2.8125V5C5.46875 5.25625 5.25625 5.46875 5 5.46875C4.74375 5.46875 4.53125 5.25625 4.53125 5V2.8125C4.53125 1.61875 5.36875 0.78125 6.5625 0.78125H8.4375C9.63125 0.78125 10.4688 1.61875 10.4688 2.8125V5C10.4688 5.25625 10.2562 5.46875 10 5.46875Z"
        fill="#F83D84"
      />
      <path
        d="M12.7563 11.1125H5C4.74375 11.1125 4.53125 10.9 4.53125 10.6438C4.53125 10.3875 4.74375 10.175 5 10.175H12.7563C13.0125 10.175 13.225 10.3875 13.225 10.6438C13.225 10.9 13.0125 11.1125 12.7563 11.1125Z"
        fill="#F83D84"
      />
    </svg> -->

    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="22"
      height="22"
      viewBox="0 0 2000 2000"
      fill="#F83D84"
    >
      <path
        fill="#F83D84"
        d="M1937.5,104c-0.2,0-0.3,0-0.5,0l0,0h-237l0,0c-28.7,0.2-52.8,19.8-59.9,46.3L1582.7,300H1313H999H688l0,0  l0,0c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0l0,0H62l0,0c-28.2,0.2-52,19.1-59.5,45c-3.6,11.8-3.7,24.8,0.4,37.4c0.1,0.2,0.1,0.3,0.2,0.5  l0,0l262.6,802.9c7.8,25.5,31.3,44,59.3,44.2l0,0h811.4h208.7h21.2c4.3,0.4,8.5,0.4,12.6,0c70.4,8.4,125,68.3,125,141  c0,75.1-58.2,136.5-132,141.6v0.4h-9h-226H423l0,0c-0.3,0-0.6,0-0.8,0c-1.1,0-2.1,0-3.2,0c-105.5,0-191,85.5-191,191  s85.5,191,191,191s191-85.5,191-191c0-23.2-4.1-45.4-11.7-66h486.4c-7.6,20.6-11.7,42.8-11.7,66c0,105.5,85.5,191,191,191  s191-85.5,191-191c0-27.8-6-54.3-16.7-78.1C1548,1593.1,1628,1491.4,1628,1371c0-114.8-72.8-212.7-174.7-249.9l231.8-738.5l0,0  c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.3-0.9,0.4-1.3l58.2-151.7H1937l0,0c0.2,0,0.3,0,0.5,0c34.5,0,62.5-28,62.5-62.5  C2000,132,1972,104,1937.5,104z M419,1770c-36.5,0-66-29.5-66-66s29.5-66,66-66s66,29.5,66,66S455.5,1770,419,1770z M1264,1770  c-36.5,0-66-29.5-66-66s29.5-66,66-66s66,29.5,66,66S1300.5,1770,1264,1770z M1327.3,1105H370.8L148.4,425H687l0,0  c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0l0,0l0,0h311h314h227.7L1327.3,1105z"
      />
    </svg>

    <span
      v-if="quantity != 0"
      class="rounded-circle bg-color-theme text-white p-1 fontsize12 weight-bold"
    >
      {{ quantity }}</span
    >
    <p class="text-color-theme font-s-12 m-0"> سبد خرید </p>
  </button>
</template>
<script>
import { cartListsOpen } from "@/libraries/functions";

export default {
  computed: {
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    quantity() {
      if (this.cart) {
        return this.cart.cartItems.reduce((sum, item) => {
          sum += item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
  },
};
</script>
<style scoped>
i {
  color: var(--color-theme);
}
</style>
