<template>
  <div
  ref="healthCare"
    class="healthCare"
    id="healthCare"
    tabindex="-1"
    @click="checkCloseModal"
    aria-hidden="true"
  >
    <div id="healthCareContet" class="healthcare-content modal-dialog ">
      <div class="position-relative modal-content">
        <button
          id="btnCloseLoginModal"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          class=" position-absolute close-x"
          @click="closeHealthModal()"
        >
          ×
        </button>

        <template v-if="firstModal">
          <div class="modal-right-side py-4">
            <AtlasLogo />
            <p class=" mt-4 px-5 text-center modal-desc">
              {{ homeData?homeData.settings.site.firstModalContent:modalDescription }}
            </p>
            <div class="firstModal mt-4">
              <button
                class="skin-btn "
                type="button"
                @click.prevent="chooseSkin()"
              >
                پوست
              </button>
              <button
                class="hair-btn "
                type="button"
                @click.prevent="chooseHair()"
              >
                مو
              </button>
            </div>
          </div>
          <div class="d-md-block d-none modal-left-side">
            <img :src="modalImg" />
          </div>
        </template>
        <template v-if="hairModal">
          <div
            class="d-flex flex-column align-items-center justify-content-between hair-section"
          >
            <AtlasLogo />
            <p class=" w-75 mx-auto text-center mt-5 modal-desc">
              {{  homeData?homeData.settings.site.hairModalContent:modalDescription }}
            </p>
            <div class="first-modal-btn mt-5">
              <button
                class="hair-Oily-btn"
                type="button"
                @click.prevent="goToResult('21', 'موهای-چرب')"
              >
                 یک روز 
              </button>
                    <button
                class="hair-dry-btn "
                type="button"
                @click.prevent="goToResult('23', 'موهای-معمولی')"
              >
                دو روز 
              </button>
              <button
                class="hair-dry-btn"
                type="button"
                @click.prevent="goToResult('22', 'موهای-خشک')"
              >
                 دو روز به بالا  
              </button>
              
        
         
            </div>
          </div>
        </template>
        <template v-if="skinModal">
          <div
            class="d-flex flex-column align-items-center justify-content-between skin-section"
          >
            <AtlasLogo />
            <p class=" w-75 mx-auto mt-5 modal-desc">{{  homeData?homeData.settings.site.skinModalContent:modalDescription  }}</p>
            <div class="first-modal-btn1 mx-auto mt-5 ">
              
              <button
                class="skin-Oily-btn "
                type="button"
                @click.prevent="goToResult('18', 'پوست-های-خیلی-چرب')"
              >
                 بین 15 دقیقه تا 2 ساعت 
              </button>
                 <button
                class="skin-Oily-btn "
                type="button"
                @click.prevent="goToResult('18', 'پوست-های-چرب','innerSkin')"
              >
                  بین 2 ساعت تا 4 ساعت 
              </button>
              <button
                class="skin-dry-btn "
                type="button"
                @click.prevent="goToResult('20', 'پوست-های-معمولی')"
              >
                بین 4 ساعت و یک روز  
              </button>
           
              <button
                class="skin-dry-btn "
                type="button"
                @click.prevent="goToResult('19', 'پوست-های-خشک')"
              >
                یک روز به بالا  
              </button>
            </div>
          </div>
        </template>
        
        <template v-if="innerSkin">
          <div
            class="d-flex flex-column align-items-center justify-content-between skin-section"
          >
            <AtlasLogo />
            <p class=" w-75 mx-auto mt-5 modal-desc">{{  homeData?homeData.settings.site.skinInnerContetn:modalDescription  }}</p>
            <div class="first-modal-btn1 mx-auto mt-5 ">
              
              <button
                class="skin-Oily-btn "
                type="button"
                @click.prevent="goToResult('18', 'پوست-های-چرب')"
              >
                 تمام صورت  
              </button>
                 <button
                class="skin-Oily-btn "
                type="button"
                @click.prevent="goToResult('27', 'پوست-های-مختلط')"
              >
                  پیشانی چرب و گونه ها خشک  
              </button>
          
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
import closeModalMixin from "@/libraries/mixins/closeModalMixin";
import modalImage from "@/assets/images/healthCare.png";
import $ from "jquery";
export default {
  inject: ["mediaQueries"],
  name: "RespLoginModal",
  mixins: [closeModalMixin],
  components: { AtlasLogo, modalImage },
  data() {
    return {
      lastSection:"firstModal",
      firstModal: true,
      hairModal: false,
      skinModal: false,
      innerSkin:false,
      modals: ["healthCare"],
      textModalHeader: "ورود به وبسایت",
      modalHeight: "0",
      changeText: "ورود به وبسایت",
      modalImg: modalImage,
      modalDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    };
  },
  methods: {
    checkCloseModal(e){
      if(e.target.id=='healthCare'){
        this.closeHealthModal();
      }
    },
    closeHealthModal(changeRoute=true){
      this.$emit('closeHealthModal');
      if(changeRoute){
        this.$router.push(this.$route.path)
      }
    },
    closeModal() {
      this.$refs.healthCare.closeModal();
      // برگرداندن به وضعیت اولیه با بستن مودال
    },
    chooseHair() {
      this.firstModal = false;
      setTimeout(() => {
        this.hairModal = true;
        this.lastSection="hairModal"
      }, 500);
    },
    chooseSkin() {
      this.firstModal = false;
      setTimeout(() => {
        this.skinModal = true;
        this.lastSection="skinModal"

      }, 500);
    },
    closeModal() {
      setTimeout(() => {
        this.firstModal = true;
        this.lastSection="firstModal"

        this.hairModal = false;
        this.skinModal = false;
      }, 1000);
    },
    goToResult(id, slug,innerContent=false) {
      if(innerContent){
        this[this.lastSection]=false;
        this[innerContent]=true;
      }else{

        this.closeHealthModal(false);
      this.$router.push(`/products/category/${id}/${slug}?category_id=${id}`);
      }
    
    },
  },
  watch: {
    changeText(val) {
      this.textModalHeader = val;
    },
  },
  mounted() {
    let HealthCare = this;
    $("#healthCare").on("hidden.bs.modal", function() {
      HealthCare.closeModal();
    });
    if (this.$route.fullPath.includes("#healthCare")) {
      // this.$router.replace(this.$route.fullPath.replace("#cart-modal", ""));
      cartListsOpen();
    }
  },
  computed:{
    homeData(){
      return this.$store.getters["front/getHomeData"]
    }
  }
};
</script>
<style>
.firstModal{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  gap: 15px;
}</style>
<style scoped>
#mobile {
  width: 95%;
  background: white;
  height: 30px;
  border-radius: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#modal-content {
  transition: all 0.3s;
  height: 300px;
}

.modal-content {
  min-height: 450px;
}
.modal-right-side {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}
.close-x {
  z-index: 1100;
  background: transparent;
  font-size: 30px;
  right: 10px;
}
#healthCare .modal-content {
  background-color: #d9d9d9;
  flex-direction: row;
}
.modal-desc {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.hair-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #f15500;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
}
.skin-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #a03995;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
}
/* نمایش تمام صفحه مودال در حالت موبایلی
 */
.modal-content {
  min-height: auto;
}
.modal-left-side {
  flex: 1;
}
.modal-left-side img {
  /* object-fit: fill; */
  width: 100%;
  height: 100%;
}
.modal-dialog {
  max-width: 90% !important;
  width: 60%;
  /* margin: 7rem auto; */
  display: flex;
  background-color: #d9d9d9;
}
.first-modal-btn {
     display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.first-modal-btn1 {
     display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.hair-section {
  background-image: url(../../../../assets/images/hair.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  background-position: center;
  width: 100%;
  padding: 4rem 1rem;
  overflow: scroll;
}
.skin-section {
  background-image: url(../../../../assets/images/skin.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #fff;
  width: 100%;
  padding: 4rem 1rem;
  overflow: scroll;
}
.hair-section::-webkit-scrollbar {
  display: none;
}
.skin-section::-webkit-scrollbar {
  display: none;
}
.skin-dry-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #fff;
  border-radius: 50px;
  color: #000;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
    inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.skin-Oily-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #fff;
  border-radius: 50px;
  color: #000;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
    inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.hair-dry-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #fff;
  border-radius: 50px;
  color: #000;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
    inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.hair-Oily-btn {
  font-family: "Yekan Bakh";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: table;
  width: 270px;
  height: 55px;
  background: #fff;
  border-radius: 50px;
  color: #000;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
    inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 90% !important;
    width: 80%;
    display: flex;
    background-color: #d9d9d9;
  }
}

@media (max-width: 1080px) {
  .first-modal-btn{
    grid-template-columns: 1fr;

}
}@media (max-width: 600px) {
  .first-modal-btn1{
    grid-template-columns: 1fr;

}
}
@media (max-width: 768px) {

.first-modal-btn{
    grid-template-columns: 1fr;
}  .div#healthCareContet {
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    position: fixed;
    top: 0;
  }
  .modal-dialog {
    margin: 0;
  }
  .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
  }
}
div#healthCare {
    position: fixed;
    inset: 0;
    z-index: 10000;
    background: #00000099;
}
</style>
