<template>
  <section class="product-details-img py-1 px-1 py-lg-5 px-lg-5 position-relative">
    <div class="product-details-l-img">
      <!-- Swiper -->
      <div v-if="galleries != null"  class="swiper-container gallery-swiper mySwiper">
        <div class="swiper-wrapper">
          <div v-for="(image) in galleries" :key="image.id" class="swiper-slide">
            <!-- <Spinner2 v-if="!(isAlreadyLoaded(getImage(image)))" class="loading" /> -->
            <!-- MOBILE -->
            <img  @load="imageLoaded(getImage(image))" v-if="mediaQueries.large" :src="getImage(image, 'lg')" @click="openViewer(image)" loading="lazy" class="main" :style="'cursor: zoom-in'" />
            <!-- DESKTOP -->
            <PicZoom @load="imageLoaded" @clicked="openViewer(image)" v-if="!mediaQueries.large && galleries != null" box=".gallery-swiper" ref="piczoom" class="main" :url="getImage(image)"/>
          </div>
        </div>
      </div>
      <div v-else class="h-100">
        <div class="swiper-slide p-2"></div>
      </div>
    </div>
    <div class="d-none d-lg-block swiper-gellery-next"><svg style="transform: rotate(180deg);" width="24" height="24" viewBox="0 0 24 24" fill="#565656" xmlns="http://www.w3.org/2000/svg"><path d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z" fill="#565656"></path></svg></div>
    <div class="d-none d-lg-block swiper-gellery-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="#565656" xmlns="http://www.w3.org/2000/svg"><path d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z" fill="#565656"></path></svg></div>
    <!-- <div @scroll="isScrolling" class="product-details-s-img ml-2" >
      <div style="overflow-x: auto;overflow-y:hidden" v-if="galleries != null" class="d-flex flex-row" v-dragscroll="!mediaQueries.mobileSize">
        <a class="small-pic" v-for="(image, index) in galleries" :key="image.id" :active="currentIndex === index" @click="goToSlide(index)">
          <img loading="lazy" :src="getImage(image, 'md')"></a>
      </div>
      <div v-else class="d-flex flex-row">
        <a v-for="i in totalSkeletons"></a>
      </div>
    </div> -->
    <SilentBox ref="silentBox" :gallery="galleryFriendlyImages" />
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle";
import {hasClass} from '@/libraries/VueMethods'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import SilentBox from "@/components/gallery/components/Gallery"
import PicZoom from "@/components/piczoom/PicZoom"
import {getImage} from '@/libraries/functions'
import Spinner2 from "@/parts/Front/components/shared/Spinner2";


export default {
  props: ['galleries', 'selectedVariety'],
  inject: ['mediaQueries'],
  data() {
    return {
      swiper: null,
      // If is scrolling dont change image
      scrolling: false,
      loadedImages: []
    }
  },
  components: {Spinner2, Viewer, SilentBox, PicZoom},
  computed: {
    totalSkeletons() {
      return window.innerWidth < 450 ? 2 : 3
    },
    currentIndex() {
      if (!this.swiper) {
        return -1
      }
      return this.swiper.realIndex;
    },
    galleryFriendlyImages() {
      return this.galleries ? this.galleries.map(g => ({src: g.url, id: g.id})) : []
    }
  },
  watch: {
    galleries(newVal) {
      if (newVal == null) {
        return
      }
      ////swiper slider of product details
      if (this.swiper)
        this.swiper.destroy()
        this.initialSwiper()
    },
    selectedVariety: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          let index = this.galleries.findIndex((image) => {
            return image.variety_id == newVal.id;
          });
          if (index > -1) {
            this.$nextTick(() => {
              this.$nextTick(() => {
                setTimeout(() => {
                  this.goToSlide(index);
                }, 30);
              });
            });
          }
        }
      },
    },
  },
  mounted() {
    this.initialSwiper()
    // this.onResize()
    // window.removeEventListener('resize', this.onResize);
    // window.addEventListener('resize', this.onResize);
  },
  methods: {
    getImage,
    imageLoaded(src) {
      if (!this.loadedImages.includes(src)) {
        this.loadedImages.push(src)
      }
    },
    isAlreadyLoaded(src) {
      return this.loadedImages.includes(src)
    },
    mousemove(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mousemove(e)
    },
    mouseover(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mouseover(e)
    },
    mouseleave(e) {
      this.$refs.piczoom && this.$refs.piczoom[this.currentIndex].mouseleave(e)
    },
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const productLargeImg = document.querySelectorAll('.product-details-l-img')[0];
      const productSmallImg = document.querySelectorAll('.product-details-s-img')[0];
      const productSmallImgItem = document.querySelectorAll('.product-details-s-img a');
      if (productLargeImg && productSmallImg) {
        if (bodyWidth < 576) {
          for (const productSmlImg of productSmallImgItem) {
            productSmlImg.style.height = (productSmlImg.offsetWidth) + "px";

          }
        }
        if (bodyWidth < 400) {
          // productLargeImg.style.height = (productLargeImg.offsetWidth) / 0.75 + "px";
          // productSmallImg.style.height = (productLargeImg.offsetWidth) / 0.75 + "px";
          for (const productSmlImg of productSmallImgItem) {
            // productSmlImg.style.height = (productSmlImg.offsetWidth) + "px";

          }
        }
      }
    },
    goToSlide(slideNumber) {
      if (this.scrolling && this.scrolling + 150 > new Date().getTime() || !this.swiper) {
        return;
      }
      document.querySelectorAll('.mouse-cover-canvas').forEach(el=> el.style.display = 'none')

      this.swiper.slideTo(slideNumber);
    },
    // برای رفع باگ کلیک شدن موقع اسکرول
    isScrolling() {
      this.scrolling = new Date().getTime();
    },
    // Wheeling with mouse event
    isWheeling(e) {
      let deltaY = e.deltaY;
      let preventDefault = true;
      if (deltaY < -40 && this.currentIndex > 0) {
        this.goToSlide(this.currentIndex - 1)
      } else if (deltaY > 40 && this.currentIndex < this.galleries.length - 1) {
        this.goToSlide(this.currentIndex + 1)
      } else {
        preventDefault = false
      }
      if (preventDefault) {
        e.preventDefault()
      } else {
        window.scroll({
          top: window.scrollY + (deltaY > 40 ? 200 : -200),
          left: window.scrollX,
          behavior: 'smooth'
        });
      }
    },
    openViewer(image) {
      let index = this.galleryFriendlyImages.findIndex(item => item.src == image.url);
      this.$refs.silentBox.openOverlay(this.galleryFriendlyImages[index], index)
      // let galleries = [...this.galleries]
      // galleries.sort((a,b) => {
      //   if (a.id == image.id) return -1;
      //   if (b.id != image.id) return 1;
      //   return 0;
      // });
      // this.$viewerApi({
      //   images: galleries.map(image => image.url),
      //   options: {
      //     zoomable:false,
      //     rotatable: false,
      //     navbar: true,
      //     scalable: false,
      //     title: false
      //   }
      // })
    },
    initialSwiper(){
      this.swiper = new Swiper('.product-details-img .swiper-container', {
        loop: true,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 1,
            slideShadows: false,
        },
        navigation: {
            nextEl: '.product-details-img .swiper-gellery-next',
            prevEl: '.product-details-img .swiper-gellery-prev',
        },
      })
    }
  }
}
</script>
<style scoped>
  .swiper-slide {
    width: 400px;
    height: 400px;
  }
  .swiper-slide:not(.swiper-slide-active) {
    filter: grayscale(1);
  }
  .product-details-l-img img.main {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 0;
  }
  .small-pic {
    transform: scale(1);
    transition: 200ms all;
  }
  .small-pic[active] {
    border: 0px solid #000000;
    box-shadow: 0 0 0px 2px #000000;
    transform: scale(1.03);
  }
  .enlarge {
    cursor: pointer;
    position: absolute;
    width: 27px;
    height: 27px;
    left: 10px;
    top: 10px;
    padding: 6px;
    background: white;
    border-radius: 50%;
    transition: 200ms all;
    user-select: none;
  }
  .enlarge:hover {
    width: 29px;
    height: 29px;
    left: 9px;
    top: 9px;
  }
  .enlarge img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: baseline;
    user-select: none;
  }
  .product-details-s-img a {
    width: 99px;
    background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
    margin: 5px 10px;
    text-align: center;
    flex-shrink: 0;
  }
  .product-details-s-img a img {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .swiper-gellery-next{
    position: absolute;
    /* background-color: white;
    box-shadow: 0px 0px 50px rgba(0,0,0,0.5);
    border-radius: 50%; */
    padding: 5px;
    top: 50%;
    right: 0;
    z-index: 10;
    cursor:pointer;
  }
  .swiper-gellery-prev{
    position: absolute;
    /* background-color: white;
    box-shadow: 0px 0px 50px rgba(0,0,0,0.5);
    border-radius: 50%; */
    padding: 5px;
    top: 50%;
    left: 0;
    z-index: 10;
    cursor:pointer;
  }

  @media screen and (max-width: 575px) {
    .product-details-s-img a {
      margin: 5px 5px;
    }
    .product-details-s-img {
      padding-top: 1px;
    }
    .product-details-l-img {
      height: auto;
    }
  }
</style>
<style>
  .zoom-on-hover {
    height: 100%;
  }

  .zoom-on-hover .normal {
    height: 100%;
  }

  .product-details-img img.zoom {
    cursor: zoom-out;
  }

  .viewer-canvas > img {
    left: 50%;
    transform: translateX(-50%)!important;
    margin-left: unset!important;
  }

  .viewer-list {
    width: 100% !important;
  }

  .viewer-list > li {
    min-width: 40px!important;
  }

  .viewer-reset {
    display: none!important;
  }

  .loading {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
