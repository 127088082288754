<template>
  <div class="in-product-detail">
    <main class="main container product-details-main">
      <PagesPath :pathTitle1="'محصولات'" :pathTo1="'/products'"
                 :pathTitle2="product != null ? product.title : ''"></PagesPath>
        <ProductContent class="white-bg mt-4" :comparable="true" v-model="product"/>
        <SimilarProducts v-if=" product&&!product.isPreview" :related="relatedProducts"/>
        <section class="product-specifications-comments">
          <!-- <ul v-if="product != null &&!product.isPreview" class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10">
            <li v-if=" product != null && product.description != null"
                :class="activeTab === 'review' ? 'active' : ''"><a
                class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" data-toggle="tab"
                :class="activeTab==='review' ? 'active' : ''" @click="changeTab('review')">
              نقد و بررسی
            </a></li>
            <li v-if="product != null && product.specifications != '' "
                :class="activeTab === 'specifications' ? 'active' : '' "><a
                class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" data-toggle="tab"
                :class="activeTab === 'specifications' ? 'active' : '' " @click="changeTab('specifications')">
              مشخصات
            </a></li>
            <li :class="activeTab === 'comments' ? 'active' : '' "><a
                class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" data-toggle="tab"
                :class="activeTab === 'comments' ? 'active' : '' " @click="changeTab('comments')">
              نظرات کاربران
            </a></li>
            <li :class="activeTab === 'productQuestion' ? 'active' : '' "><a
                class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" data-toggle="tab"
                :class="activeTab === 'productQuestion' ? 'active' : '' " @click="changeTab('productQuestion')">
               پرسش و پاسخ
            </a></li>
          </ul>
          <ul v-else class="nav nav-tabs border-bottom py-4 px-1">
            <li class=" active"><a
                class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-medium active"
                data-toggle="tab" href="#review">
              نقد بررسی
            </a></li>
          </ul> -->
          <div v-if="product != null &&!product.isPreview" class="tab-content">
            <div class="white-bg mt-4" id="productReview" v-if=" product != null && product.description != null">
              <ul class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10">
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active" @click="scrollToTab('productReview')">نقد و بررسی</a>
              </li>
              <li v-if="product != null && product.specifications != '' ">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productSpecifications')">مشخصات</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productComments')">نظرات کاربران</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productQuestion')">پرسش و پاسخ</a>
              </li>
            </ul>
              <ProductReview :activeTab="'review'" :product="product"/>
            </div>
            <div class="white-bg mt-4" id="productSpecifications" v-if="product != null && product.specifications != ''">
              <ul class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10">
              <li v-if=" product != null && product.description != null">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productReview')">نقد و بررسی</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active" @click="scrollToTab('productSpecifications')">مشخصات</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productComments')">نظرات کاربران</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productQuestion')">پرسش و پاسخ</a>
              </li>
            </ul>
              <ProductSpecifications class="mt-4" :activeTab="'specifications'" :product="product"/>
            </div>
            <div class="white-bg mt-4" id="productComments">
              <ul class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10">
              <li v-if=" product != null && product.description != null">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small " @click="scrollToTab('productReview')">نقد و بررسی</a>
              </li>
              <li v-if="product != null && product.specifications != '' ">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productSpecifications')">مشخصات</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active" @click="scrollToTab('productComments')">نظرات کاربران</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productQuestion')">پرسش و پاسخ</a>
              </li>
            </ul>
              <ProductComments :activeTab="'comments'" :product="product" />
            </div>
            <div class="white-bg mt-4" id="productQuestion">
              <ul class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10">
              <li v-if=" product != null && product.description != null">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productReview')">نقد و بررسی</a>
              </li>
              <li v-if="product != null && product.specifications != '' ">
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productSpecifications')">مشخصات</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small" @click="scrollToTab('productComments')">نظرات کاربران</a>
              </li>
              <li>
                <a class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active" @click="scrollToTab('productQuestion')">پرسش و پاسخ</a>
              </li>
            </ul>
              <ProductQuestion :activeTab="'productQuestion'" :product="product" />
            </div>
          </div>
          <div v-else class="tab-content mt-5">
            <div id="review" class="tab-pane fade in active show">
              <p class="fontsize14 text-color-666 text-justify line-height2 p-3">
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
              </p>
            </div>
          </div>
        </section>
      <ScrollToTop></ScrollToTop>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import ProductServices from "@/parts/Front/components/productDetail/ProductServices";
import ProductComments from "@/parts/Front/components/productDetail/ProductComments";
import ProductQuestion from "@/parts/Front/components/productDetail/ProductQuestion";
import ProductSpecifications from "@/parts/Front/components/productDetail/ProductSpecifications";
import ProductReview from "@/parts/Front/components/productDetail/ProductReview";
import SimilarProducts from "@/parts/Front/components/productDetail/SimilarProducts";
import ProductContent from "@/parts/Front/components/productDetail/ProductContent";
import product from "@/parts/Front/components/productDetail/classes/product";
import ProductCongratulations from "@/parts/Front/components/productDetail/ProductCongratulations";
import config from '@/config'
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "ProductDetails",
  components: {
    ProductCongratulations,
    PagesPath,
    ScrollToTop,
    ProductServices,
    ProductComments,
    ProductQuestion,
    ProductSpecifications,
    ProductReview,
    SimilarProducts,
    ProductContent,
    BSkeleton
  },
  data() {
    return {
      url: 'front/products/',
      title: '',
      disabled: false,
      relatedProducts: Array(5).fill(null),
      product: null,
      // activeTab: null,
    }
  },
  mounted() {
    // this.$store.dispatch('front/getProductDataFromServer', this.$route.params.id)
    if (this.$route.params.id) {
      this.getProduct(this.$route.params.id)
    }

    this.onResize()
    window.removeEventListener('resize', this.onResize)
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const colorChooseImg = document.querySelectorAll('.color-choose-img')[0];
      // const productDetContent=document.querySelectorAll('.product-details-content')[0];
      if (colorChooseImg) {
        if (bodyWidth < 767) {
          colorChooseImg.style.width = (bodyWidth - 100) + "px";

        }
      }
    },
    // changeTab(name) {
    //   // this.$store.commit('front/setProductActiveTab', name)
    //   this.activeTab = name
    // },
    async getProduct(id) {
      this.disabled = true
      try {
        if (this.getInitialProductInfo) {
          this.product = this.getInitialProductInfo;
        }
        const response = await this.$store.dispatch(
          "front/getProductFromServer",
          id
        );
        this.product = new product(response.data.data.product, this);
        this.relatedProducts = response.data.data.relatedProducts1

        // if (this.product.description != null) {
        //   this.activeTab = 'review'
        // } else if (this.product.description == null && this.product.specifications != '') {
        //   this.activeTab = 'specifications'
        // } else {
        //   this.activeTab = 'comments'
        // }
      } catch (error) {
        console.log(error);
        this.$root.errorNotify('محصول مورد نظر یافت نشد');
        this.$router.push('/')
      } finally {
        this.disabled = false
      }
    },
    scrollToTab(selectedTab){
      let element = document.getElementById(selectedTab)
      element.scrollIntoView({ behavior: "smooth" })
    }
  },
  computed:{
    getInitialProductInfo() {
      return this.$store.getters["front/getSavedProductInfo"];
    },
  },
  metaInfo() {
    return {
      title: this.product ? config.title + ` - ${this.product.title}` : config.title,
      meta: [
        {
          name: 'description',
          content: this.product ? (this.product.meta_description ? this.product.meta_description : this.product.short_description) : false
        },
        {property: 'og:title', content: this.product ? this.product.title : false},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style scoped>

</style>
<style>
@media screen and (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
  }
  @media screen and (max-width:400px){
    .nav-tabs li a{
      font-size: 14px;
      padding: .25rem!important;
      margin: .12rem!important;
    }
  }
}</style>
