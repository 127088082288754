<template>
  <div class="box-select-container">
<!--    :disabled="option.isDisabled"-->

    <button
        class="box-select-option border text-muted bg-none px-3 border-r-25 ml-2"
         :class="isSelected(option.id) ? 'selected' : 'btn-outline-dark' + ' ' + getAdditionalClass(option)"
         @click="select(option.id)"
         v-for="option in options">
      <span>{{ option.label }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BoxSelect",
  props: {
    options: Array, // id, label, idDisabled
    value: [String, Number]
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value() {
      this.realValue = this.value;
    }
  },
  methods: {
    select(value) {
      this.$emit('input', value)
    },
    isSelected(id) {
      return this.realValue === id;
    },
    getAdditionalClass(option) {
      switch (option.label.length) {
        case 7: return 'seven'
        case 6: return 'six'
        case 5: return 'five'
        case 4: return 'four'
        case 3: return 'three'
        case 2: return 'two'
        case 1: return 'one'
        default: return 'seven'
      }
    }
  }
}
</script>

<style scoped>
  @media screen  and (max-width: 768px){
    .box-select-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 7px;
    }
  }
  .box-select-option span {
    vertical-align: bottom;
    vertical-align: sub;
  }

  .box-select-option {
    /* min-width: 42px; */
    /* margin-left: 5px; */
    position: relative;
    /* border-radius: 0; */
  }

  .box-select-option:disabled:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -20%;
    width: 137%;
    height: 1.4px;
    background: red;
    transform: rotate(45deg);
  }
  .box-select-option:disabled:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -20%;
    width: 137%;
    height: 1.4px;
    background: red;
    transform: rotate(-45deg);
  }


  .selected {
    box-shadow: 0 0 0 0.13rem var(--color-theme)!important;
    font-weight: bold;
  }
</style>
<style>
.box-select-container .btn-outline-dark {
  border-color: var(--color-theme);
}
</style>
