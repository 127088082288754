<template>
  <section class="productTab-products container">
    <div class="recommended-products-tabs container">
      <div
        class="recommended-products-category d-flex flex-column flex-xl-row align-items-start align-items-xl-center gap-20"
      >
        <div class="d-flex align-items-center gap-5 text-color-grey">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5198 20.69C15.0198 20.69 15.3698 20.52 15.5798 20.36C15.9798 20.07 16.5998 19.32 16.2098 17.64L15.6398 15.18C15.5898 14.97 15.6998 14.59 15.8498 14.43L17.8398 12.44C18.8898 11.39 18.7998 10.48 18.6598 10.03C18.5198 9.58 18.0598 8.78999 16.5998 8.53999L14.0498 8.12C13.8598 8.08999 13.5498 7.86 13.4698 7.69L12.0598 4.87C11.3798 3.51 10.4798 3.31 9.99981 3.31C9.51982 3.31 8.61982 3.51 7.93982 4.87L6.52981 7.69C6.43981 7.86 6.13983 8.08999 5.94983 8.12L3.39981 8.53999C1.93981 8.77999 1.47981 9.58 1.33981 10.03C1.19981 10.48 1.10982 11.39 2.15982 12.44L4.14981 14.43C4.29981 14.58 4.4098 14.97 4.3598 15.18L3.78982 17.64C3.39982 19.33 4.01983 20.07 4.41983 20.36C4.81983 20.65 5.70981 21.02 7.20981 20.13L9.59982 18.71C9.79982 18.59 10.2198 18.59 10.4198 18.71L12.8098 20.13C13.4998 20.55 14.0798 20.69 14.5198 20.69ZM9.99981 4.81C10.1598 4.81 10.4498 4.99999 10.7198 5.53999L12.1298 8.36C12.4298 8.97 13.1398 9.49001 13.8098 9.60001L16.3598 10.02C16.9298 10.12 17.1898 10.33 17.2398 10.49C17.2898 10.65 17.1998 10.97 16.7898 11.38L14.7998 13.37C14.2898 13.88 14.0198 14.81 14.1798 15.51L14.7498 17.97C14.9198 18.68 14.8098 19.06 14.6998 19.15C14.5898 19.23 14.1898 19.22 13.5698 18.84L11.1698 17.42C10.4998 17.02 9.4898 17.02 8.8298 17.42L6.43982 18.84C5.80982 19.21 5.4098 19.23 5.2998 19.15C5.1898 19.07 5.07981 18.69 5.24981 17.97L5.81982 15.51C5.97982 14.8 5.71983 13.88 5.19983 13.37L3.20981 11.38C2.79981 10.97 2.70983 10.64 2.75982 10.49C2.80982 10.34 3.06983 10.12 3.63983 10.02L6.18982 9.60001C6.85982 9.49001 7.56981 8.97 7.86981 8.36L9.27981 5.53999C9.54981 4.99999 9.83982 4.81 9.99981 4.81Z"
              fill="#434343"
            />
            <path
              d="M16 5.75H22C22.41 5.75 22.75 5.41 22.75 5C22.75 4.59 22.41 4.25 22 4.25H16C15.59 4.25 15.25 4.59 15.25 5C15.25 5.41 15.59 5.75 16 5.75Z"
              fill="#434343"
            />
            <path
              d="M19 19.75H22C22.41 19.75 22.75 19.41 22.75 19C22.75 18.59 22.41 18.25 22 18.25H19C18.59 18.25 18.25 18.59 18.25 19C18.25 19.41 18.59 19.75 19 19.75Z"
              fill="#434343"
            />
            <path
              d="M21 12.75H22C22.41 12.75 22.75 12.41 22.75 12C22.75 11.59 22.41 11.25 22 11.25H21C20.59 11.25 20.25 11.59 20.25 12C20.25 12.41 20.59 12.75 21 12.75Z"
              fill="#434343"
            />
          </svg>
          <h3
            class="m-0 d-inline-block fontsize-heavy"
            style="font-weight: 900"
          >
            محصولات
          </h3>
          <p class="fontsize-small m-0">بندیتو</p>
        </div>
        <div class="custom-line d-none d-xl-block"></div>
        <ul class="tab-navigation d-flex align-items-center flex-nowrap gap-5">
          <li
            v-for="(tab, index) in tabs"
            @click="setActiveProducts(tab)"
            :key="index"
            class="ml-1"
            role="presentation"
          >
            <a
              class="py-1 px-2 py-xl-2 px-xl-3 fontsize-mini"
              :class="{ active: activeTab == tab.name }"
              id="home-tab"
              aria-controls="home"
              aria-selected="true"
              >{{ tab.label }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="recommended-content-container margin-t-20">
      <div class="recommended-content">
        <div class="recommended-content-item container" id="home">
          <div :style="`min-height: ${minHeight}px`">
            <div
              class="swiper-container"
              v-if="!disabledSliders.includes('productTabSlider')"
            >
              <div class="swiper-wrapper">
                <Product
                  class="swiper-slide"
                  :style="'max-width:' + maxWidth + 'px'"
                  v-for="(product, index) in products"
                  :key="index + 'index'"
                  :product="product"
                />
              </div>
            </div>
            <div v-if="!products">
              <Loading :mode="'dot'" />
            </div>
          </div>
        </div>
        <div class="recommended-content-bg">
          <div class="next-btn-holder d-none d-xl-block">
            <div class="next-btn">
              <svg
                width="46"
                height="140"
                viewBox="0 0 46 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
                  fill="#f2f2f2"
                />
              </svg>
              <div class="next-btn-icon">
                <RightChevIcon :fill="'#444444'" />
              </div>
            </div>
          </div>
          <div class="prev-btn-holder d-none d-xl-block">
            <div class="prev-btn">
              <svg
                width="46"
                height="140"
                viewBox="0 0 46 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
                  fill="#f2f2f2"
                />
              </svg>
              <div class="prev-btn-icon">
                <RightChevIcon :fill="'#444444'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/parts/Front/components/shared/Loading";
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import LeftChevIcon from "@/parts/Front/components/Icon/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";
let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 10,
  },
};

export default {
  name: "ProductTab",
  components: { Product, Loading, LeftChevIcon, RightChevIcon },
  data() {
    return {
      // activeRoute: "/products?sort=most_discount",
      minHeight: "unset",
      maxWidth: "unset",
      activeTab: "suggestions",
      readyProducts: null,
      tabs: [
        {
          name: "suggestions",
          label: "پیشنهادی",
        },
        {
          name: "new-products",
          label: "محصولات جدید",
        },
      ],
      // loadedTabs: ["suggestions"],
      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    activeTab: {
      handler: function () {
        this.initSlider(true);
      },
    },
    products: {
      immediate: true,
      handler(products) {
        if (!products) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.setReadyProducts(this.activeTab);
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },
    products() {
      return this.readyProducts;
    },
  },
  methods: {
    async setReadyProducts(key) {
      this.activeTab = key;
      this.readyProducts = null;
      let res = await this.$store.dispatch("front/getIndexPageContent", key);
      this.readyProducts = res;
    },
    setActiveProducts(tab) {
      this.activeTab = tab.name;
      this.setReadyProducts(this.activeTab);
    },

    // getProductMainImageUrl(product) {
    //   return getImage(product.major_image, "lg");
    // },
    onResize() {
      this.setMaxWidth();
    },
    // loadTab(name) {
    //   if (this.loadedTabs.includes(name)) {
    //     return;
    //   }
    //   this.loadedTabs.push(name);
    //   this[name].forEach((product) => {
    //     if (!product) return;
    //     let url = this.getProductMainImageUrl(product);
    //     let img = new Image();
    //     img.src = url;
    //   });
    // },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".productTab-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("productTabSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(".productTab-products .swiper-container", {
          spaceBetween: 1,
          centeredSlides: true,
          centeredSlidesBounds: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".productTab-products .next-btn-holder",
            prevEl: ".productTab-products .prev-btn-holder",
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(".productTab-products .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.recommended-content-container {
  padding: 0 40px 0 70px;
  position: relative;
}
.recommended-content-bg {
  background-color: #f2f2f2;
  border-radius: 40px;
  position: absolute;
  width: 100%;
  height: 70%;
  top: 50%;
  transform: translateY(-50%);
}
.swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.recommended-content {
  position: relative;
  /* top: 80px; */
}
.swiper-container {
  animation: fade 450ms;
}
.nav-item {
  border-radius: 25px;
  border: 1px solid #999999;
  white-space: nowrap;
}
.nav-link {
  color: #999999 !important;
}

.prev-btn-holder {
  position: absolute;
  top: 53%;
  right: 98.88%;
  transform: translateY(-50%) rotateY(180deg);
}
.next-btn-holder {
  position: absolute;
  top: 53%;
  left: 99%;
  transform: translateY(-50%);
}
.prev-btn {
  /* background-color: rgba(242, 242, 242, 1);
  height: 110px;
  width: 100px; */
  position: relative;
  /* -webkit-clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
  clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  ); */
}
.prev-btn-icon {
  position: absolute;
  top: 53%;
  right: 8%;
  transform: translateY(-50%);
}
.next-btn {
  /* background-color: rgba(242, 242, 242, 1); */
  position: relative;
  /* height: 110px;
  width: 100px; */
  /* -webkit-clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
  clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30% 
  );*/
}
.next-btn-icon {
  position: absolute;
  top: 53%;
  left: 8%;
  transform: translateY(-50%);
}
.custom-line {
  width: 100%;
  height: 1px;
  border-radius: 25px;
  background-color: #cccccc;
  flex-grow: 1;
}
.tab-navigation li {
  list-style-type: none;
}
.tab-navigation li a {
  white-space: nowrap;
  list-style-type: none;
  color: var(--color-777);
  border: 1px solid var(--color-c8);
  border-radius: 25px;
  font-weight: bold;
  background-color: transparent;
}
.tab-navigation li a.active {
  color: #ffffff;
  border: 1px solid var(--color-main);
  background-color: var(--color-main) !important;
}

@media (max-width: 1199px) {
  .productTab-products {
    width: 100% !important;
  }
  .recommended-content-container {
    border-radius: unset;
    padding: 0;
  }
}
</style>
<style>
/* .nav-link.active {
  font-weight: normal !important;
  background-color: #ffb16f !important;
  border-radius: 25px !important;
  font-size: x-small !important;
  color: #ffffff !important;
} */
@media (max-width: 1199px) {
  .productTab-products {
    width: 100% !important;
  }
  .recommended-content-container {
    border-radius: unset;
    padding: 0;
  }
}
</style>
