<template>
  <main>
    <PagesPath :pathTitle1="'باشگاه مشتریان بندیتو'"></PagesPath>

    <section class="clubDetail">
      <div class="white-bg container">
        <div>
          <h3 class="container font-900 margin-b-50">باشگاه مشتریان بندیتو</h3>
          <div class="row justify-content-around">
            <div class="col-12 col-lg-5">
              <p class="club-detail-first-paragraph">
                بندیتو با سابقه ی 10 ساله در تولید تخصصی روغن های گیاهی، همواره
                به دنبال رضایت مشتریان خود بوده است.به منظور ارائه خدمات بهتر و
                بیشتر به مشتریان عزیز،باشگاه مشتریان بندیتو راه اندازی شد. این
                باشگاه، با تخفیفات ویژه برای اعضا، بهنیازهای مشتریان پاسخ می دهد
                و به رضایت آنها می اندیشد. با خرید هر یک از محصولات بندیتو و کسب
                امتیاز لازم از خدمات و مزایای باشگاه مشتریان بهره مند خواهید شد.
              </p>
            </div>
            <div id="topTenCustomer" class="col-12 col-lg-6" v-if="topTen">
              <p
                class="w-100 text-center font-w-900 fontsize-heavy bg-color-theme text-light py-1 border-r-30"
              >
                برترین امتیازها
              </p>
              <div
                class="d-flex align-items-center gap-10 mb-2 mt-2 overflow-auto"
              >
                <button
                  @click="changeTab('last_month')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'last_month' ? 'bg-color-theme text-light' : ''
                  "
                >
                  ماه
                </button>
                <button
                  @click="changeTab('last_year')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'last_year' ? 'bg-color-theme text-light' : ''
                  "
                >
                  سال
                </button>
                <button
                  @click="changeTab('all_time')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'all_time' ? 'bg-color-theme text-light' : ''
                  "
                >
                  کل
                </button>
                <router-link
                  v-if="isLogin"
                  class="btn-light py-2 px-5 border-r-30 border w-s-nowrap"
                  to="/club-scores"
                  >امتیاز من</router-link
                >
              </div>
              <div
                class="w-100 club-table-container"
                v-if="topTen[activeTab] && topTen[activeTab].length"
              >
                <table class="table">
                  <thead>
                    <tr class="table-bg-color-pink">
                      <th>ردیف</th>
                      <th>کاربر</th>
                      <th>سطح</th>
                      <th>امتیاز</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="table-bg-color-pink-2"
                      v-for="(item, index) in topTen[activeTab]"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column gap-3 mx-auto"
                        >
                          <span>{{ item.customer }}</span>
                          <span class="dir-ltr">{{ item.dashed_mobile }}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column gap-3 mx-auto"
                        >
                          <div class="club-image-holder">
                            <img
                              v-if="item.customers_club_level.image"
                              :id="'club-level-' + index"
                              class="w-100 h-100"
                              :src="`https://api.benedito.ir/${item.customers_club_level.image}`"
                              alt=""
                            />
                          </div>
                          <b-tooltip
                            v-if="item.customers_club_level.image"
                            :target="'club-level-' + index"
                            triggers="hover"
                          >
                            {{ item.customers_club_level.level }}
                          </b-tooltip>
                          <span
                            v-if="item.customers_club_level.image == null"
                            >{{ item.customers_club_level.level }}</span
                          >
                        </div>
                      </td>
                      <td>{{ item.score }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-info" role="alert">
                موردی یافت نشد!
              </div>
            </div>
          </div>
        </div>
        <div class="clubDetail-cards mt-5">
          <h3 class="font-900 mb-4">خدمات باشگاه مشتریان بندیتو</h3>
          <div>
            <p>
              باشگاه مشتریان بندیتو دارای چهار سطح ( سفید، برنزی، نقره ای و
              طلایی ) میباشد. شما با هر بار خرید در فروشگاه بندیتو و کسب امتیاز
              لازم، طبق قوانین باشگاه در یکی از سطوح قرار می گیرید.
            </p>
          </div>
          <div class="d-none d-lg-block scoringTable">
            <table class="w-100">
              <thead>
                <tr class="table-bg-color-pink">
                  <th>خدمات</th>
                  <th v-for="(level, index) in levels" :key="index">
                    <img :src="level.image" :alt="level.title" />
                    <span>{{ level.title }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-bg-color-pink-2"
                  v-for="(plan, index) in plansTable"
                  :key="index"
                >
                  <td class="font-w-800">{{ plan.title }}</td>
                  <td v-for="(value, index) in plan.values" :key="index">
                    <emphasis v-if="value.value == true" />
                    <DeletedClubDetail v-else-if="value.value == false" />
                    <span v-else>{{ value.value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block d-lg-none scoringTable" id="mobilePlanTable">
            <div
              class="d-flex align-items-center justify-content-between px-4 mb-3 mt-2"
            >
              <button
                class="px-4 py-2 bg-color-theme text-white border-r-25"
                @click="clickHandler('next')"
              >
                سطح بعدی
              </button>
              <button
                class="px-4 py-2 bg-color-theme text-white border-r-25"
                @click="clickHandler('prev')"
              >
                سطح قبلی
              </button>
            </div>
            <table class="w-100">
              <thead>
                <tr class="table-bg-color-pink">
                  <th>خدمات</th>
                  <th
                    class="d-flex align-items-center justify-content-center flex-column"
                  >
                    <img
                      :src="levels[activePlan].image"
                      :alt="levels[activePlan].title"
                    />
                    <span>{{ levels[activePlan].title }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-bg-color-pink-2" v-for="(plan, index) in plansTable" :key="index">
                  <td class="font-w-800">{{ plan.title }}</td>
                  <td>
                    <emphasis v-if="plan.values[activePlan].value == true" />
                    <DeletedClubDetail
                      v-else-if="plan.values[activePlan].value == false"
                    />
                    <span v-else>{{ plan.values[activePlan].value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="Description-performance-clubDetail margin-t-50">
          <div class="Description-performance-title">
            <h3 class="font-weight-bold mb-3">شرح عملکرد باشگاه مشتریان:</h3>
            <p>
              در باشگاه مشتریان بندیتو دو نوع هدیه بابت عملیاتی که کاربر در وب
              سایت انجاممی دهد در نظر گرفته شده است. این دو نوع عبارتند از بنو
              امتیاز.
            </p>
          </div>
          <div>
            <h3 class="font-weight-bold margin-b-20">بن:</h3>
            <p>این نوع از هدیه قابل خرید می باشد.</p>
            <p>
              با به دست آوردن بن می توان از آن در خرید ها به صورت اعتبار هدیه
              استفادهنمود.
            </p>
            <p>
              این عملیات به این معنی است که پس از جمع کردن مقداری بن، درخواست
              توسط مشتریبه مدیر سایت ارسال شده و مدیر وب سایت پس از بررسی
              درخواست آن را تبدیل به پولموجود در کیف پول کرده و مشتری می تواند
              از آن در خرید های خود بهره ببرد.
            </p>
          </div>
          <div>
            <h3 class="font-weight-bold margin-b-20">امتیاز:</h3>
            <p>
              این نوع از هدیه فقط جهت استفاده مشتریان برای بالا بردن سطح بوده و
              قابل خرجکردن و کم شدن نمی باشد. به دست آوردن امتیاز در هر بخش باعث
              بالا رفتن سطح مشتریمی شود.
            </p>
          </div>
        </div>
        <div class="HowTo-score-points-clubDetail margin-t-50">
          <div class="scoringTable">
            <div>
              <h3 class="font-weight-bold mb-3">نحوه امتیاز گیری در وبسایت:</h3>
              <p
                class="font-weight-bold font-w-900 margin-b-50 mb-tab-30 font-s-18"
              >
                جدول 1 : عملیاتی که منجر به دریافت امتیاز یا بن در باشگاه می شود
              </p>
            </div>
            <table class="table1-score-points">
              <thead>
                <tr class="table-bg-color-pink">
                  <th>ردیف</th>
                  <th>نحوه امتیاز گیری</th>
                  <th>امتیاز</th>
                  <th>بن</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-bg-color-pink-2" v-for="item in table1">
                  <td>
                    {{ item.row }}
                  </td>
                  <td>
                    {{ item.des }}
                  </td>
                  <td>
                    {{ item.point }}
                  </td>
                  <td>
                    {{ item.bon }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="margin-t-50 mt-tab-30 scoringTable">
            <p
              class="font-weight-bold font-w-900 margin-b-40 mb-tab-30 font-s-18"
            >
              جدول2 : - امتیاز و بن دریافتی بابت خرید از وب سایت
            </p>
            <table class="table2-score-points">
              <thead>
                <tr class="table-bg-color-green">
                  <th>مبلغ خرید (تومان)</th>
                  <th>امتیاز</th>
                  <th>بن</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-bg-color-green-2" v-for="item in table2">
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.point }}
                  </td>
                  <td>
                    {{ item.bon }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="Scoring-rules margin-t-60">
          <h3 class="font-weight-bold mb-3">
            قوانین امتیاز گیری درباشگاه مشتریان:
          </h3>
          <nav>
            <ul>
              <li v-for="item in rules" class="margin-b-10 item-rules">
                {{ item.title }}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import DeletedClubDetail from "@/parts/Front/components/Icon/DeletedClubDetail.vue";
import emphasis from "@/parts/Front/components/Icon/emphasis.vue";
import PagesPath from "@/parts/Front/components/PagesPath";
import whiteImg from "@/assets/images/clubDetail/logoColorizedWhite.png";
import bronzeImg from "@/assets/images/clubDetail/logoColorizedBronze.png";
import silverImg from "@/assets/images/clubDetail/logoColorizedSilver.png";
import goldImg from "@/assets/images/clubDetail/logoColorizedGold.png";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "ClubDetail",
  components: {
    DeletedClubDetail,
    emphasis,
    PagesPath,
    BTooltip,
  },
  data() {
    return {
      activeTab: "last_month",
      activePlan: 0,
      table1: [
        {
          row: "1",
          des: "ثبت نام در وب سایت و تکمیل پروفایل",
          point: "200",
          bon: "2",
        },
        { row: "2", des: "خرید از وب سایت", point: "-", bon: "2" },
        {
          row: "3",
          des: "نوشتن نظرات برای محصولات خریداری شده",
          point: "200",
          bon: "2",
        },
        {
          row: "4",
          des: "عکس قبل و بعد از مصرف محصولات",
          point: "300",
          bon: "3",
        },
        {
          row: "5",
          des: "استفاده از لینک معرف برای ثبت نام",
          point: "100",
          bon: "-",
        },
        {
          row: "6",
          des: "اولین خرید توسط کسی که با لینک معرف ثبت نام کرده است",
          point: "1000",
          bon: "10",
        },
        {
          row: "7",
          des: "منشن کردن پیج ما در استوری به مدت 24 ساعت",
          point: "500",
          bon: "5",
        },
        { row: "8", des: "شرکت در نظر سنجی اینماد", point: "200", bon: "2" },
        {
          row: "9",
          des: "نحوه شرکت در تکمیل پرسشنامه گیری",
          point: "300",
          bon: "3",
        },
        { row: "10", des: "لاگین شدن روزانه در pwa", point: "300", bon: "1" },
      ],
      table2: [
        { price: "زیر 100 هزار", point: "100", bon: "1" },
        { price: "بین 100 تا 499 هزار   ", point: "200", bon: "2" },
        { price: "بین 500 تا 999 هزار", point: "400", bon: "4" },
        { price: "بیشتراز 1 میلیون", point: "600", bon: "6" },
      ],
      rules: [
        { title: "امتیاز ثبت نام وتکمیل پروفایل تنها یک بار قابل دریافت است" },
        {
          title:
            "برای خرید هایزیر مبلغ 100 هزار تومان امتیازی درنظر گرفته نمی شود",
        },
        {
          title:
            "برای خرید هایزیر مبلغ 100 هزار تومان امتیازی درنظر گرفته نمی شود",
        },
        { title: "برای هر محصولفقط یک بار امتیاز نظرات درنظر گرفته می شود" },
        {
          title:
            "در صورتی که عکسقبل و بعد از استفاده از محصول را ارسال کرده باشید و هنوز توسط مدیریت تأیید یارد نشده باشد تصویر جدیدی برای آن محصول نمی توانید ارسال کنید.",
        },
        {
          title:
            "در صورتی که براییک محصول عکس قبل و بعد از استفاده را ارسال کرده باشید و تأیید شده باشد، امتیازآن به شما تعلق می گیرد و مجدداً نمی توانید برای آن محصول تصویر قبل و بعد ارسالنمایید.",
        },
        {
          title:
            "هیچ محدودیتیبرای دریافت امتیاز برای کسانی که توسط شما در وب سایت ثبت نام می شوند وجود ندارد",
        },
        {
          title:
            "با خرید هر شخصیکه کد شما را به عنوان معرف ثبت کرده باشد امتیازی به شما تعلق خواهد گرفت",
        },
        {
          title:
            "برای دریافتامتیاز بخش منشن کردن پیج ما در استوری، باید تصاویر ارسالی شما در شبکه هایاجتماعی برای ادمین پیچ توسط ادمین تأیید گردد و با گذشتن 24 ساعت از ثبت آن، میتوان مجدداً برای دریافت امتیاز این بخش اقدام کرد.",
        },
        {
          title:
            "شرکت در نظرسنجیسایت اینماد فقط یک بار شامل کسب امتیاز هدیه خواهد شد.",
        },
        {
          title:
            "برای ورود به نرمافزار و دریافت امتیاز ورود هیچ محدودیتی درنظر گرفته نشده است و هر روز می توانیدبا ورود به نرم افزار آنلاین وب سایت، از این امتیاز بهره ببرید.",
        },
      ],
      levels: [
        {
          id: 1,
          title: "سطح سفید",
          image: whiteImg,
        },
        {
          id: 2,
          title: "سطح برنزی",
          image: bronzeImg,
        },
        {
          id: 3,
          title: "سطح نقره ای",
          image: silverImg,
        },
        {
          id: 4,
          title: "سطح طلایی",
          image: goldImg,
        },
      ],
      plansTable: [
        {
          id: 1,
          title: "تخفیف دائمی برگشت از خرید 3",
          values: [
            {
              plan_id: 1,
              value: "3%",
            },
            {
              plan_id: 1,
              value: "5%",
            },
            {
              plan_id: 1,
              value: "6%",
            },
            {
              plan_id: 1,
              value: "7%",
            },
          ],
        },
        {
          id: 2,
          title: "تخیف تاریخ تولد",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: "15%",
            },
            {
              plan_id: 1,
              value: "20%",
            },
            {
              plan_id: 1,
              value: "25%",
            },
          ],
        },
        {
          id: 3,
          title: " ارسال رایگان",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: "700,000 تومان",
            },
            {
              plan_id: 1,
              value: "600,000 تومان",
            },
            {
              plan_id: 1,
              value: "500,000 تومان",
            },
          ],
        },
        {
          id: 4,
          title: "اطلاع از محصولات شگفت انگیز",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: true,
            },
            {
              plan_id: 1,
              value: true,
            },
            {
              plan_id: 1,
              value: true,
            },
          ],
        },
        {
          id: 5,
          title: "اطلاع از لانچ محصولات جدید",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: true,
            },
            {
              plan_id: 1,
              value: true,
            },
          ],
        },
        {
          id: 6,
          title: "خرید محصول جدید با 20% تخفیف",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: true,
            },
            {
              plan_id: 1,
              value: true,
            },
          ],
        },
        {
          id: 7,
          title: "قرعه کشی ماهانه (مسافرت)",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: true,
            },
          ],
        },
        {
          id: 8,
          title: "تستر محصولات جدید به صورت رایگان",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 1,
              value: true,
            },
          ],
        },
        {
          id: 8,
          title: "توضیحات",
          values: [
            {
              plan_id: 1,
              value: "مشتریان با کمتر از 4000 امتیاز",
            },
            {
              plan_id: 1,
              value: "مشتریان با حداقل 9000 امتیاز",
            },
            {
              plan_id: 1,
              value: "مشتریان با حداقل 9000 امتیاز",
            },
            {
              plan_id: 1,
              value: "مشتریان با حداقل 15000 امتیاز",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("front/getClubTopTenFromServer");
  },
  computed: {
    topTen() {
      return this.$store.getters["front/getClubTopTen"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  methods: {
    changeTab(tab) {
      if (this.activeTab != tab) this.activeTab = tab;
    },
    clickHandler(data) {
      if (data == "next") {
        if (this.activePlan == this.levels.length - 1) {
          this.activePlan = 0;
        } else {
          this.activePlan++;
        }
      } else if (data == "prev") {
        if (this.activePlan == 0) {
          this.activePlan = this.levels.length - 1;
        } else {
          this.activePlan--;
        }
      }
    },
  },
};
</script>
<style scoped>
.font-900 {
  font-weight: 900;
}
.clubDetail {
  margin-top: 5rem;
}
.club-detail-first-paragraph {
  text-align: justify;
  line-height: 2;
  font-size: 18px;
  color: var(--footer-text-color);
}
.clubDetail-cards-content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5rem;
  width: 85%;
  margin: 3rem auto 1rem;
}

.clubDetail-card {
  width: 100%;
}
.clubDetail-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clubDetail-card-info {
  box-shadow: 2px 4px 74px 0px #aeaeae40;
  background-color: #fff;
  border-radius: 50px;
  padding: 1rem 0;
}
.clubDetail-card-info nav {
  padding: 1rem 2rem;
}
.clubDetail-card li {
  list-style-type: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.clubDetail-card-info img {
  width: 90px !important;
}
.clubDetail-card-info h3 {
  font-weight: 700;
  padding-right: 2rem;
}
.caption-clubDetail-card {
  text-align: center;
  padding: 2rem;
  padding-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}

.table1-score-points {
  width: 75%;
  text-align: center;
  margin: auto;
}
.table1-score-points td,
.table1-score-points th {
  padding: 8px;
}
.table2-score-points {
  width: 50%;
  text-align: center;
  margin: auto;
}
.table2-score-points td,
.table2-score-points th {
  padding: 8px;
}
.item-rules {
  list-style: decimal;
  list-style-position: inside;
}
#topTenCustomer .dir-ltr {
  direction: ltr;
}
#topTenCustomer .club-table-container,
.scoringTable {
  overflow-x: auto;
}
#topTenCustomer table tr td,
.scoringTable table tr td {
  white-space: nowrap;
  padding: 0.8rem 1.5rem;
}
#topTenCustomer table,
th,
td {
  /* border: 2px solid var(--color-main) !important; */
  border-collapse: separate;
  border-spacing: 5px 5px;
  text-align: center;
}
.scoringTable table,
th,
td {
  /* border: 2px solid var(--color-main) !important; */
  border-collapse: separate;
  border-spacing: 5px 5px;
  text-align: center;
}
#topTenCustomer table tr th,
.scoringTable table tr th {
  color: #ffffff;
}
#topTenCustomer table tr td,
.scoringTable table tr td {
  color: var(--footer-text-color);
  font-weight: 600;
  border: none !important;
}
#topTenCustomer table tr:first-child th:first-child,
.scoringTable table tr:first-child th:first-child {
  border-top-right-radius: 30px !important;
}
#topTenCustomer table tr:first-child th:last-child,
.scoringTable table tr:first-child th:last-child {
  border-top-left-radius: 30px !important;
}
#topTenCustomer table tr:last-child td:first-child,
.scoringTable table tr:last-child td:first-child {
  border-bottom-right-radius: 30px !important;
}
#topTenCustomer table tr:last-child td:last-child,
.scoringTable table tr:last-child td:last-child {
  border-bottom-left-radius: 30px !important;
}
#topTenCustomer table,
.scoringTable table {
  border: none !important;
}
#mobilePlanTable table tr td {
  white-space: wrap;
  padding: 0.5rem 1rem;
}
#topTenCustomer .club-image-holder {
  width: 50px;
}
.table-bg-color-green {
  background-color: rgb(21, 190, 149);
}
.table-bg-color-green-2 {
  background-color: rgba(21, 190, 149, 0.3);
}
.table-bg-color-pink {
  background-color: #eb6fa7;
}
.table-bg-color-pink-2 {
  background-color: rgba(235, 111, 167, 0.3);
}
@media screen and (max-width: 1250px) {
  .clubDetail-cards-content {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 3rem;

    width: 85%;
  }
  .table1-score-points {
    width: 95%;
  }
  .table2-score-points {
    width: 95%;
  }
  .mb-tab-30 {
    margin-bottom: 30px;
  }
  .mt-tab-30 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 500px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    width: 95%;
  }
  .clubDetail-card-info h3 {
    font-size: 22px;
    font-weight: 900;
  }
  .clubDetail-card p {
    font-weight: 900;
    font-size: 16px !important;
  }
  .clubDetail-card li {
    font-size: 14px;
  }

  .clubDetail h3 {
    font-size: 22px;
  }
  .clubDetail p {
    font-size: 14px;
  }

  .HowTo-score-points-clubDetail p {
    font-size: 16px;
  }
  .table2-score-points,
  .table1-score-points,
  .Scoring-rules li {
    font-size: 14px !important;
  }
}
</style>
<style>
.ClubDetail-body {
  background-image: unset;
}
</style>
