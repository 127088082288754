<template>
  <div id="scores">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        لیست امتیازات کسب شده باشگاه
      </h3>
    </div>
    <div v-if="clubData" class="h-100 w-100 mt-4">
      <b-table
        class="w-100"
        bordered
        striped
        v-if="scoresList && scoresList.data && scoresList.data.length"
        :fields="fieldsScores"
        :items="scoresList.data"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(score_value)="data">
          {{ data.item.score_value | price }}
        </template>
        <template v-slot:cell(bon_value)="data">
          {{ data.item.bon_value | price }}
        </template>
        <template v-slot:cell(cause_title)="data">
          {{ data.item.cause_title }}
        </template>
        <template v-slot:cell(product)="data">
          {{ data.item.product }}
        </template>
        <template v-slot:cell(date)="data">
          {{ data.item.date | date }}
        </template>
      </b-table>
      <div v-else class="alert alert-info" role="alert">موردی یافت نشد!</div>
      <div v-if="scoresList && scoresList.data && scoresList.data.length">
        <pagination
          :limit="1"
          :data="scoresList"
          @pagination-change-page="changePage"
          class="justify-content-center"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/parts/Front/components/Icon/ChevronLeft.vue";
import { BTable } from "bootstrap-vue";
import paginationMixin from "@/libraries/mixins/paginationMixin";
export default {
  name: "ClubScores",
  props: ["clubData", "clubColor"],
  mixins: [paginationMixin],
  components: {
    ChevronLeft,
    BTable,
  },
  data() {
    return {
      fieldsScores: [
        {
          label: "ردیف",
          key: "index",
        },
        {
          label: "امتیاز",
          key: "score_value",
        },
        {
          label: "بن",
          key: "bon_value",
        },
        {
          label: "عنوان",
          key: "cause_title",
        },
        {
          label: "محصول",
          key: "product",
        },
        {
          label: "تاریخ",
          key: "date",
        },
      ],
    };
  },
  watch: {
    "$route.query.page": {
      handler: function (value) {
        this.$store.dispatch("front/getClubScoresFromServer", value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch(
      "front/getClubScoresFromServer",
      this.$route.query.page ? $route.query.page : 1
    );
  },
  computed: {
    scoresList() {
      return this.$store.getters["front/getClubScores"];
    },
  },
};
</script>
<style scoped>
.club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
</style>
<style>
#scores table tbody tr td,
#scores table thead tr th {
  white-space: nowrap !important;
}
</style>
