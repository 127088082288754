<template>
  <header class="main-header padding-t-20">
    <section class="container">
      <!-- top section -->
      <div
        class="d-xl-flex align-items-center justify-content-md-between padding-b-10"
        style="border-bottom: 2px dashed var(--color-border)"
      >
        <Nav class="d-none d-xl-block"></Nav>
        <div class="d-flex align-items-center justify-content-between gap-10">
          <div class="d-flex align-items-center">
            <p class="m-0 font-weight-bold fontsize-mini">پشتیبانی فنی</p>
          </div>
          <div
            class="d-flex align-items-center gap-10 bg-color-eai border-r-25"
          >
            <a
              class="fontsize-mini text-light bg-color-theme padding-t-2 padding-b-2 padding-r-10 padding-l-10"
              style="border-radius: 25px 25px 25px 0"
              :href="`tel:${
                content?.settings?.site?.mobile_support.split('-')[0]
              }`"
              >{{ content?.settings?.site?.mobile_support.split("-")[0] }}</a
            >
            <a
              class="fontsize-mini text-dark padding-l-10"
              style="border-radius: 25px 25px 25px 0"
              :href="`tel:${
                content?.settings?.site?.mobile_support.split('-')[1]
              }`"
              >{{ content?.settings?.site?.mobile_support.split("-")[1] }}</a
            >
            <!-- <a
              class="fontsize-mini text-dark padding-l-10"
              :href="`mailto:${content?.settings?.site?.email}`"
              >{{ content?.settings?.site?.email }}</a
            > -->
          </div>
        </div>
      </div>
      <!-- bottom section -->
      <div
        class="padding-t-10"
        id="fixedHeaderSection"
      >
        <div class="row d-flex align-items-center justify-content-between container mx-auto">
          <!-- hamburger menu -->
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <NavButton class="d-block d-xl-none" @click="allCategoriesClose()" />
              <AtlasLogo />
            </div>
            <div class="d-none d-xl-block mr-4">
              <SearchButton class="bg-none" />
            </div>
          </div>
  
          <div
            class="d-flex align-items-center"
            :class="
              isLogin
                ? 'justify-content-between gap-10'
                : 'justify-content-end  gap-20'
            "
          >
            <!-- login -->
            <div class="d-block">
              <!-- اگر لاگین نبود ایکن ورود نمایش داده شود -->
              <button
                v-if="!isLogin"
                type="button"
                class="d-flex flex-column align-items-center bg-none"
                data-toggle="modal"
                data-target="#loginModal"
                style="gap: 8px"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.5789"
                    cy="7.27803"
                    r="4.77803"
                    stroke="#F83D84"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
                    stroke="#F83D84"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="font-s-12 text-color-theme">ورود / عضویت</span>
              </button>
              <template v-if="isLogin">
                <LoginUser :atlasTheme="false" />
              </template>
            </div>
            <!-- cart -->
            <CartButton />
            <!-- notification -->
            <div
              v-if="notifications && notifications.items && notifications.items.length"
              class="d-flex align-items-center flex-column">
              <div
                class="d-inline-block"
                :class="
                  notifications && notifications.items && notifications.items.length
                    ? 'ml-4'
                    : 'ml-1'
                "
              >
                <HeaderNotification
                  v-if="notifications"
                  v-show="
                    notifications &&
                    notifications.items &&
                    notifications.items.length
                  "
                  :value="notifications"
                />
              </div>
              <span class="font-s-12 text-color-theme pt-1">پیام ها</span>
            </div>
          </div>
        </div>
      </div>
      <!-- search box mobile -->
      <SearchButton class="bg-none d-flex d-xl-none mt-4 mt-xl-0" />
    </section>

    <MainSearch></MainSearch>
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <RespMenu ref="respMenu"></RespMenu>
    <div id="header_bg_img" :class="{'ClubDetail-header_bg_img': $route.name=='ClubDetail'}">
      <img class="w-100" src="../../../../assets/images/headerbg.png" alt=""  />
    </div>
  </header>
</template>

<script>
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
import Nav from "@/parts/Front/components/Nav";
// import ProductCategories from "@/parts/Front/components/ProductCategories";
import RespMenu from "@/parts/Front/components/RespMenu";
import MainSearch from "@/parts/Front/components/MainSearch";
import LoginUser from "@/parts/Front/components/LoginUser";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import CartLists from "@/parts/Front/components/CartLists";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import CartButton from "@/parts/Front/components/cart/CartButton";
import SearchButton from "../header/SearchButton";
import NavButton from "@/parts/Front/components/shared/NavButton";
export default {
  name: "Header",
  components: {
    NavButton,
    SearchButton,
    AtlasLogo,
    HeaderNotification,
    Nav,
    LoginUser,
    // ProductCategories,
    RespMenu,
    MainSearch,
    RespLoginModal,
    CartLists,
    CartButton,
  },
  inject: ["mediaQueries"],
  methods: {
    allCategoriesClose() {
      this.$refs.respMenu.allCategoriesToggle();
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout = true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout = false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              delete this.$axios.defaults.headers.common["Authorization"];
              this.$root.deleteCookie("Authorization");
              this.$store.commit("front/setLoginStatus", false);
            });
          }
        });
    },
    isScroll(){
      if(scrollY>150) {
        document.getElementById('fixedHeaderSection').classList.add('fixedHeader')
      }else {
        document.getElementById('fixedHeaderSection').classList.remove('fixedHeader')
      }
    }
  },
  mounted() {
    if (this.isLogin) {
      this.$store.dispatch("front/getNotficationsFromServer");
    }
    window.addEventListener('scroll', this.isScroll)
  },
  computed: {
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
    content() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  data() {
    return {
      disabledlLogout: false,
    };
  },
};
</script>

<style scoped>
#header_bg_img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 100%;
}
.ClubDetail-header_bg_img img{
  height: 400px;
}
.main-header {
  /* background-color: rgb(250, 250, 250); */
  /* padding-bottom: 150px; */
  padding-bottom: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
div#logoBack {
  position: absolute;
  background: white;
  width: 170px;
  height: 80px;
  z-index: -1;
  border-radius: 0 0 20px 20px;
  top: -1rem;
  right: -15px;
}
.fixedHeader {
  position: fixed;
  top: -50px;
 background: #ffffff;
  right: 0;
  left: 0;
  z-index: 1049;
  padding-bottom: 10px;
  animation: fixedHeaderAnimation 0.5s linear forwards;
  
}
@keyframes fixedHeaderAnimation {
  0%{
    top: -50px;
  }
  100% {
    top: 0;
  }
}

@media screen and (min-width:770px) {
  .fixedHeader > div {
    width: 90%;
    margin-inline: auto;
  }
}
@media screen and (max-width: 769px) {
  #header_bg_img img {
    height: 100%;
  }
  #header_bg_img.ClubDetail-header_bg_img img{
    height: 410px ;
  }
}
@media screen and (max-width: 1250px) {
  .ClubDetail-header_bg_img img{
    height: 460px;
  }
}
@media screen and (max-width: 1000px) {
  .ClubDetail-header_bg_img img{
    height: 410px;
  }
}
</style>
