<template>
  <footer v-if="content && content.settings && content.settings.site">
    <section class="container margin-t-100">
      <div class="footer-grid-container">
        <!-- منو آموزش -->
        <div>
          <h6 class="text-color-444 fontsize-medium font-weight-bold">آموزش</h6>
          <nav>
            <ul
              class="nav d-flex align-items-start gap-10 flex-column fontsize13"
            >
              <template v-if="!loading">
                <li
                  v-for="item in tutorials"
                  :key="item.id"
                  class="nav-item fontsize-mini"
                >
                  <router-link class="footer-text-color" :to="item.link">{{
                    item.title
                  }}</router-link>
                </li>
              </template>
              <template v-else>
                <li v-for="index in 5" :key="index" class="nav-item">
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
        <!-- منو خدمات -->
        <div>
          <h6 class="text-color-444 fontsize-medium font-weight-bold">خدمات</h6>
          <nav>
            <ul
              class="nav d-flex align-items-start gap-10 flex-column fontsize13"
            >
              <template v-if="!loading">
                <li
                  v-for="item in services"
                  :key="item.id"
                  class="nav-item fontsize-mini"
                >
                  <a
                    class="footer-text-color"
                    :href="item.link"
                    v-if="item.externalLink"
                    >{{ item.title }}</a
                  >
                  <router-link
                    v-else
                    class="footer-text-color"
                    :to="item.link"
                    >{{ item.title }}</router-link
                  >
                </li>
              </template>
              <template v-else>
                <li v-for="index in 5" :key="index" class="nav-item">
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
        <!-- منو ضمانت -->
        <div>
          <h6 class="text-color-444 fontsize-medium font-weight-bold">ضمانت</h6>
          <nav>
            <ul
              class="nav d-flex align-items-start gap-10 flex-column fontsize13"
            >
              <template v-if="!loading">
                <li
                  v-for="item in guarantee"
                  :key="item.id"
                  class="nav-item fontsize-mini"
                >
                  <router-link class="footer-text-color" :to="item.link">{{
                    item.title
                  }}</router-link>
                </li>
              </template>
              <template v-else>
                <li v-for="index in 5" :key="index" class="nav-item">
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template>
            </ul>
          </nav>
        </div>
        <!-- contact us -->
        <div>
          <h6 class="text-color-444 fontsize-medium font-weight-bold">
            ارتباط با ما
          </h6>
          <div class="d-flex flex-column align-items-start gap-10">
            <!-- mobile -->
            <div>
              <div>
                <template v-if="!loading">
                  <span class="ml-1">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.20431 0.00436162C4.32696 -0.0132274 4.45022 0.0230506 4.54828 0.101096C4.64904 0.180241 4.71207 0.294564 4.72577 0.422626C4.75475 0.681494 4.56797 0.915635 4.30947 0.944768C2.52654 1.14373 1.14076 2.53262 0.940281 4.3211C0.913441 4.56071 0.712416 4.74155 0.472506 4.74155C0.454411 4.74155 0.436866 4.74045 0.418825 4.73825C0.29337 4.7245 0.181666 4.66241 0.102741 4.56346C0.0244207 4.46457 -0.0111647 4.34143 0.00308037 4.21557C0.252836 1.9841 1.97989 0.252241 4.20431 0.00436162ZM4.17004 1.91947C4.4319 1.8711 4.67236 2.03818 4.72219 2.29376C4.77202 2.54933 4.60443 2.79886 4.34973 2.84888C3.58286 2.99837 2.99074 3.59197 2.8407 4.36144C2.79796 4.58293 2.60293 4.74452 2.37837 4.74452C2.34823 4.74452 2.31814 4.74177 2.28745 4.73627C2.03275 4.68517 1.86516 4.43619 1.91499 4.18007C2.13901 3.0308 3.02362 2.14316 4.17004 1.91947ZM3.29995 7.19977C3.05168 7.05661 2.77118 6.89491 2.41742 6.97015C2.09699 7.03774 1.0048 7.92429 0.705706 8.23157C0.50963 8.43271 0.40062 8.64044 0.383625 8.84823C0.354091 9.66333 1.46163 10.5938 1.6632 10.7098C1.93105 10.9027 2.24268 11 2.5927 11C2.95037 11 3.34917 10.8983 3.78406 10.6955C6.14372 9.71058 9.74846 6.17705 10.7015 3.83567C11.097 2.96397 11.0997 2.24506 10.7081 1.70479C10.552 1.45141 9.66192 0.392288 8.86494 0.42581C8.65297 0.443949 8.44702 0.552778 8.2449 0.75064C7.93926 1.05018 7.07491 2.14558 7.00808 2.46711C6.93414 2.82437 7.09627 3.10852 7.24033 3.35805C7.27105 3.41169 7.31245 3.47534 7.35781 3.54508C7.52994 3.80976 7.75905 4.16204 7.67798 4.38694C7.09682 5.81378 5.75432 7.05864 4.32685 7.64346C4.10614 7.7237 3.75403 7.49293 3.49019 7.32006C3.4215 7.27501 3.35874 7.23387 3.30589 7.20318L3.29995 7.19977Z"
                        fill="#4F4F4F"
                      />
                    </svg>
                  </span>
                  <span class="fontsize-mini text-color-444">{{
                    content.settings.site.mobile
                  }}</span>
                </template>
                <template v-else>
                  <span
                    ><b-skeleton width="105px" class="d-inline-block mr-2"
                  /></span>
                </template>
              </div>
            </div>
            <!-- email -->
            <div>
              <div>
                <template v-if="!loading">
                  <span class="ml-1">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.17594 0.625C8.90232 0.625 9.60107 0.912083 10.1151 1.42721C10.6297 1.94125 10.9173 2.63458 10.9173 3.36042V7.63958C10.9173 9.15083 9.68773 10.375 8.17594 10.375H2.82482C1.31303 10.375 0.0839844 9.15083 0.0839844 7.63958V3.36042C0.0839844 1.84917 1.30761 0.625 2.82482 0.625H8.17594ZM9.03773 4.1675L9.08107 4.12417C9.21052 3.96708 9.21052 3.73958 9.07511 3.5825C8.99982 3.50179 8.89636 3.4525 8.78857 3.44167C8.67482 3.43571 8.56648 3.47417 8.48469 3.55L6.04232 5.5C5.72815 5.76054 5.27803 5.76054 4.95898 5.5L2.52148 3.55C2.35303 3.42542 2.12011 3.44167 1.97982 3.58792C1.83357 3.73417 1.81732 3.96708 1.94136 4.12958L2.01232 4.2L4.4769 6.12292C4.78023 6.36125 5.14803 6.49125 5.53315 6.49125C5.91719 6.49125 6.29148 6.36125 6.59427 6.12292L9.03773 4.1675Z"
                        fill="#4F4F4F"
                      />
                    </svg>
                  </span>
                  <span class="fontsize-mini text-color-444">{{
                    content.settings.site.email
                  }}</span>
                </template>
                <template v-else>
                  <span
                    ><b-skeleton width="105px" class="d-inline-block mr-2"
                  /></span>
                </template>
              </div>
            </div>
            <!-- support -->
            <div>
              <div>
                <template v-if="!loading">
                  <span><SupportIcon /></span>
                  <span class="fontsize-mini text-color-444">{{
                    content.settings.site.mobile_support
                  }}</span>
                </template>
                <template v-else>
                  <span
                    ><b-skeleton width="105px" class="d-inline-block mr-2"
                  /></span>
                </template>
              </div>
            </div>
            <!-- address -->
            <div>
              <div>
                <span class="ml-1">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.895508 4.58873C0.895508 2.09711 2.97761 0.083252 5.4961 0.083252C8.02173 0.083252 10.1038 2.09711 10.1038 4.58873C10.1038 5.84426 9.64722 7.00987 8.89565 7.99787C8.06652 9.08765 7.04462 10.0372 5.89428 10.7825C5.63103 10.9547 5.39345 10.9677 5.10447 10.7825C3.94766 10.0372 2.92572 9.08765 2.1037 7.99787C1.35158 7.00987 0.895508 5.84426 0.895508 4.58873ZM3.97988 4.72902C3.97988 5.56367 4.66101 6.22017 5.4961 6.22017C6.33178 6.22017 7.01948 5.56367 7.01948 4.72902C7.01948 3.90081 6.33178 3.21237 5.4961 3.21237C4.66101 3.21237 3.97988 3.90081 3.97988 4.72902Z"
                      fill="#4F4F4F"
                    />
                  </svg>
                </span>
                <address
                  v-if="!loading"
                  class="fontsize-mini text-color-444 text-justify d-inline-block"
                >
                  {{ content.settings.site.address }}
                </address>
                <b-skeleton v-else width="270px" />
              </div>
            </div>
            <!-- social media -->
            <div>
              <SocialNetworks />
            </div>
          </div>
        </div>
      </div>
      <!-- logo & desc -->
    </section>
    <section class="footer-desc-container footer-outer-container">
      <div>
        <div class="container py-3">
          <img
            style="float: right"
            class="ml-2"
            width="120"
            src="@/assets/images/logo.png"
            alt=""
          />
          <p
            v-if="content"
            class="text-color-666 line-height2"
            style="text-align: justify"
          >
            {{ content.settings.site.footer_about }}
          </p>
        </div>
        <div class="footer-dashed-line"></div>
        <!-- copyright & socila -->
        <div
          class="container d-flex align-items-center flex-wrap gap-10 flex-w-wrap justify-content-between padding-t-20 padding-b-20"
        >
          <div class="d-flex align-items-center gap-10 flex-wrap">
            <p>
              <svg
                class="d-sm-inline-block d-none ml-1"
                height="20"
                viewBox="0 0 512 512"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#666"
                  d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
                />
              </svg>
              تمامی حقوق این سایت مربوط به
              <span class="font-weight-bold">فروشگاه بندیتو</span> می باشد
            </p>
            <p>
              طراحی شده:
              <a
                style="color: var(--color-theme4)"
                target="_blank"
                href="https://shetabit.com/"
                >گروه شتاب</a
              >
            </p>
          </div>
          <div class="d-flex align-items-center gap-20">
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=325870&amp;Code=cWzNpT3wulmDJpmWKe96"
              ><img
                referrerpolicy="origin"
                src="https://Trustseal.eNamad.ir/logo.aspx?id=325870&amp;Code=cWzNpT3wulmDJpmWKe96"
                alt=""
                style="cursor: pointer"
                id="cWzNpT3wulmDJpmWKe96"
            /></a>

            <img
              referrerpolicy="origin"
              id="rgvjwlaowlaoapfurgvjesgt"
              style="cursor: pointer"
              onclick='window.open("https://logo.samandehi.ir/Verify.aspx?id=344530&p=xlaoaodsaodsdshwxlaoobpd", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")'
              alt="logo-samandehi"
              src="https://logo.samandehi.ir/logo.aspx?id=344530&p=qftishwlshwlujynqftilyma"
            />
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
import { BSkeleton } from "bootstrap-vue";
import SocialNetworks from "./SocialNetworks";
import MailIcon from "@/parts/Front/components/Icon/MailIcon";
import PhoneIcon from "@/parts/Front/components/Icon/PhoneIcon";
import SupportIcon from "@/parts/Front/components/Icon/SupportIcon";
import LocationIcon from "@/parts/Front/components/Icon/LocationIcon";

export default {
  name: "Footer",
  components: {
    SocialNetworks,
    DynamicLinker,
    BSkeleton,
    MailIcon,
    PhoneIcon,
    SupportIcon,
    LocationIcon,
  },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      email: null,
      disabled: false,
      phoneNumber: null,
      tutorials: [
        {
          id: 1,
          title: "نحوه ثبت سفارش",
          link: "/weblog-details/24/%D8%A2%D9%85%D9%88%D8%B2%D8%B4-%D8%AE%D8%B1%DB%8C%D8%AF-%D8%A7%D8%B2-%D8%B3%D8%A7%DB%8C%D8%AA",
        },
        {
          id: 2,
          title: "نحوه لغو سفارش",
          link: "/weblog-details/24/%D8%A2%D9%85%D9%88%D8%B2%D8%B4-%D8%AE%D8%B1%DB%8C%D8%AF-%D8%A7%D8%B2-%D8%B3%D8%A7%DB%8C%D8%AA",
        },
        {
          id: 3,
          title: "نحوه ویرایش سفارش",
          link: "/weblog-details/24/%D8%A2%D9%85%D9%88%D8%B2%D8%B4-%D8%AE%D8%B1%DB%8C%D8%AF-%D8%A7%D8%B2-%D8%B3%D8%A7%DB%8C%D8%AA",
        },
        {
          id: 4,
          title: "کدرهگیری پستی",
          link: "/post-tracking",
        },
      ],
      services: [
        {
          id: 1,
          title: "باشگاه مشتریان",
          link: "/club-detail",
          externalLink: false,
        },
        {
          id: 2,
          title: "مشاوره رایگان",
          link: "https://www.goftino.com/c/acGiCd",
          externalLink: true,
        },
        {
          id: 3,
          title: "ارسال رایگان",
          link: "/pages/5",
          externalLink: false,
        },
        {
          id: 4,
          title: "پشتیبانی فنی",
          link: "/contact",
          externalLink: false,
        },
      ],
      guarantee: [
        {
          id: 1,
          title: "ضمانت بازگشت کالا",
          link: "/pages/7",
        },
        {
          id: 2,
          title: "ضمانت اصل بودن کالا",
          link: "/pages/8",
        },
        {
          id: 3,
          title: "سوالات متداول",
          link: "/faq",
        },
      ],
    };
  },
  methods: {
    findUrl,
    // sendPhone() {
    //   if (!this.phoneNumber) {
    //     return window.swal({
    //       title: "خطا!",
    //       text: "لطفا شماره تماس خود را وارد کنید",
    //       icon: "warning",
    //       timer: 2000,
    //     });
    //   }
    //   const fromData = new FormData();
    //   fromData.append("phone_number", this.phoneNumber);
    //   this.disabled = true;
    //   this.$axios
    //     .post("/front/newsletters", fromData)
    //     .then((response) => {
    //       this.phoneNumber = null;
    //       window.swal({
    //         title: "تبریک",
    //         text: "شماره شما برای عضویت در خبرنامه ما با موفقیت ثبت شد",
    //         icon: "success",
    //         timer: 2000,
    //       });
    //     })
    //     .catch((err) => {
    //       this.$root.notify(err);
    //     })
    //     .finally(() => {
    //       this.disabled = false;
    //     });
    // },
    // sendEmail() {
    //   if (!this.email) {
    //     return window.swal({
    //       title: "خطا!",
    //       text: "لطفا ایمیل خود را وارد کنید",
    //       icon: "warning",
    //       timer: 2000,
    //     });
    //   } else if (!this.validEmail(this.email)) {
    //     return window.swal({
    //       title: "خطا!",
    //       text: "لطفا فرمت ایمیل خود را به درستی وارد کنید",
    //       icon: "warning",
    //       timer: 2000,
    //     });
    //   }
    //   const fromData = new FormData();
    //   fromData.append("email", this.email);
    //   this.disabled = true;
    //   this.$axios.post("/front/newsletters", fromData).then((response) => {
    //     this.disabled = false;
    //     this.email = null;
    //     window.swal({
    //       title: "تبریک",
    //       text: "ایمیل شما برای عضویت در خبرنامه ما ثبت سیستم شد",
    //       icon: "success",
    //       timer: 2000,
    //     });
    //   });
    // },
    // validEmail: function (email) {
    //   var re =
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return re.test(email);
    // },
  },
};
</script>

<style scoped>
ul li {
  list-style-type: disc;
  list-style-position: inside;
  margin-inline-start: 5px;
}
ul li::marker {
  color: #999999b4;
  font-size: medium;
}
.footer-grid-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}
.imgSkeleton {
  height: 70px;
}
.get-subscribe form {
  height: 40px;
  border: 2px solid var(--color-da);
  transition: 0.3s;
}
p {
  font-size: 14px;
}
.footer-desc-container {
  background-color: #fafafa;
  border-top: 5px solid #ebebeb;
  padding-block: 1rem;
  margin-top: 1rem;
}
.footer-dashed-line {
  border-top: 2px dashed #e4e4e4;
}
@media (max-width: 767px) {
  .footer-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .footer-grid-container {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}
</style>
