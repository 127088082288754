<template>
  <div>
    <flip-countdown :deadline="deadline"></flip-countdown>
  </div>
</template>
<script>
import FlipCountdown from "vue2-flip-countdown";

export default {
  props: ["deadline"],
  components: {
    FlipCountdown,
  },
};
</script>
<style>
.flip-clock {
  direction: ltr !important;
  padding: 1rem 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.flip-card {
  /* background-image: url("../../../../assets/images/timerbg.png"); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  background-color: #ffffff;
  border-radius: 50%;
  background-size: 20px;
  padding-bottom: 0 !important;
  width: 26px !important;
  height: 26px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  color: var(--color-themeRed) !important;
  background: transparent !important;
  padding: 0 !important;
  width: none !important;
  height: none !important;
}
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
  color: var(--color-themeRed) !important;
  background: transparent !important;
  border-top: none !important;
}
.flip .flip-card__bottom,
.flip .flip-card__bottom-4digits {
  animation: none !important;
}
.flip .flip-card__back::before,
.flip .flip-card__back-4digits::before {
  animation: none !important;
}
.flip-card__back,
.flip-card__back-4digits {
  display: none !important;
}
.flip-card__back-bottom,
.flip-card__back-bottom-4digits {
  display: none !important;
}
.flip-clock__slot {
  display: none !important;
}
.flip-card__bottom,
.flip-card__back,
.flip-card__back-bottom {
  display: none !important;
  padding: 0 !important;
  position: static !important;
}
@media (min-width: 1000px) {
  .flip-card {
    font-size: 1.1rem !important;
    font-weight: 100 !important;
  }
  .flip-clock__slot {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 999px) {
  .flip-card {
    font-size: 1rem !important;
    font-weight: 100 !important;
  }
  .flip-clock__slot {
    font-size: 0.8rem !important;
  }
  
}
</style>
