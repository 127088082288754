<template>
  <div id="homeSliderSwiper" class="mb-3 mb-xl-0">
    <b-skeleton-img v-if="!sliders"></b-skeleton-img>
    <section
      v-else
      class="home-slider border-r-25"
    >
      <div class="swiper-container h-100">
        <div v-if="sliders" class="swiper-wrapper">
          <div
            v-for="slider in sliders"
            :key="slider.id"
            class="swiper-slide border-r-25"
          >
            <DynamicLinker class="top-slider-img" :item="slider">
              <img
                :src="slider.image.url"
                class="w-100 h-100 border-r-25"
                :alt="slider.title"
              />
            </DynamicLinker>
          </div>
        </div>
        <!-- Add Pagination -->
        <!-- Add Arrows -->
      </div>
      <div class="d-flex align-items-center justify-content-center mt-3">
        <div v-if="sliders && sliders.length" class="d-md-none swiper-pagination d-flex gap-5"></div>
      </div>
      <div class="d-md-block d-none swiper-button-next text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            fill="#999"
          />
        </svg>
      </div>
      <div class="d-md-block d-none swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            fill="#999"
          />
        </svg>
      </div>
    </section>
  </div>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min.js";
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
import { BSkeletonImg } from "bootstrap-vue";
export default {
  name: "homeSlider",
  components: {
    DynamicLinker,
    BSkeletonImg,
  },
  watch: {
    sliders() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
  },
  inject: ["mediaQueries"],
  methods: {
    findUrl,
    getSliderCustomFields(slider) {
      return JSON.parse(slider.custom_fields);
    },
    // onResize() {
    //   let topSliderH = document.querySelector(".top-slider");
    //   let bodyWidth = document.body.offsetWidth;
    //   if (topSliderH) {
    //     if (bodyWidth < 767) {
    //       topSliderH.style.height = bodyWidth / 2 + "px";
    //     } else {
    //       topSliderH.style.height = 500 + "px";
    //     }
    //   }
    // },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}

        this.slider = new Swiper("#homeSliderSwiper .home-slider .swiper-container", {
          spaceBetween: 10,
          //   centeredSlides: true,
          speed: 1500,
          loop: false,
          autoplay: true,
          pagination: {
            el: "#homeSliderSwiper .home-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: "#homeSliderSwiper .home-slider .swiper-button-next",
            prevEl: "#homeSliderSwiper .home-slider .swiper-button-prev",
          },
        });
      });
      // this.$nextTick(() => {
      //   this.onResize();
      // });
    },
  },
  mounted() {
    // ست کردن تنظیمات  برای اسکرول صفحه در برابر رسپانسیو
    // window.removeEventListener("resize", this.onResize);
    // window.addEventListener("resize", this.onResize);
    this.$store.dispatch("front/getIndexSlider");

    this.initHeaderSwiper();
    //CHange height of top-slider
    // const topSliderH = document.querySelector(".top-slider");
    // const bodyWidth = document.body.offsetWidth;
    // if (topSliderH) {
    //   if (bodyWidth < 767) {
    //     topSliderH.style.height = bodyWidth / 1.6 + "px";
    //   } else {
    //     topSliderH.style.height = 500 + "px";
    //   }
    // }

    // window.removeEventListener("resize", this.onResize);
    // window.addEventListener("resize", this.onResize);
  },
  computed: {
    // دریافت دیتا از سمت ویوایکس
    sliders() {
      let slidersData = this.$store.getters["front/getSliderIndex"];
      if (slidersData) {
        let sliders;
        if (this.mediaQueries.medium) {
          sliders = slidersData.sliders.filter((s) =>
            s.group.includes("mobile")
          );
        } else {
          sliders = slidersData.sliders.filter(
            (s) => !s.group.includes("mobile")
          );
        }
        // sliders.forEach((slider) => {
        //   try {
        //     slider.custom_fields = this.getSliderCustomFields(slider);
        //   } catch (e) {}
        // });
        return sliders;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.home-slider {
  aspect-ratio: 2.35/1;
}
</style>
<style>
.home-slider .swiper-button-next::after {
  display: none;
}
.home-slider .swiper-button-prev::after {
  display: none;
}
</style>
