<template>
  <div class="swiper">
    <div class="swiper-container h-100">
      <div v-if="sliders" class="swiper-wrapper">
        <Product
          class="swiper-slide"
          v-for="(product, index) in sliders"
          :key="index"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Product from "@/parts/Front/components/product/CountDownProduct.vue";
import Swiper from "swiper/swiper-bundle.min.js";
let breakpoints = {
  320: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  420: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  540: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
};
export default {
  name: "SingleAmazingProduct",
  components: {
    Product,
  },
  data() {
    return {
      swiper: null,
      readyProducts: null,
    };
  },
  watch: {
    sliders() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
  },
  mounted() {
    this.setReadyProducts();
    this.initHeaderSwiper();
  },
  computed: {
    sliders() {
      return this.readyProducts;
    },
  },
  methods: {
    async setReadyProducts() {
      this.readyProducts = null;
      let res = await this.$store.dispatch(
        "front/getIndexPageContent",
        "most-timed-discounts"
      );
      this.readyProducts = res
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}
        this.slider = new Swiper(".swiper .swiper-container", {
          spaceBetween: 10,
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 8000,
            disableOnInteraction: false,
          },
          breakpoints,
          pagination: {
            el: ".swiper .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper .swiper-button-next",
            prevEl: ".swiper .swiper-button-prev",
          },
        });
      });
    },
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>
<style scoped></style>
