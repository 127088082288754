<template>
  <article
    class="in-product position-relative box-shaddow20 bg-white border-r-25 pb-4"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <template v-if="product && product.major_final_price">
      <DiscountTag :final-price="product.major_final_price" />
    </template>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <a @click.prevent="cachProduct" :href="to.href" class="text-center">
      <!--      <div class="recommended-products-img text-center" :style="!product ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)' : ''">-->
      <div class="recommended-products-img text-center">
        <img
          loading="lazy"
          v-if="product && mainImage"
          class=""
          :src="`https://api.benedito.ir/storage/${product.images[0]}`"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
      </div>

      <div v-if="product" class="text-center position-relative">
        <div
          class="product-rate d-flex align-items-center justify-content-center"
        >
          <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
          <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
        </div>
        <h1
          v-if="$route.path != '/'"
          class="text-color-444 weight-bold fontsize-medium mt-1 mb-0"
        >
          {{ product.title.substr(0, 33) }}
          {{ product.title.length > 33 ? "..." : "" }}
        </h1>
        <h2 v-else class="text-color-444 weight-bold fontsize-medium mt-1 mb-0">
          {{ product.title.substr(0, 33) }}
          {{ product.title.length > 33 ? "..." : "" }}
        </h2>
        <div v-if="product.status == 'available'">
          <span class="text-color-themeGreen fontsize-medium">
            {{ mainPrice | price }}
          </span>
          <span class="text-color-666 fontsize-small pr-1">تومان</span>
          <span v-if="discountAmount != 0" class="pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <span class="line-through text-color-999 fontsize-small">{{
              (mainPrice + discountAmount) | price
            }}</span>
          </span>
        </div>
        <div v-else class="hide-by-hover ">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-color-themeRed fontsize14"
          >
            تمام شد
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3 px-4 px-sm-5">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </a>
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";

import DiscountTag from "./DiscountTag";
import Tick from "@/parts/Front/components/shared/Tick";
export default {
  name: "Product",
  components: { Tick, DiscountTag, BFormRating, BSkeleton },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    cachProduct() {
      let product = this.product;
      product.isPreview = true;
      this.$store.commit("front/setProductLinkedInfo", product);
      this.$router.push(this.to.href);
    },
  },
  computed: {
    to() {
      return this.$router.resolve({
        name: "product.detail",
        params: { id: this.product.id, slug: this.product.slug },
      });
    },
    mainPrice() {
      return this.product ? this.product.major_final_price.amount : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },
    mainImage() {
      return this.product && this.product.images.length ? true : false;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}

.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}

.compare-btn {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}

.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}

.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}

.py-25 {
  padding: 14px 0;
}
</style>
