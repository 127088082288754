<template>
  <div id="qunatityBox">
    <!-- <button @click="handlerQunatity('+')">+</button>
    <span>{{product.wanted_quantity}}</span> -->
    <treeselect
        class="d-flex d-lg-none"
        :searchable="!mediaQueries.mobileSize"
        :clearable="false"
        :options="options"
        noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
        placeholder="تعداد"
        v-model="product.wanted_quantity"
    />
    <!-- <button  @click="handlerQunatity('-')">-</button> -->
    <div
      class="d-none d-lg-flex align-items-center border border-r-25 px-2 text-grey-8b font-w-900 fontsize-medium w-s-nowrap"
    >
      <span>{{ product.wanted_quantity }}</span>
      <div class="d-flex align-items-center flex-column">
        <svg
          class="cursor-pointer"
          @click="handlerQunatity('+')"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.24652 4.2352C6.21318 4.26767 6.07066 4.39031 5.95341 4.50451C5.21606 5.17411 4.00916 6.92095 3.64077 7.8352C3.58158 7.97405 3.45629 8.3251 3.44824 8.51266C3.44824 8.69238 3.48962 8.8637 3.57353 9.02719C3.69077 9.23098 3.87525 9.39446 4.09307 9.48404C4.24422 9.54171 4.69652 9.63129 4.70456 9.63129C5.19939 9.72087 6.00341 9.77014 6.89192 9.77014C7.73847 9.77014 8.50974 9.72087 9.01204 9.64753C9.02008 9.63913 9.58215 9.54955 9.77468 9.45157C10.1264 9.27185 10.3448 8.92081 10.3448 8.54514V8.51266C10.3362 8.26799 10.1178 7.75348 10.1097 7.75348C9.74077 6.88848 8.59307 5.18198 7.83043 4.49612C7.83043 4.49612 7.63445 4.30296 7.51204 4.21899C7.33617 4.08796 7.11836 4.02302 6.90054 4.02302C6.65744 4.02302 6.431 4.09635 6.24652 4.2352Z"
            fill="#B9B9B9"
          />
        </svg>
        <svg
          class="cursor-pointer"
          @click="handlerQunatity('-')"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.24652 9.55789C6.21318 9.52542 6.07066 9.40278 5.95341 9.28858C5.21606 8.61898 4.00916 6.87214 3.64077 5.95789C3.58158 5.81904 3.45629 5.46799 3.44824 5.28043C3.44824 5.10071 3.48962 4.92939 3.57353 4.7659C3.69077 4.56211 3.87525 4.39863 4.09307 4.30905C4.24422 4.25138 4.69652 4.1618 4.70456 4.1618C5.19939 4.07222 6.00341 4.02295 6.89192 4.02295C7.73847 4.02295 8.50974 4.07222 9.01204 4.14556C9.02008 4.15396 9.58215 4.24354 9.77468 4.34152C10.1264 4.52124 10.3448 4.87228 10.3448 5.24795V5.28043C10.3362 5.5251 10.1178 6.03962 10.1097 6.03962C9.74077 6.90462 8.59307 8.61111 7.83043 9.29697C7.83043 9.29697 7.63445 9.49013 7.51204 9.5741C7.33617 9.70513 7.11836 9.77008 6.90054 9.77008C6.65744 9.77008 6.431 9.69674 6.24652 9.55789Z"
            fill="#B9B9B9"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  inject: ["mediaQueries"],
  name: "ProductSelectQuantity",
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value,
    };
  },
  methods: {
    handlerQunatity(flag) {
      if (flag == "+") {
        this.product.wanted_quantity += 1;
      } else if (this.product.wanted_quantity != 1) {
        this.product.wanted_quantity -= 1;
      }
      this.$emit("input", this.product);
    },
  },
  watch: {
    value(newVal) {
      this.product = newVal;
    },
    product(newVal) {
      console.log(newVal);
      this.$emit("input", newVal);
    },
  },
  components: { Treeselect },
  computed: {
    options() {
      let options = [];
      let max = Math.min(this.product.getMaxQuantity(), 300);
      for (let i = 1; i <= max; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },
  },
};
</script>

<style scoped>
div#qunatityBox span {
  /* background: white; */
  padding: 5px 10px;
  /* box-shadow: 0 0 4px #1213; */
  /* width: 58px; */
  /* height: 40px; */
  text-align: center;
  /* line-height: 40px; */
  font-size: 20px;
}

div#qunatityBox {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}
div#qunatityBox button:last-child {
  /* background: red; */
  /* color: white; */
  color: #000000;
}
div#qunatityBox button:first-child {
  /* background: green; */
  /* color: white; */
  color: #000000;
}
div#qunatityBox button {
  width: 40px;
  height: 40px;
  font-size: 30px;
  border-radius: 5px;
  transform: scale(0.8);
}
</style>
<style>
div#qunatityBox .vue-treeselect__control {
  border-radius: 20px !important;
}
</style>
