<template>
  <main id="clubLanding">
    <section>
      <h1>
        <svg
          width="31"
          height="31"
          viewBox="0 -0.5 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="11.9141"
            y="15.4102"
            width="1.58679"
            height="5.59554"
            fill="url(#paint0_linear_103_1804)"
          />
          <path
            d="M5.89393 3.5979H1C1 7.393 1.29104 9.57603 6.69821 9.57603"
            stroke="#FFDD66"
            stroke-width="2"
          />
          <path
            d="M19.8636 8.56848C19.8636 12.5379 16.6458 15.7557 12.6764 15.7557C8.70707 15.7557 5.48926 12.5379 5.48926 8.56848C5.48926 4.59911 8.70707 1.3813 12.6764 1.3813C16.6458 1.3813 19.8636 4.59911 19.8636 8.56848Z"
            fill="#FFDD66"
          />
          <path
            d="M12.6764 20.7262C9.74579 20.7262 7.37002 21.5833 7.37002 22.6406H17.9829C17.9829 21.5833 15.6071 20.7262 12.6764 20.7262Z"
            fill="#FFDD66"
          />
          <path d="M5.48926 0H19.8636V8.23263H5.48926V0Z" fill="#FFDD66" />
          <path
            d="M17.9829 23.01H7.37002V22.607H17.9829V23.01Z"
            fill="#FFDD66"
          />
          <path
            d="M19.6603 3.5979H24.5542C24.5542 7.393 24.2632 9.57603 18.856 9.57603"
            stroke="#DE9300"
            stroke-width="2"
          />
          <path
            d="M19.8634 8.56843C19.8634 12.5378 16.6456 15.7556 12.6762 15.7556C12.6762 15.7556 12.6762 12.5378 12.6762 8.56843C12.6762 4.59905 12.6762 1.38124 12.6762 1.38124C16.6456 1.38124 19.8634 4.59905 19.8634 8.56843Z"
            fill="url(#paint1_linear_103_1804)"
          />
          <path
            d="M12.6762 20.7262C12.6762 20.7262 12.6762 21.5833 12.6762 22.6405H17.9826C17.9826 21.5833 15.6069 20.7262 12.6762 20.7262Z"
            fill="url(#paint2_linear_103_1804)"
          />
          <path
            d="M12.6762 0.000488281H19.8634V8.23258H12.6762V0.000488281Z"
            fill="url(#paint3_linear_103_1804)"
          />
          <path
            d="M17.9826 23.01H12.6762C12.6762 23.01 12.6643 22.7639 12.6762 22.6069C12.8331 20.5406 17.9826 22.6069 17.9826 22.6069V23.01Z"
            fill="url(#paint4_linear_103_1804)"
          />
          <circle cx="12.8176" cy="7.76846" r="4.30105" fill="#DCAE0C" />
          <circle
            cx="12.8088"
            cy="7.71544"
            r="3.12686"
            fill="#DE9300"
            stroke="#FFE176"
            stroke-width="4.55437"
          />
          <path
            d="M12.8087 4.17944L13.8984 6.35885L16.0778 6.63128L14.5812 8.30942L14.9881 10.7177L12.8087 9.62796L10.6293 10.7177L11.0397 8.30942L9.53955 6.63128L11.719 6.35885L12.8087 4.17944Z"
            fill="#FFF4BC"
          />
          <path
            d="M13.2559 3.95584L12.8087 3.06141L12.3614 3.95584L11.3914 5.8959L9.47753 6.13514L8.53113 6.25344L9.16678 6.96451L10.5063 8.46298L10.1364 10.6337L9.97064 11.606L10.8529 11.1649L12.8087 10.187L14.7645 11.1649L15.6451 11.6052L15.4811 10.6344L15.1143 8.46295L16.4509 6.96406L17.0848 6.25327L16.1398 6.13514L14.2259 5.8959L13.2559 3.95584Z"
            stroke="#C98500"
            stroke-opacity="0.7"
          />
          <rect x="5" y="23" width="15" height="2" fill="#DE9300" />
          <defs>
            <linearGradient
              id="paint0_linear_103_1804"
              x1="12.7075"
              y1="15.4102"
              x2="12.7075"
              y2="21.0057"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C07F00" />
              <stop offset="1" stop-color="#DE9300" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_103_1804"
              x1="19.8139"
              y1="7.24836"
              x2="12.6085"
              y2="7.24836"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DE9300" />
              <stop offset="1" stop-color="#FFBC11" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_103_1804"
              x1="19.8139"
              y1="7.24836"
              x2="12.6085"
              y2="7.24836"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DE9300" />
              <stop offset="1" stop-color="#FFBC11" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_103_1804"
              x1="19.8139"
              y1="7.24836"
              x2="12.6085"
              y2="7.24836"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DE9300" />
              <stop offset="1" stop-color="#FFBC11" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_103_1804"
              x1="19.8139"
              y1="7.24836"
              x2="12.6085"
              y2="7.24836"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DE9300" />
              <stop offset="1" stop-color="#FFBC11" />
            </linearGradient>
          </defs>
        </svg>
        <span>باشگاه مشتریان فروشگاه بندیتو</span>
      </h1>
      <div id="pageDetail">
        <p id="firstPara">
          سلام و عرض ادب خدمت همه همراهان فروشگاه بندیتو
          <br />
          فروشگاه بندیتو در نظر دارد تا با احداث باشگاه مشتریان بروی وبسایت
          , برای عزیزانی که ما را به عنوان معتمد خود در خرید محصولات روغنی در
          نظر گرفته اند فرآیندی را پیاده سازی کند تا لطف شما مهربانان را با دادن
          اشتراک های ویژه تا میزان کمی جبران نماید
          <br />
          هدف ما رضایت بیشتر شماست
        </p>
        <div id="pageAction">
          <h4>آنچه در این صفحه میخوانید:</h4>
          <ol>
            <li @click="gotoSection('firstPara')">
              هدف از پیاده سازی باشگاه مشتریان
            </li>
            <li @click="gotoSection('advantageList')">
              مزیت های باشگاه
            </li>
            <li @click="gotoSection('clubRoadmap')">سطح های مختلف عضویت</li>
            <li @click="gotoSection('scores')">
              نحوه محاسبه امتیازها در باشگاه
            </li>
          </ol>
        </div>
      </div>

      <h2>
        <svg
          fill="#000000"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 295.24 295.24"
          xml:space="preserve"
          width="31"
          height="31"
        >
          <g>
            <g>
              <g>
                <path
                  d="M257.143,0.072L40.476,0.001C18.157,0.001,0,18.158,0,40.477v254.762h223.81v-192.69l9.867-6.576l18.705,9.352
				l19.048-9.524l23.81,11.905V40.477C295.238,18.958,278.357,1.306,257.143,0.072z M214.286,40.477v245.238H9.524V40.477
				c0-17.067,13.886-30.952,30.952-30.952h188.229C219.895,16.958,214.286,28.077,214.286,40.477z M285.714,92.296l-14.286-7.143
				l-19.048,9.524l-19.386-9.695l-9.184,6.119V40.477c0-17.067,13.886-30.952,30.952-30.952c17.066,0,30.952,13.886,30.952,30.952
				V92.296z"
                />
                <polygon
                  fill="red"
                  points="141.462,103.368 161.905,82.925 182.348,103.368 189.081,96.634 168.638,76.191 189.081,55.749 182.348,49.015 
				161.905,69.458 141.462,49.015 134.729,55.749 155.171,76.191 134.729,96.634 			"
                />
                <polygon
                  points="89.276,60.311 82.152,53.977 48.157,92.225 42.057,83.072 34.133,88.358 47.081,107.777 			"
                />
                <rect x="23.81" y="119.049" width="76.19" height="9.524" />
                <rect x="23.81" y="142.858" width="76.19" height="9.524" />
                <rect x="23.81" y="166.668" width="76.19" height="9.524" />
                <rect x="23.81" y="190.477" width="76.19" height="9.524" />
                <rect x="23.81" y="214.287" width="76.19" height="9.524" />
                <rect x="23.81" y="238.096" width="38.095" height="9.524" />
                <rect x="123.81" y="119.049" width="76.19" height="9.524" />
                <rect x="123.81" y="142.858" width="76.19" height="9.524" />
                <rect x="123.81" y="166.668" width="76.19" height="9.524" />
                <rect x="123.81" y="190.477" width="57.143" height="9.524" />
              </g>
            </g>
          </g>
        </svg>
        <span>
          مزیت های باشگاه
        </span>
      </h2>
      <ul id="advantageList">
        <li>هدیه نخفیف به مشتریان دائمی ما</li>
        <li>هدیه نخفیف به عزیزانی که ما را معرفی میکنند</li>
        <li>هدیه نخفیف به عزیزانی که برای ما نظر میگذارند</li>
        <li>هدیه نقدی به عزیزانی که جزء مشتریان با خرید بالا 1 تومان هستند</li>
        <li>قرعه کشی سفر به کیش</li>
        <li>در نظر گرفتن جوایز در انتهای هر فصل</li>
        <li>تست محصولات جدید برای مشتریان باشگاه</li>
      </ul>
    </section>
    <div id="line"></div>
    <section @mouseleave="reRunAnim()" id="clubRoadmap">
      
      <ol id="clubStatus">
        <li
          @mouseenter="handlerAnim(0)"
          id="bronzeCard"
          class="memberShipCard active"
        >
          <img
            @mouseenter="handlerAnim(0)"
            class="active"
            src="../../../assets/images/clubLanding/bronze.png"
            alt="سطح عضویت برنزی"
          />
          <h3>برنزی</h3>
          <ul>
            <li>حداقل امتیاز <span class="score">4000</span></li>
            <li>
              <span class="percentage">5%</span>
              نخفیف دائمی روی محصولات
            </li>
            <li>
              <span class="percentage">15%</span>
              تخفیف تولد و سالگرد ازدواج
            </li>
            <li>
              ارسال رایگان محصول در ازای خرید بالای
              <span class="price">350 </span> هزارتومان
            </li>
            <li>
              ارسال پیام در زمان افزوده شدن محصولات شگفت انگیز
            </li>
          </ul>
        </li>
        <li @mouseenter="handlerAnim(1)" id="silverCard" class="memberShipCard">
          <img
            @mouseenter="handlerAnim(1)"
            src="../../../assets/images/clubLanding/silver.png"
            alt=" سطح عضویت نقراه ای"
          />
          <h3>
            نقره ای
          </h3>
          <ul>
            <li>حداقل امتیاز <span class="score">9000</span></li>

            <li>
              <span class="percentage">10%</span>
              نخفیف دائمی روی محصولات
            </li>
            <li>
              <span class="percentage">15%</span>
              تخفیف تولد و سالگرد ازدواج + هدیه ویژه
            </li>
            <li>
              ارسال رایگان محصول در ازای خرید بالای
              <span class="price">150 </span> هزارتومان
            </li>
            <li>
              ارسال پیام در زمان افزوده شدن محصولات شگفت انگیز
            </li>
            <li>
              ارسال پیام در زمان افزوده شدن محصولات جدید در فروشگاه
            </li>
          </ul>
        </li>
        <li @mouseenter="handlerAnim(2)" id="goldCard" class="memberShipCard">
          <img
            @mouseenter="handlerAnim(2)"
            src="../../../assets/images/clubLanding/gold.png"
            alt="سطح عضویت طلایی"
          />
          <h3>
            طلایی
          </h3>
          <ul>
            <li>حداقل امتیاز <span class="score">15000</span></li>

            <li>
              <span class="percentage">15%</span>
              نخفیف دائمی روی محصولات
            </li>
            <li>
              <span class="percentage">20%</span>
              تخفیف تولد و سالگرد ازدواج + هدیه ویژه
            </li>
            <li>
              ارسال رایگان محصول
            </li>
            <li>
              ارسال پیام در زمان افزوده شدن محصولات شگفت انگیز
            </li>
            <li>
              ارسال پیام در زمان افزوده شدن محصولات جدید در فروشگاه
            </li>
            <li>
              تست محصولات جدید با تخفیف
              <span class="percentage">20%</span>
            </li>
            <li>
              قرعه کشی محصولات
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <div id="line"></div>
    <section id="scores">
      <h3>نحوه امتیاز گیری شما عزیزان در باشگاه</h3>
      <p>
        <svg
          width="31"
          height="31"
          viewBox="0 0 72 72"
          id="emoji"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="color">
            <polygon
              fill="#FCEA2B"
              stroke="#FCEA2B"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.8"
              points="35.25,12 46.375,23.25 46.375,62 13,62 13,12"
            />
            <polygon
              fill="#F1B31C"
              stroke="#F1B31C"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.8"
              points="15.688,62 12.625,62 46.375,62 46.375,36.625 20.9375,62"
            />
            <polygon
              fill="#FFFFFF"
              stroke="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
              points="34,49 34,54 39,54 63,30 58,25"
            />
          </g>
          <g id="hair" />
          <g id="skin" />
          <g id="skin-shadow" />
          <g id="line">
            <polyline
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
              points="46.375,30.9999 46.375,23.25 35.125,23.25 35.125,12 12.625,12 12.625,62 46.375,62 46.375,52.1666"
            />
            <line
              x1="35.125"
              x2="46.375"
              y1="12"
              y2="23.25"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="41"
              x2="18"
              y1="30"
              y2="30"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="28"
              x2="18"
              y1="24"
              y2="24"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="41"
              x2="18"
              y1="36"
              y2="36"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="28"
              x2="18"
              y1="42"
              y2="42"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="28"
              x2="18"
              y1="48"
              y2="48"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="28"
              x2="18"
              y1="54"
              y2="54"
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
              points="34,49 34,54 39,54 63,30 58,25"
            />
          </g>
        </svg>
        توجه فرمایید هر بن معادل هزارتومان هدیه نقدی است که در کیف پول شما قرار
        خواهد گرفت
      </p>
      <table>
        <thead>
          <tr>
            <th>عملیات</th>
            <th>امتیاز</th>
            <th>بن</th>
            <th>توضیحات</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ثبت نام</td>
            <td>100</td>
            <td>-</td>
            <td>برای عزیزانی که عضو هستند پیش فرض اعمال میشود</td>
          </tr>
          <tr>
            <td>تکمیل پروفایل</td>
            <td>200</td>
            <td>-</td>
            <td>برای عزیزانی که تکمیل کرده اند پیش فرض اعمال میشود</td>
          </tr>
          <tr>
            <td>خرید زیر 100 هزارتومان</td>
            <td>100</td>
            <td>1</td>
            <td>-</td>
          </tr>
          <tr>
            <td>خرید بین 100 تا 499 هزارتومان</td>
            <td>200</td>
            <td>2</td>
            <td>-</td>
          </tr>
          <tr>
            <td>خرید بین 500 تا 999 هزارتومان</td>
            <td>400</td>
            <td>4</td>
            <td>-</td>
          </tr>
          <tr>
            <td>خرید بالای 1 میلیون تومان</td>
            <td>600</td>
            <td>6</td>
            <td></td>
          </tr>
          <tr>
            <td>کامنت</td>
            <td>200</td>
            <td>1</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>
<script>
export default {
  data() {
    return {
      membershipTimeOut: null,
    };
  },
  mounted() {
    this.membershipTimeOut = setInterval(() => {
      this.membershipAnim();
    }, 5000);
  },
  methods: {
    gotoSection(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    reRunAnim() {
      if (this.membershipTimeOut == null) {
        this.membershipTimeOut = setInterval(() => {
          this.membershipAnim();
        }, 5000);
      }
    },
    handlerAnim(index) {
      clearInterval(this.membershipTimeOut);
      this.membershipTimeOut = null;
      this.membershipAnim(index);
    },
    membershipAnim(ind = null) {
      
      let images = document.querySelectorAll("li.memberShipCard img");
      let cards = document.getElementsByClassName("memberShipCard");
      if (ind != null) {
        for (let index = 0; index < cards.length; index++) {
          if (cards[index].classList.contains("active")) {
            cards[index].classList.toggle("active");
            images[index].classList.toggle("active");

            cards[ind].classList.add("active");
            images[ind].classList.add("active");
            break;
          }
        }
      } else {
        for (let index = 0; index < cards.length; index++) {
          if (cards[index].classList.contains("active")) {
            cards[index].classList.toggle("active");
            images[index].classList.toggle("active");
            let i = index;
            if (i == 2) {
              i = 0;
            } else {
              i = index + 1;
            }
            cards[i].classList.add("active");
            images[i].classList.add("active");
            break;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
main#clubLanding {
  width: 80%;
  margin: 24px auto;
}
main#clubLanding h1 span {
  margin-right: 5px;
  font-size: 31px;
}
main#clubLanding h2 svg * {
  fill: goldenrod;
  stroke: gold;
}
main#clubLanding h2 span {
  font-size: 26px;
  margin-right: 8px;
}
#advantageList {
  list-style-image: url(../../../assets/images/clubLanding/check.png);
  margin: auto;
  width: 90%;
}
main#clubLanding p {
  padding: 16px;
  text-align: justify;
}
div#line {
  margin: 36px auto;
  height: 4px;
  border-radius: 4px;
  background: linear-gradient(45deg, gold, #c0c0c0, #cd7f32);
}
div#clubMemberStatus {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
div#clubMemberStatus img {
  width: 150px;
  opacity: 0.5;
  transition: all 1s;
}
div#clubMemberStatus img.active {
  opacity: 1;
}
ol#clubStatus {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  list-style: none;
  margin: 24px auto;
}
li.memberShipCard ul {
  list-style: none;
}
li.memberShipCard {
  position: relative;
  padding: 8px;
  box-shadow: 0 0 2px #1213;
  border-radius: 8px;
  color: black;
  font-weight: bold;
  opacity: 0.5;
  transition: all 1.2s;
}
.memberShipCard.active {
  transform: scale(1.2);
  border-radius: 0px;
  opacity: 1;
  z-index: 10;
  box-shadow: 0 0 4px rgba(17, 34, 17, 0.39);
  animation: backanim 4s linear infinite alternate;
}
.memberShipCard.active::after {
  content: "";
  height: 2px;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  animation: afterAnim 5s linear;
}

.memberShipCard.active::before {
  content: "";
  width: 2px;
  background: black;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: beforAnim 5s linear;
}
li#silverCard {
  background-color: silver;
}
li#bronzeCard {
  background-color: #cd7f32;
}
li#goldCard {
  background-color: gold;
}
@keyframes beforAnim {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
@keyframes afterAnim {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
section#scores table tbody tr td {
  padding: 4px;
}
section#scores table tbody tr:nth-child(even) {
  background: #80808057;
}
section#scores table tbody {
  text-align: center;
}
section#scores table thead tr th {
  min-width: 40px;
  padding: 4px;
}
section#scores table thead {
  background: black;
  color: white;
  text-align: center;
}
#pageAction ol li {
  margin-bottom: 5px;
  cursor: pointer;
}
#pageAction ol {
  padding-right: 35px;
}
#pageAction {
  border: 1px solid #1213;
  border-radius: 5px;
  padding: 15px 20px;
  box-shadow: 0 0 5px #1213;
  background: #f4f4f4;
}
div#pageDetail {
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 15px;
}
li.memberShipCard img {
  width: 80%;
    margin-right: 10%;
}
@media (max-width: 768px) {
  div#pageDetail {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
  div#clubMemberStatus img,
  li.memberShipCard {
    opacity: 1;
  }
  .memberShipCard.active::before,
  .memberShipCard.active::after {
    animation: unset;
  }
  .memberShipCard.active {
    transform: scale(1);
    border-radius: 0px;
    opacity: 1;
    z-index: 10;
    box-shadow: unset;
    animation: unse 4s linear infinite alternate;
  }
  ol#clubStatus
  {
    flex-direction: column;
  }
}
</style>
