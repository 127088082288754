<template>
  <section id="customerClub" class="container">
    <PagesPath :pathTitle1="'باشگاه مشتریان'" :pathTo1="'club-panel'" />
    <div class="customer-club-container">
      <div class="white-bg side-bar">
        <ul v-if="clubData && clubColor">
          <li
            :class="{ 'active-tab': $route.fullPath.includes('/club-panel') }"
            class="d-flex align-items-center gap-5 py-2 pr-3 border-r-25"
          >
            <ClubHome
              :fill="
                $route.fullPath.includes('/club-panel') ? '#ffffff' : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-panel"
              >داشبورد</router-link
            >
          </li>
          <li
            :class="{
              'active-tab': $route.fullPath.includes('/club-missons'),
            }"
            class="d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25"
          >
            <ClubList
              :fill="
                $route.fullPath.includes('/club-missons')
                  ? '#ffffff'
                  : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-missons"
              >ماموریت های امتیاز گیری</router-link
            >
          </li>
          <li
            :class="{
              'active-tab': $route.fullPath.includes('/club-scores'),
            }"
            class="d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25"
          >
            <ClubList
              :fill="
                $route.fullPath.includes('/club-scores') ? '#ffffff' : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-scores"
              >امتیازات کسب شده</router-link
            >
          </li>
          <!-- <li
            :class="{
              'active-tab': $route.fullPath.includes('/club-after-before'),
            }"
            class="d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25"
          >
            <ClubImg
              :fill="
                $route.fullPath.includes('/club-after-before')
                  ? '#ffffff'
                  : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-after-before"
              >تصویر قبل / بعد</router-link
            >
          </li> -->
          <li
            :class="{
              'active-tab': $route.fullPath.includes('/club-exchange'),
            }"
            class="d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25"
          >
            <ClubExchange
              :fill="
                $route.fullPath.includes('/club-exchange')
                  ? '#ffffff'
                  : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-exchange"
              >تبدیل بن</router-link
            >
          </li>
          <li
            :class="{
              'active-tab': $route.fullPath.includes('/club-bon-requests'),
            }"
            class="d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25"
          >
            <ClubList
              :fill="
                $route.fullPath.includes('/club-bon-requests')
                  ? '#ffffff'
                  : '#6b6b6b'
              "
            />
            <router-link
              class="text-color-grey fontsize16 font-w-500"
              to="/club-bon-requests"
              >گزارش تبدیل بن</router-link
            >
          </li>
        </ul>
      </div>
      <div class="white-bg content">
        <router-view :clubColor="clubColor" :clubData="clubData"></router-view>
      </div>
    </div>
  </section>
</template>
<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ClubImg from "@/parts/Front/components/Icon/ClubImg.vue";
import ClubExchange from "@/parts/Front/components/Icon/ClubExchange.vue";
import ClubHome from "@/parts/Front/components/Icon/ClubHome.vue";
import ClubList from "@/parts/Front/components/Icon/ClubList.vue";
export default {
  components: {
    PagesPath,
    ClubImg,
    ClubExchange,
    ClubHome,
    ClubList,
  },
  watch: {
    clubData(newValue) {
      this.clubColor = newValue.customers_club_level.color;
    },
  },
  data() {
    return {
      clubColor: "#eb6fa7",
    };
  },
  mounted() {
    this.$store.dispatch("front/getClubDataFromServer");
  },
  computed: {
    clubData() {
      return this.$store.getters["front/getClubData"];
    },
  },
};
</script>
<style scoped>
#customerClub .customer-club-container {
  display: grid;
  grid-template-columns: 24% 75%;
  min-height: 500px;
  column-gap: calc(100% - 99%);
}
a:hover {
  color: #565656;
  text-decoration: none;
}
.active-tab {
  background-color: v-bind(clubColor);
}
.active-tab a {
  color: #ffffff;
}
#customerClub ul li {
  box-shadow: 0px 0px 30px 0 #fff, 10px 10px 30px 0 #1d0dca17;
  display: inline-block;
  opacity: 1;
  outline: 0;
  position: relative;
  transition: all 300ms cubic-bezier(0.694, 0, 0.335, 1);
  white-space: nowrap;
}

#customerClub ul li:before {
  border-radius: 20px;
  backface-visibility: hidden;
  background-color: v-bind(clubColor);
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

#customerClub ul li:hover:before {
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}
#customerClub ul li:hover a {
  color: #ffffff;
}
#customerClub ul li:after {
  background-color: #ffffff;
}

#customerClub ul li a {
  z-index: 1;
  position: relative;
}
#customerClub .customer-club-container .content {
  overflow-x: auto;
}

@media screen and (max-width: 992px) {
  #customerClub .customer-club-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  #customerClub .customer-club-container .content {
    min-height: 500px;
  }
}
</style>
