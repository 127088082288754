<template>
  <div>
    <Header id="homeHeader" />
    <main class="main">
      <section class="container mt-2 mt-xl-5">
        <div class="row">
          <div class="col-12 col-xl-9"><HomeSlider /></div>
          <div class="col-12 col-xl-3 mt-2 mt-xl-0">
            <SingleAmazingProduct />
          </div>
        </div>
      </section>
      <div class="container">
        <ProductCategories/>
      </div>
      <RecommendedProductSlider class="mt-2 mt-lg-5"/>
      <div class="container margin-t-100">
        <ResponsiveBanner
          class="w-100"
          :item="access($store.getters, 'front/getHomeData.advertise.center')"
          :mobile-item="
            access($store.getters, 'front/getHomeData.advertise.center_mobile')
          "
        />
      </div>
      <SpecialProduct class="mt-5 mt-lg-5"/>
      <ProductTab class="mt-4 mt-lg-5"/>
      <!-- <div class="container margin-t-50">
          <Banners :banners="banners[1]"></Banners>
      </div> -->

      <div v-if="advertiseBanners" class="d-flex align-items-center justify-content-between flex-column flex-md-row container mt-2 mt-lg-5">
          <HomeBanners :banner="advertiseBanners.bottom_right"/>
          <HomeBanners :banner="advertiseBanners.bottom_left" class="mt-4 mt-md-0"/>
      </div>
      <div>
        <BeforeAfter/>
      </div>
      <div class="mt-5"><TrustedCustomers /></div>
      <Flashes
        v-if="
          $store.getters['front/getHomeData'] != null &&
          $store.getters['front/getHomeData'].flashes != ''
        "
        :flashes="$store.getters['front/getHomeData'].flashes"
      />
      <Weblogs class="mt-5"/>
    </main>
  </div>
</template>
<script>
import Header from "@/parts/Front/components/shared/Header";
import Flashes from "@/parts/Front/components/FlashProducts";
import Weblogs from "@/parts/Front/components/Weblogs";
import ProductTab from "@/parts/Front/components/home/productsTab.vue";
import SpecialProduct from "@/parts/Front/components/home/SpecialProduct.vue";
import RecommendedProductSlider from "@/parts/Front/components/RecommendedProductSlider";
import HomeSlider from "@/parts/Front/components/home/HomeSlider.vue";
import SingleAmazingProduct from "@/parts/Front/components/home/SingleAmazingProduct.vue";
import ResponsiveBanner from "@/parts/Front/components/ResponsiveBanner";
import TrustedCustomers from "@/parts/Front/components/about-us/Trusted-customers";
import Banners from "@/parts/Front/components/Banners";
import HomeBanners from "@/parts/Front/components/HomeBanners.vue";
import ProductCategories from "@/parts/Front/components/ProductCategories.vue";
import BeforeAfter from "@/parts/Front/components/home/BeforeAfter.vue";
export default {
  name: "Home",
  components: {
    RecommendedProductSlider,
    Header,
    Flashes,
    Weblogs,
    SpecialProduct,
    ProductTab,
    HomeSlider,
    SingleAmazingProduct,
    ResponsiveBanner,
    TrustedCustomers,
    Banners,
    HomeBanners,
    ProductCategories,
    BeforeAfter
},
  computed: {
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return [
          [advertise.center, advertise.position2],
          [advertise.position3, advertise.position4],
        ];
      } else {
        return [
          [null, null],
          [null, null],
        ];
      }
    },
    advertiseBanners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].advertise;
      }else {
        return {}
      }
    }
  },
};
</script>
<style scoped>
</style>
