<template>
  <div v-if="products && products.length" class="container">
    <div class="special-product">
      <ul class="special-product-list m-0">
        <li class="special-product-list-title justify-content-start gap-5">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.8416 6.51662L10.4249 10.2333C10.1666 10.3833 9.8416 10.3833 9.57494 10.2333L3.15827 6.51662C2.69993 6.24995 2.58327 5.62495 2.93327 5.23328C3.17493 4.95828 3.44993 4.73328 3.7416 4.57495L8.25827 2.07495C9.22494 1.53328 10.7916 1.53328 11.7583 2.07495L16.2749 4.57495C16.5666 4.73328 16.8416 4.96662 17.0833 5.23328C17.4166 5.62495 17.2999 6.24995 16.8416 6.51662Z"
              fill="white"
            />
            <path
              d="M9.52507 11.7833V17.4666C9.52507 18.1 8.8834 18.5166 8.31673 18.2416C6.60006 17.4 3.7084 15.825 3.7084 15.825C2.69173 15.25 1.8584 13.8 1.8584 12.6083V8.30831C1.8584 7.64998 2.55007 7.23331 3.11673 7.55831L9.1084 11.0333C9.3584 11.1916 9.52507 11.475 9.52507 11.7833Z"
              fill="white"
            />
            <path
              d="M10.4751 11.7833V17.4666C10.4751 18.1 11.1168 18.5166 11.6834 18.2416C13.4001 17.4 16.2918 15.825 16.2918 15.825C17.3084 15.25 18.1418 13.8 18.1418 12.6083V8.30831C18.1418 7.64998 17.4501 7.23331 16.8834 7.55831L10.8918 11.0333C10.6418 11.1916 10.4751 11.475 10.4751 11.7833Z"
              fill="white"
            />
          </svg>
          <p class="m-0 fontsize-mini">محصولات ویژه و پکیج ها</p>
        </li>
        <li
          class="d-flex"
          :class="{ activeItem: activeIndex == index }"
          @click="selectedProduct(item)"
          v-for="(item, index) in products?.slice(0, 7)"
          :key="index"
        >
          <h6 class="special-product-item-title fontsize-mini">
            <svg
              width="16"
              height="16"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.50011 10.5291C9.39719 10.5291 9.29427 10.5054 9.19927 10.45L2.20886 6.40455C1.93177 6.2383 1.82886 5.87413 1.99511 5.58913C2.16136 5.30413 2.52552 5.20913 2.81052 5.37538L9.50011 9.24664L16.1501 5.39913C16.4351 5.23288 16.7993 5.3358 16.9655 5.61288C17.1318 5.89788 17.0289 6.26205 16.7518 6.4283L9.80886 10.45C9.70594 10.4975 9.60302 10.5291 9.50011 10.5291Z"
                fill="white"
              />
              <path
                d="M9.5 17.7017C9.17542 17.7017 8.90625 17.4325 8.90625 17.1079V9.92749C8.90625 9.60291 9.17542 9.33374 9.5 9.33374C9.82458 9.33374 10.0938 9.60291 10.0938 9.92749V17.1079C10.0938 17.4325 9.82458 17.7017 9.5 17.7017Z"
                fill="white"
              />
              <path
                d="M9.50001 18.0105C8.80334 18.0105 8.11459 17.86 7.56834 17.5592L3.34084 15.208C2.19292 14.5746 1.29834 13.0467 1.29834 11.7325V7.25962C1.29834 5.94546 2.19292 4.42546 3.34084 3.78421L7.56834 1.44087C8.65292 0.839207 10.3471 0.839207 11.4317 1.44087L15.6592 3.79212C16.8071 4.42546 17.7017 5.95337 17.7017 7.26754V11.7405C17.7017 13.0546 16.8071 14.5746 15.6592 15.2159L11.4317 17.5592C10.8854 17.86 10.1967 18.0105 9.50001 18.0105ZM9.50001 2.17712C9.00126 2.17712 8.51042 2.28004 8.14626 2.47796L3.91876 4.82921C3.15876 5.24879 2.48584 6.38879 2.48584 7.25962V11.7325C2.48584 12.6034 3.15876 13.7434 3.91876 14.1709L8.14626 16.5221C8.86667 16.9259 10.1333 16.9259 10.8538 16.5221L15.0813 14.1709C15.8413 13.7434 16.5142 12.6113 16.5142 11.7325V7.25962C16.5142 6.38879 15.8413 5.24879 15.0813 4.82129L10.8538 2.47004C10.4896 2.28004 9.99876 2.17712 9.50001 2.17712Z"
                fill="white"
              />
            </svg>
            {{ item.title }}
          </h6>
          <div class="special-product-list-prices">
            <span class="w-s-nowrap fontsize-mini ml-0 ml-xl-1"
              >{{ item.major_final_price.amount | price }} تومان</span
            >
            <span class="line-through w-s-nowrap fontsize-mini"
              >{{
                item.major_final_price.discount_price +
                item.major_final_price.amount
              }}
              تومان</span
            >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.61925 13.659H4.37508C3.37175 13.659 2.61925 13.3906 2.15259 12.8598C1.68592 12.329 1.50508 11.559 1.62758 10.5615L2.15259 6.18648C2.30425 4.89731 2.63092 3.74231 4.90592 3.74231H9.10592C11.3751 3.74231 11.7018 4.89731 11.8593 6.18648L12.3842 10.5615C12.5009 11.559 12.3259 12.3348 11.8593 12.8598C11.3751 13.3906 10.6284 13.659 9.61925 13.659ZM4.90008 4.61731C3.22008 4.61731 3.13842 5.2823 3.01592 6.28563L2.49092 10.6606C2.40342 11.4015 2.50842 11.944 2.80592 12.2765C3.10342 12.609 3.62842 12.7781 4.37508 12.7781H9.61925C10.3659 12.7781 10.8909 12.609 11.1884 12.2765C11.4859 11.944 11.5909 11.4015 11.5034 10.6606L10.9784 6.28563C10.8559 5.27647 10.7801 4.61731 9.09425 4.61731H4.90008Z"
                fill="white"
              />
              <path
                d="M9.33341 5.49231C9.09425 5.49231 8.89591 5.29398 8.89591 5.05481V3.01314C8.89591 2.38314 8.50508 1.99231 7.87508 1.99231H6.12508C5.49508 1.99231 5.10425 2.38314 5.10425 3.01314V5.05481C5.10425 5.29398 4.90591 5.49231 4.66675 5.49231C4.42758 5.49231 4.22925 5.29398 4.22925 5.05481V3.01314C4.22925 1.89898 5.01091 1.11731 6.12508 1.11731H7.87508C8.98925 1.11731 9.77091 1.89898 9.77091 3.01314V5.05481C9.77091 5.29398 9.57258 5.49231 9.33341 5.49231Z"
                fill="white"
              />
              <path
                d="M11.9059 10.7599H4.66675C4.42758 10.7599 4.22925 10.5616 4.22925 10.3224C4.22925 10.0832 4.42758 9.88489 4.66675 9.88489H11.9059C12.1451 9.88489 12.3434 10.0832 12.3434 10.3224C12.3434 10.5616 12.1451 10.7599 11.9059 10.7599Z"
                fill="white"
              />
            </svg>
          </div>
        </li>
        <li>
          <router-link
            class="text-light fontsize-mini"
            to="/products/category/32/پکیج-ها?category_id=32"
            >محصولات پکیجی بیشتر...</router-link
          >
        </li>
      </ul>
      <template v-if="settings && settings.package_banner">
        <img
          class="w-100 h-100 d-md-block d-none"
          :src="`https://api.benedito.ir/storage/${settings.package_banner.image_package_banner_desktop}`"
          alt=""
          style="object-fit: cover"
        />
        <img
          class="w-100 h-100 d-block d-md-none"
          :src="`https://api.benedito.ir/storage/${settings.package_banner.image_package_banner_mobile}`"
          alt=""
          style="object-fit: cover"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "specialProduct",
  data() {
    return {
      readyProducts: null,
      selectedImage: null,
      activeIndex: null,
    };
  },
  watch: {},
  mounted() {
    this.setReadyProducts();
  },
  computed: {
    products() {
      return this.readyProducts;
    },
    settings() {
      if (!this.$store.getters["front/getHomeData"]) {
        return {};
      } else {
        return this.$store.getters["front/getHomeData"].settings;
      }
    },
  },
  methods: {
    async setReadyProducts() {
      this.readyProducts = null;
      let res = await this.$store.dispatch(
        "front/getIndexPageContent",
        "is-package"
      );
      this.readyProducts = res;
    },
    selectedProduct(item) {
      // let selectedProduct = this.products.find((item) => item.id == id);
      // this.selectedImage = selectedProduct.images[0].url;
      // this.activeIndex = index;
      this.$router.push(`/product/${item.id}/${item.slug}`);
    },
  },
};
</script>
<style scoped>
.special-product {
  position: relative;
  border-radius: 50px 0 50px 50px;
  padding: 20px 25px;
  /* background-image: url(../../../../assets/images/amazingHomeBanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.special-product:before {
  content: "";
  position: absolute;
  border-radius: 50px 0 50px 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(to right, transparent 40%, #ffb16f 100%);
}
.special-product-list {
  position: relative;
  z-index: 3;
  border-radius: 50px 0 50px 50px;
  width: 50%;
  min-height: 500px;
  padding: 35px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.4)
  );
}
.special-product-list li:not(:last-child) {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 10px 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  gap: 20px;
}
.special-product-list li:last-child {
  color: #ffffff;
  text-align: center;
  list-style-type: none;
  padding: 10px 20px 0 20px;
}
.special-product-list-title {
  background-color: rgba(255, 177, 111, 0.3) !important;
  border-radius: 25px;
  padding: 10px 20px !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
  width: 100%;
  font-weight: bold;
  font-size: larger;
}
.special-product img {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  border-radius: 50px 0 50px 50px;
}
.special-product-list li:not(:first-child) {
  margin-top: 12px;
}
.special-product-list li:not(:last-child):hover {
  background: linear-gradient(
    to left,
    rgba(253, 43, 80, 0.8),
    rgba(255, 176, 112, 0.8)
  );
}
.special-product-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.special-product-list-prices {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1199px) {
  .special-product {
    position: relative;
    padding: 10px 15px;
  }
  .special-product-list {
    width: 100%;
    padding: 15px 10px;
  }
}
@media screen and (max-width: 1025px) {
  .special-product {
    /* background-image: url(../../../../assets/images/mobileAmazingHomeBanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  }
}
@media screen and (max-width: 540px) {
  .special-product-item-title {
    width: 100%;
  }
  .special-product-list li:not(:last-child) {
    flex-direction: column;
    gap: 5px;
  }
  .special-product-list-prices {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
