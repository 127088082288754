<template>
  <span v-if="product">
    <a title="شگفت انگیز شد اطلاع بده">
      <svg
        v-if="isDiscountListen"
        @click="unListenDiscountReq()"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 512.000000 512.000000"
        class=""
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#FF0000"
          stroke="none"
        >
          <path
            d="M651 4896 c-33 -18 -50 -53 -51 -101 0 -23 -7 -68 -15 -100 -55 -212
-224 -359 -438 -380 -83 -9 -115 -22 -132 -56 -40 -76 10 -149 102 -149 112 0
244 -59 334 -149 90 -90 149 -222 149 -333 0 -50 18 -87 51 -104 75 -38 149
11 149 101 0 163 124 359 272 428 69 33 159 57 211 57 91 0 142 73 103 149
-17 33 -54 51 -104 51 -109 0 -244 60 -333 149 -90 90 -149 222 -149 333 0 50
-18 87 -51 104 -34 17 -67 17 -98 0z m78 -489 c21 -34 137 -149 181 -180 l25
-17 -25 -17 c-32 -22 -171 -161 -193 -193 l-17 -25 -17 25 c-22 32 -161 171
-193 193 l-25 17 25 17 c44 31 160 146 181 180 11 18 24 33 29 33 5 0 18 -15
29 -33z"
          />
          <path
            d="M2209 4595 c-36 -20 -46 -53 -54 -178 -24 -427 -200 -816 -505 -1117
-297 -293 -679 -462 -1100 -486 -123 -7 -156 -17 -175 -55 -19 -35 -19 -63 0
-98 19 -38 52 -48 175 -55 427 -24 808 -195 1110 -496 301 -302 472 -683 496
-1110 7 -123 17 -156 55 -175 35 -19 63 -19 98 0 39 19 48 52 55 183 13 228
74 456 181 668 35 71 65 141 65 156 0 17 -12 41 -29 60 -38 42 -96 46 -136 8
-28 -26 -131 -229 -162 -317 -10 -29 -20 -53 -23 -53 -3 0 -14 24 -24 52 -56
163 -190 391 -321 548 -189 227 -481 440 -737 538 -54 20 -98 39 -98 42 0 3
44 22 98 42 119 46 299 147 418 235 112 84 310 283 389 390 87 120 185 295
231 411 21 56 41 102 44 102 3 0 23 -46 44 -102 46 -116 144 -291 231 -410 82
-112 269 -300 387 -387 126 -95 291 -188 417 -237 55 -21 101 -41 101 -44 0
-3 -24 -13 -52 -23 -89 -31 -292 -134 -318 -162 -38 -40 -34 -98 8 -136 19
-17 43 -29 60 -29 15 0 85 29 156 65 227 114 506 185 729 185 67 0 108 18 125
55 16 34 15 60 -2 94 -19 35 -55 51 -117 51 -27 0 -93 5 -147 11 -802 88
-1422 709 -1512 1514 -5 50 -10 113 -10 142 0 64 -16 100 -51 119 -35 18 -66
17 -100 -1z"
          />
          <path
            d="M2741 2266 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83
124 -149 87z"
          />
          <path
            d="M4071 2196 c-34 -19 -50 -54 -51 -108 0 -67 -24 -182 -55 -263 -55
-147 -174 -295 -310 -385 -107 -71 -293 -130 -411 -130 -99 0 -150 -70 -109
-149 18 -36 51 -48 145 -55 136 -10 261 -52 373 -126 212 -138 344 -361 363
-610 7 -94 19 -127 55 -145 35 -19 63 -19 98 0 36 18 48 51 55 145 19 253 158
483 374 617 116 72 280 123 398 123 98 0 150 70 110 149 -18 34 -54 51 -111
51 -61 0 -178 24 -257 53 -203 74 -391 262 -465 465 -29 79 -53 196 -53 257 0
57 -17 93 -51 111 -34 17 -67 17 -98 0z m108 -623 c85 -135 226 -267 363 -340
l38 -20 -92 -59 c-118 -75 -238 -194 -308 -306 -30 -48 -57 -88 -60 -88 -3 0
-30 40 -60 88 -70 111 -191 232 -302 302 -48 30 -88 57 -88 60 0 3 40 30 88
60 111 70 232 191 302 302 30 48 57 88 60 88 3 0 29 -39 59 -87z"
          />
        </g>
      </svg>
      <svg
        v-else
        @click="listenDiscountReq()"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 512.000000 512.000000"
        class=""
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#444"
          stroke="none"
        >
          <path
            d="M651 4896 c-33 -18 -50 -53 -51 -101 0 -23 -7 -68 -15 -100 -55 -212
-224 -359 -438 -380 -83 -9 -115 -22 -132 -56 -40 -76 10 -149 102 -149 112 0
244 -59 334 -149 90 -90 149 -222 149 -333 0 -50 18 -87 51 -104 75 -38 149
11 149 101 0 163 124 359 272 428 69 33 159 57 211 57 91 0 142 73 103 149
-17 33 -54 51 -104 51 -109 0 -244 60 -333 149 -90 90 -149 222 -149 333 0 50
-18 87 -51 104 -34 17 -67 17 -98 0z m78 -489 c21 -34 137 -149 181 -180 l25
-17 -25 -17 c-32 -22 -171 -161 -193 -193 l-17 -25 -17 25 c-22 32 -161 171
-193 193 l-25 17 25 17 c44 31 160 146 181 180 11 18 24 33 29 33 5 0 18 -15
29 -33z"
          />
          <path
            d="M2209 4595 c-36 -20 -46 -53 -54 -178 -24 -427 -200 -816 -505 -1117
-297 -293 -679 -462 -1100 -486 -123 -7 -156 -17 -175 -55 -19 -35 -19 -63 0
-98 19 -38 52 -48 175 -55 427 -24 808 -195 1110 -496 301 -302 472 -683 496
-1110 7 -123 17 -156 55 -175 35 -19 63 -19 98 0 39 19 48 52 55 183 13 228
74 456 181 668 35 71 65 141 65 156 0 17 -12 41 -29 60 -38 42 -96 46 -136 8
-28 -26 -131 -229 -162 -317 -10 -29 -20 -53 -23 -53 -3 0 -14 24 -24 52 -56
163 -190 391 -321 548 -189 227 -481 440 -737 538 -54 20 -98 39 -98 42 0 3
44 22 98 42 119 46 299 147 418 235 112 84 310 283 389 390 87 120 185 295
231 411 21 56 41 102 44 102 3 0 23 -46 44 -102 46 -116 144 -291 231 -410 82
-112 269 -300 387 -387 126 -95 291 -188 417 -237 55 -21 101 -41 101 -44 0
-3 -24 -13 -52 -23 -89 -31 -292 -134 -318 -162 -38 -40 -34 -98 8 -136 19
-17 43 -29 60 -29 15 0 85 29 156 65 227 114 506 185 729 185 67 0 108 18 125
55 16 34 15 60 -2 94 -19 35 -55 51 -117 51 -27 0 -93 5 -147 11 -802 88
-1422 709 -1512 1514 -5 50 -10 113 -10 142 0 64 -16 100 -51 119 -35 18 -66
17 -100 -1z"
          />
          <path
            d="M2741 2266 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83
124 -149 87z"
          />
          <path
            d="M4071 2196 c-34 -19 -50 -54 -51 -108 0 -67 -24 -182 -55 -263 -55
-147 -174 -295 -310 -385 -107 -71 -293 -130 -411 -130 -99 0 -150 -70 -109
-149 18 -36 51 -48 145 -55 136 -10 261 -52 373 -126 212 -138 344 -361 363
-610 7 -94 19 -127 55 -145 35 -19 63 -19 98 0 36 18 48 51 55 145 19 253 158
483 374 617 116 72 280 123 398 123 98 0 150 70 110 149 -18 34 -54 51 -111
51 -61 0 -178 24 -257 53 -203 74 -391 262 -465 465 -29 79 -53 196 -53 257 0
57 -17 93 -51 111 -34 17 -67 17 -98 0z m108 -623 c85 -135 226 -267 363 -340
l38 -20 -92 -59 c-118 -75 -238 -194 -308 -306 -30 -48 -57 -88 -60 -88 -3 0
-30 40 -60 88 -70 111 -191 232 -302 302 -48 30 -88 57 -88 60 0 3 40 30 88
60 111 70 232 191 302 302 30 48 57 88 60 88 3 0 29 -39 59 -87z"
          />
        </g>
      </svg>
    </a>
  </span>
</template>

<script>
export default {
  inject: ["mediaQueries"],
  props: {
    product: Object,
    value: Object || null,
  },
  data() {
    return {
      isDiscountListen: false,
      disabled: false,
      product: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
      this.selectFirstIfNotSelected();
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    this.$store.commit("front/addHomeRetrievedEvent", () => {
      if (this.DiscountListenList && this.DiscountListenList != "") {
        this.isDiscountListen = !!this.DiscountListenList.filter(
          (item) => item.product_id == this.product.id
        ).length;
      }
    });
  },
  computed: {
    DiscountListenList() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user != null
      ) {
        return this.$store.getters["front/getHomeData"].user.user
          .listen_discounts;
      } else {
        return null;
      }
    },
    user() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user != null
      ) {
        return this.$store.getters["front/getHomeData"].user.user;
      } else {
        return null;
      }
    },
  },
  methods: {
    selectFirstIfNotSelected() {
      this.product.selectFirstIfNotSelected();
    },

    async listenDiscountReq() {
      if (!this.user) {
        window.$("#loginModal").modal("show");
        return;
      }
      try {
        this.disabled = true;
        const response = await this.$axios.post(
          `customer/products/${this.product.id}/listen-discount`
        );
        this.user.listen_discounts.push(response.data.data.listenDiscount);
        this.$root.notify(response, "success");
        this.isDiscountListen = true;
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    async unListenDiscountReq() {
      try {
        this.disabled = true;
        const response = await this.$axios.delete(
          `customer/products/${this.product.id}/unlisten-discount`
        );
        this.user.listen_discounts = this.user.listen_discounts.filter(
          (listenDiscount) => {
            return listenDiscount.product_id != this.product.id;
          }
        );
        this.$root.notify(response, "success");
        this.isDiscountListen = false;
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
};
</script>

<style></style>
