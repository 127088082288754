<template>
    <div v-if="product">
      <button
          v-if="!hasSizeAttribute && product.size_charts != ''"
          class="py-2 text-center bg-color-c8 text-black fontsize-small what-is-my-size mt-3"
          data-toggle="modal"
          data-target="#sizeModal"
      >
        جدول سایز
      </button>
      <div v-for="attribute in attributes" :key="attribute.id"
           class="product-details-size-choose mt-3 text-right d-flex align-items-center">
        <div class="attribute-container text-right" :class="{'box': attribute.style === 'box' || attribute.style === 'image', 'size': attribute.name == 'size'}">
          <span>انتخاب {{ attribute.label }}</span>
          <div :class="{'attribute-values': !isSizeAndBoxedStyle(attribute), 'attribute-values-size': isSizeAndBoxedStyle(attribute), 'big': getOptions(attribute).length > 4}">
            <treeselect v-if="attribute.style === 'select'" :clearable="false" @input="e => selectAttribute(e, attribute.id)"
                        :searchable="!mediaQueries.mobileSize"
                        :options="getOptions(attribute)"
                        noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
                        :placeholder="attribute.label"
            />
            <BoxSelect v-else-if="attribute.style === 'box'" :options="getOptions(attribute)"
                       @input="e => selectAttribute(e, attribute.id)" :value="getAttributeSelectedValue(attribute.id)"/>
            <BoxImage v-else-if="attribute.style === 'image'" :options="getOptions(attribute)"
                       @input="e => selectAttribute(e, attribute.id)" :value="getAttributeSelectedValue(attribute.id)"/>
            <template v-if="attribute.name == 'size'">
              <button
                  v-if="product != null && product.size_charts != ''"
                  class="py-2 text-center bg-color-c8 text-black fontsize-small what-is-my-size"
                  :class="{'px-2': attribute.style !== 'select'}"
                  data-toggle="modal"
                  data-target="#sizeModal"
              >
                جدول سایز
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="width: 145px;" class="d-inline-block ml-2">
      <b-skeleton type="button" width="100%" />
    </div>
</template>

<script>
import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import BoxSelect from "@/parts/Front/components/productDetail/attributes/BoxSelect";
import BoxImage from "./attributes/BoxImage";
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "ProductAttributes",

  inject:['mediaQueries'],
  components: {BoxImage, BoxSelect, Treeselect, BSkeleton},
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value
    }
  },
  watch: {
    value(newVal) {
      this.product = newVal;
    },
    product(newVal) {
      this.$emit('input', newVal)
    },
  },
  computed: {
    attributes() {
      return this.product.getAttributes()
    },
    hasSizeAttribute() {
      return this.attributes.find(attr => attr.name == 'size')
    }
  },
  methods: {
    isSizeAndBoxedStyle(attribute) {
      return attribute.name == 'size' && attribute.style === 'box'
    },
    getAttributeSelectedValue(attrId) {
      let val = this.product.selected_attributes_id.find(item => item.id == attrId);
      return val ? val.value : null
    },
    getOptions(attribute) {
      let options = [];
      attribute.values.forEach(attr => {
        options.push({
          id: attr.value,
          label: attr.value,
          isDisabled: !this.product.isAttributeAvailable(attr),
          variety: attribute.name == 'tarh' ? this.product.findVarietyForAttributeValue(attr.id, attr.value) : null
        })
      })
      return options
    },
    getPivotValue(pivot){
      if(pivot.value){
        return pivot.value
      }else{
        return pivot.attribute_value.value
      }
    },
    selectAttribute(selectedValueValue, attributeId) {
      this.product.varieties.forEach((variety)=>{
        if(variety.attributes){
          variety.attributes.forEach((attr)=>{
            if(attr.id==attributeId&&selectedValueValue==this.getPivotValue(attr.pivot)){
              return this.$store.commit("front/setSelectedVariety",variety)
            }
          })
        }
      })
      let selectedValue = null;
      this.attributes.forEach(attr => {
        if (selectedValue) {
          return;
        }
        if (attr.id == attributeId) {
          attr.values.forEach(val => {
            if (val.value == selectedValueValue) {
              selectedValue = val;
            }
          })
        }
      })
      if (!selectedValue) {
        this.product.deselectAttribute(attributeId);
      } else {
        this.product.selectAttribute(selectedValue.id, selectedValue.value, selectedValue.valueId)
      }
    },
  }
}
</script>

<style scoped>
  .attribute-container {
    max-width: 200px
  }

  .attribute-container.size {
    max-width: 198px;
  }

  .attribute-container.box {
    max-width: unset;
  }

  .what-is-my-size {
    min-width: 90px;
    margin-right: 9px;
  }

  .attribute-values {
    display: flex;
    justify-content: center;
  }

  .attribute-values-size {
    display: flex;
    justify-content: center;
  } 
  

  @media screen and (max-width: 768px){
    .attribute-values-size.big {
      flex-direction: column;
    }
    .attribute-values-size.big >>> .box-select-option, .attribute-values-size.big >>> .what-is-my-size {
      margin-bottom: 7px;
    }
  }

  @media screen and (max-width: 400px){
    .product-details-size-choose.text-right {
      margin: 0 -10px 0 -10px;
    }
  }
</style>
