<template>
  <section v-if="related != ''" class="similar-products  text-right container mt-4 mb-3 mb-sm-5">
    <div class="d-flex align-items-baseline mb-3">
      <h5 class="weight-bold fontsize-heavy">محصولات مرتبط</h5>
      <!-- <router-link
        to="/products"
        class="recommended-products-more text-white text-white-hover fontsize-small bg-color-theme px-3 py-2 mt-3 d-inline-block border-r-25"
        >مشاهده همه</router-link
      > -->
    </div>
    <div class=" container px-0">
      <div class=" recommended-content-item swiper-container">
        <div
          class="swiper-wrapper "
          v-if="related != '' && !disabledSliders.includes('myProducts')"
        >
          <div
            class="swiper-slide px-1"
            v-for="(product, index) in related"
            :key="index"
          >
            <Product :product="product"></Product>
          </div>
        </div>
      </div>
      <div class="swiper-pagination w-100 "></div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
import Product from "@/parts/Front/components/product/newProduct";
export default {
  props: {
    related: Array,
  },
  components: {
    Product,
  },
  data() {
    return {
      disabledSliders: [],
    };
  },
  mounted() {
    this.initRelatedProducts();
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("myProducts", kill, () => {
        if (this.slider) {
          this.slider.destroy()
        }
        this.slider = new Swiper(".similar-products .swiper-container", {
          spaceBetween: 30,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
};
</script>
<style>
.similar-products .swiper-container {
  overflow: unset;
}
/* .similar-products .swiper-button-prev,.similar-products .swiper-button-next {
  width: 15px !important;
  height: 15px !important;
} */
.similar-products .container {
  overflow-x: hidden;
}
.similar-products .swiper-button-prev::after,
.similar-products .swiper-button-next::after {
  content: "unset" !important;
}
.similar-products .swiper-pagination-bullet {
  margin: 0 2px;
}
.similar-products .swiper-pagination {
  position: unset;
}
</style>
