var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container",attrs:{"id":"customerClub"}},[_c('PagesPath',{attrs:{"pathTitle1":'باشگاه مشتریان',"pathTo1":'club-panel'}}),_c('div',{staticClass:"customer-club-container"},[_c('div',{staticClass:"white-bg side-bar"},[(_vm.clubData && _vm.clubColor)?_c('ul',[_c('li',{staticClass:"d-flex align-items-center gap-5 py-2 pr-3 border-r-25",class:{ 'active-tab': _vm.$route.fullPath.includes('/club-panel') }},[_c('ClubHome',{attrs:{"fill":_vm.$route.fullPath.includes('/club-panel') ? '#ffffff' : '#6b6b6b'}}),_c('router-link',{staticClass:"text-color-grey fontsize16 font-w-500",attrs:{"to":"/club-panel"}},[_vm._v("داشبورد")])],1),_c('li',{staticClass:"d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25",class:{
            'active-tab': _vm.$route.fullPath.includes('/club-missons'),
          }},[_c('ClubList',{attrs:{"fill":_vm.$route.fullPath.includes('/club-missons')
                ? '#ffffff'
                : '#6b6b6b'}}),_c('router-link',{staticClass:"text-color-grey fontsize16 font-w-500",attrs:{"to":"/club-missons"}},[_vm._v("ماموریت های امتیاز گیری")])],1),_c('li',{staticClass:"d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25",class:{
            'active-tab': _vm.$route.fullPath.includes('/club-scores'),
          }},[_c('ClubList',{attrs:{"fill":_vm.$route.fullPath.includes('/club-scores') ? '#ffffff' : '#6b6b6b'}}),_c('router-link',{staticClass:"text-color-grey fontsize16 font-w-500",attrs:{"to":"/club-scores"}},[_vm._v("امتیازات کسب شده")])],1),_c('li',{staticClass:"d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25",class:{
            'active-tab': _vm.$route.fullPath.includes('/club-exchange'),
          }},[_c('ClubExchange',{attrs:{"fill":_vm.$route.fullPath.includes('/club-exchange')
                ? '#ffffff'
                : '#6b6b6b'}}),_c('router-link',{staticClass:"text-color-grey fontsize16 font-w-500",attrs:{"to":"/club-exchange"}},[_vm._v("تبدیل بن")])],1),_c('li',{staticClass:"d-flex align-items-center gap-5 py-2 pr-3 mt-3 border-r-25",class:{
            'active-tab': _vm.$route.fullPath.includes('/club-bon-requests'),
          }},[_c('ClubList',{attrs:{"fill":_vm.$route.fullPath.includes('/club-bon-requests')
                ? '#ffffff'
                : '#6b6b6b'}}),_c('router-link',{staticClass:"text-color-grey fontsize16 font-w-500",attrs:{"to":"/club-bon-requests"}},[_vm._v("گزارش تبدیل بن")])],1)]):_vm._e()]),_c('div',{staticClass:"white-bg content"},[_c('router-view',{attrs:{"clubColor":_vm.clubColor,"clubData":_vm.clubData}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }